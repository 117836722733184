import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../components/auth';
import { useNavigate } from 'react-router-dom';
import Api from '../lib/Api';
import ToastHelper from '../helpers/toast_helper';
import { FaEdit } from 'react-icons/fa';

export default function Settings() {
    // Stany dla danych użytkownika i restauracji
    const [userData, setUserData] = useState(null);
    const [userLoading, setUserLoading] = useState(false);
    const [userError, setUserError] = useState('');

    // Stany dla edycji użytkownika
    const [editingUserFields, setEditingUserFields] = useState({});
    const [editedUserData, setEditedUserData] = useState({});
    const [editUserLoading, setEditUserLoading] = useState(false);
    const [editUserError, setEditUserError] = useState('');

    // Stany dla edycji restauracji
    const [editingRestaurantFields, setEditingRestaurantFields] = useState({});
    const [editedRestaurantData, setEditedRestaurantData] = useState({});
    const [editRestaurantLoading, setEditRestaurantLoading] = useState(false);
    const [editRestaurantError, setEditRestaurantError] = useState('');

    // Stany dla modalu usuwania konta
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const auth = useAuth();
    const navigate = useNavigate();

    // Funkcja do pobierania danych użytkownika i restauracji
    const fetchUser = useCallback(async () => {
        setUserLoading(true);
        setUserError('');

        try {
            const fetchedData = await Api.GetUser();
            setUserData(fetchedData);
        } catch (err) {
            setUserError(err.message || 'Wystąpił błąd podczas pobierania danych użytkownika.');
        } finally {
            setUserLoading(false);
        }
    }, []);

    // Pobieranie danych użytkownika po zamontowaniu komponentu
    useEffect(() => {
        if (localStorage.getItem('access_token')) {
            fetchUser();
        } else {
            auth.logout();
            navigate('/login');
        }
    }, [fetchUser, auth, navigate]);

    // Obsługa kliknięcia ikony edycji dla użytkownika
    const handleEditUserClick = (field) => {
        setEditingUserFields((prev) => ({ ...prev, [field]: true }));
        setEditedUserData((prev) => ({ ...prev, [field]: userData.user[field] }));
    };

    // Obsługa kliknięcia ikony edycji dla restauracji
    const handleEditRestaurantClick = (field) => {
        setEditingRestaurantFields((prev) => ({ ...prev, [field]: true }));
        setEditedRestaurantData((prev) => ({ ...prev, [field]: userData.restaurant[field] }));
    };

    // Obsługa zmian w inputach użytkownika
    const handleUserInputChange = (field, value) => {
        setEditedUserData((prev) => ({ ...prev, [field]: value }));
    };

    // Obsługa zmian w inputach restauracji
    const handleRestaurantInputChange = (field, value) => {
        setEditedRestaurantData((prev) => ({ ...prev, [field]: value }));
    };

    // Obsługa zapisywania edytowanych pól użytkownika
    const handleSaveUser = async () => {
        setEditUserLoading(true);
        setEditUserError('');

        // Przygotowanie danych do aktualizacji użytkownika
        const dataToUpdate = { ...editedUserData };

        try {
            const response = await Api.UpdateUser(dataToUpdate);
            if (!response.error) {
                ToastHelper.showSuccessToast('Dane użytkownika zostały pomyślnie zaktualizowane!');
                setEditingUserFields({});
                setEditedUserData({});
                fetchUser(); // Odświeżenie danych użytkownika
            } else {
                throw new Error(response.message || 'Wystąpił błąd podczas aktualizacji danych użytkownika.');
            }
        } catch (err) {
            setEditUserError(err.message || 'Wystąpił błąd podczas aktualizacji danych użytkownika.');
        } finally {
            setEditUserLoading(false);
        }
    };

    // Obsługa zapisywania edytowanych pól restauracji
    const handleSaveRestaurant = async () => {
        setEditRestaurantLoading(true);
        setEditRestaurantError('');

        // Przygotowanie danych do aktualizacji restauracji
        const dataToUpdate = { ...editedRestaurantData };

        try {
            const response = await Api.UpdateUser(dataToUpdate); // Zakładam, że API.UpdateUser obsługuje również aktualizację restauracji
            if (!response.error) {
                ToastHelper.showSuccessToast('Dane restauracji zostały pomyślnie zaktualizowane!');
                setEditingRestaurantFields({});
                setEditedRestaurantData({});
                fetchUser(); // Odświeżenie danych
            } else {
                throw new Error(response.message || 'Wystąpił błąd podczas aktualizacji danych restauracji.');
            }
        } catch (err) {
            setEditRestaurantError(err.message || 'Wystąpił błąd podczas aktualizacji danych restauracji.');
        } finally {
            setEditRestaurantLoading(false);
        }
    };

    // Obsługa otwierania modalu usuwania konta
    const openDeleteModal = () => {
        setIsModalOpen(true);
    };

    // Obsługa zamykania modalu usuwania konta
    const closeDeleteModal = () => {
        setIsModalOpen(false);
    };

    // Obsługa usuwania konta użytkownika
    const handleDeleteAccount = async () => {
        setIsDeleting(true);
        try {
            const response = await Api.DeleteUser();
            if (!response.error) {
                ToastHelper.showSuccessToast('Twoje konto zostało pomyślnie usunięte.');
                // Wylogowanie użytkownika i przekierowanie do strony logowania
                localStorage.removeItem('access_token');
                localStorage.removeItem('user_id');
                localStorage.removeItem('restaurant_id');
                auth.logout();
                navigate('/login');
            } else {
                ToastHelper.showErrorToast('Wystąpił błąd podczas usuwania konta: ' + response.message);
            }
        } catch (error) {
            ToastHelper.showErrorToast('Wystąpił błąd podczas usuwania konta: ' + error.message);
        } finally {
            setIsDeleting(false);
            closeDeleteModal();
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 p-6">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-3xl font-semibold text-white mb-6">Ustawienia</h1>

                {userLoading && <p className="text-gray-400">Ładowanie danych...</p>}
                {userError && <p className="text-red-500 mb-4">{userError}</p>}

                {userData && (
                    <div className="bg-gray-800 shadow-md rounded-lg p-8 w-full">
                        {/* Sekcja Użytkownika */}
                        <div className="mb-8">
                            <h2 className="text-2xl font-semibold text-white mb-4">Dane Użytkownika</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                {/* Pole Email */}
                                <div className="relative">
                                    <label className="block text-gray-300 font-medium mb-2">Email</label>
                                    {editingUserFields.email ? (
                                        <input
                                            type="email"
                                            value={editedUserData.email}
                                            onChange={(e) => handleUserInputChange('email', e.target.value)}
                                            required
                                            className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    ) : (
                                        <div className="flex items-center justify-between">
                                            <p className="text-white">{userData.user.email}</p>
                                            <button
                                                className="text-blue-400 hover:text-blue-600"
                                                onClick={() => handleEditUserClick('email')}
                                            >
                                                <FaEdit />
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {/* Pole Hasło */}
                                <div className="relative">
                                    <label className="block text-gray-300 font-medium mb-2">Hasło</label>
                                    {editingUserFields.password ? (
                                        <input
                                            type="password"
                                            value={editedUserData.password || ''}
                                            onChange={(e) => handleUserInputChange('password', e.target.value)}
                                            placeholder="Wprowadź nowe hasło lub pozostaw puste, aby zachować obecne"
                                            className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    ) : (
                                        <div className="flex items-center justify-between">
                                            <p className="text-white">********</p>
                                            <button
                                                className="text-blue-400 hover:text-blue-600"
                                                onClick={() => handleEditUserClick('password')}
                                            >
                                                <FaEdit />
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {/* Pole Telefon */}
                                <div className="relative">
                                    <label className="block text-gray-300 font-medium mb-2">Telefon</label>
                                    {editingUserFields.phone ? (
                                        <input
                                            type="tel"
                                            value={editedUserData.phone}
                                            onChange={(e) => handleUserInputChange('phone', e.target.value)}
                                            required
                                            className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    ) : (
                                        <div className="flex items-center justify-between">
                                            <p className="text-white">{userData.user.phone}</p>
                                            <button
                                                className="text-blue-400 hover:text-blue-600"
                                                onClick={() => handleEditUserClick('phone')}
                                            >
                                                <FaEdit />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Wyświetlanie błędu edycji użytkownika, jeśli wystąpi */}
                            {editUserError && <p className="text-red-500 mt-4 text-center">{editUserError}</p>}

                            {/* Przyciski Zapisz i Anuluj dla użytkownika */}
                            {Object.keys(editedUserData).length > 0 && (
                                <div className="flex justify-end mt-6 space-x-4">
                                    <button
                                        onClick={() => {
                                            setEditingUserFields({});
                                            setEditedUserData({});
                                            setEditUserError('');
                                        }}
                                        className="px-6 py-2 bg-gray-600 text-gray-200 rounded-md hover:bg-gray-700 transition-colors duration-200"
                                        disabled={editUserLoading}
                                    >
                                        Anuluj
                                    </button>
                                    <button
                                        onClick={handleSaveUser}
                                        disabled={editUserLoading}
                                        className={`px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200 flex items-center ${editUserLoading ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                    >
                                        {editUserLoading && (
                                            <svg
                                                className="animate-spin h-5 w-5 mr-2 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8v8H4z"
                                                ></path>
                                            </svg>
                                        )}
                                        {editUserLoading ? 'Zapisywanie...' : 'Zapisz Zmiany'}
                                    </button>
                                </div>
                            )}
                        </div>

                        {/* Sekcja Restauracji */}
                        <div className="mb-8">
                            <h2 className="text-2xl font-semibold text-white mb-4">Dane Restauracji</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                {/* Pole Nazwa Restauracji */}
                                <div className="relative">
                                    <label className="block text-gray-300 font-medium mb-2">Nazwa Restauracji</label>
                                    {editingRestaurantFields.restaurant_name ? (
                                        <input
                                            type="text"
                                            value={editedRestaurantData.restaurant_name}
                                            onChange={(e) => handleRestaurantInputChange('restaurant_name', e.target.value)}
                                            required
                                            className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    ) : (
                                        <div className="flex items-center justify-between">
                                            <p className="text-white">{userData.restaurant.restaurant_name}</p>
                                            <button
                                                className="text-blue-400 hover:text-blue-600"
                                                onClick={() => handleEditRestaurantClick('restaurant_name')}
                                            >
                                                <FaEdit />
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {/* Pole Ulica */}
                                <div className="relative">
                                    <label className="block text-gray-300 font-medium mb-2">Ulica</label>
                                    {editingRestaurantFields.street ? (
                                        <input
                                            type="text"
                                            value={editedRestaurantData.street}
                                            onChange={(e) => handleRestaurantInputChange('street', e.target.value)}
                                            required
                                            className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    ) : (
                                        <div className="flex items-center justify-between">
                                            <p className="text-white">{userData.restaurant.street}</p>
                                            <button
                                                className="text-blue-400 hover:text-blue-600"
                                                onClick={() => handleEditRestaurantClick('street')}
                                            >
                                                <FaEdit />
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {/* Pole Miasto */}
                                <div className="relative">
                                    <label className="block text-gray-300 font-medium mb-2">Miasto</label>
                                    {editingRestaurantFields.city ? (
                                        <input
                                            type="text"
                                            value={editedRestaurantData.city}
                                            onChange={(e) => handleRestaurantInputChange('city', e.target.value)}
                                            required
                                            className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    ) : (
                                        <div className="flex items-center justify-between">
                                            <p className="text-white">{userData.restaurant.city}</p>
                                            <button
                                                className="text-blue-400 hover:text-blue-600"
                                                onClick={() => handleEditRestaurantClick('city')}
                                            >
                                                <FaEdit />
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {/* Pole Kod Pocztowy */}
                                <div className="relative">
                                    <label className="block text-gray-300 font-medium mb-2">Kod Pocztowy</label>
                                    {editingRestaurantFields.postal_code ? (
                                        <input
                                            type="text"
                                            value={editedRestaurantData.postal_code}
                                            onChange={(e) => handleRestaurantInputChange('postal_code', e.target.value)}
                                            required
                                            className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    ) : (
                                        <div className="flex items-center justify-between">
                                            <p className="text-white">{userData.restaurant.postal_code}</p>
                                            <button
                                                className="text-blue-400 hover:text-blue-600"
                                                onClick={() => handleEditRestaurantClick('postal_code')}
                                            >
                                                <FaEdit />
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {/* Pole Kraj */}
                                <div className="relative">
                                    <label className="block text-gray-300 font-medium mb-2">Kraj</label>
                                    {editingRestaurantFields.country ? (
                                        <input
                                            type="text"
                                            value={editedRestaurantData.country}
                                            onChange={(e) => handleRestaurantInputChange('country', e.target.value)}
                                            required
                                            className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    ) : (
                                        <div className="flex items-center justify-between">
                                            <p className="text-white">{userData.restaurant.country}</p>
                                            {/* <button
                                                className="text-blue-400 hover:text-blue-600"
                                                onClick={() => handleEditRestaurantClick('country')}
                                            >
                                                <FaEdit />
                                            </button> */}
                                        </div>
                                    )}
                                </div>

                                {/* ID Restauracji (Nieedytowalne) */}
                                <div className="relative col-span-1 md:col-span-2">
                                    <label className="block text-gray-300 font-medium mb-2">ID Restauracji</label>
                                    <div className="flex items-center">
                                        <p className="text-gray-400">{userData.restaurant.restaurant_unique_id}</p>
                                        
                                    </div>
                                    <button
                                            onClick={() => navigate(`/menu/${userData.restaurant.restaurant_unique_id}/test`)}
                                            className="text-gray-500 opacity-50 hover:opacity-100 underline"
                                        >
                                            Podgląd Menu
                                        </button>
                                </div>
                            </div>

                            {/* Wyświetlanie błędu edycji restauracji, jeśli wystąpi */}
                            {editRestaurantError && <p className="text-red-500 mt-4 text-center">{editRestaurantError}</p>}

                            {/* Przyciski Zapisz i Anuluj dla restauracji */}
                            {Object.keys(editedRestaurantData).length > 0 && (
                                <div className="flex justify-end mt-6 space-x-4">
                                    <button
                                        onClick={() => {
                                            setEditingRestaurantFields({});
                                            setEditedRestaurantData({});
                                            setEditRestaurantError('');
                                        }}
                                        className="px-6 py-2 bg-gray-600 text-gray-200 rounded-md hover:bg-gray-700 transition-colors duration-200"
                                        disabled={editRestaurantLoading}
                                    >
                                        Anuluj
                                    </button>
                                    <button
                                        onClick={handleSaveRestaurant}
                                        disabled={editRestaurantLoading}
                                        className={`px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200 flex items-center ${editRestaurantLoading ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                    >
                                        {editRestaurantLoading && (
                                            <svg
                                                className="animate-spin h-5 w-5 mr-2 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8v8H4z"
                                                ></path>
                                            </svg>
                                        )}
                                        {editRestaurantLoading ? 'Zapisywanie...' : 'Zapisz Zmiany'}
                                    </button>
                                </div>
                            )}
                        </div>

                        {/* Sekcja Dodatkowe Informacje */}
                        {/* Możesz dodać więcej sekcji według potrzeb */}

                        {/* Tekstowy Link Usuwania Konta */}
                        <div className="mt-8 text-left">
                            <button
                                onClick={openDeleteModal}
                                className="text-red-500 hover:text-red-400 underline"
                            >
                                Usuń konto
                            </button>
                        </div>
                    </div>
                )}

                {/* Modal Potwierdzający Usunięcie Konta */}
                {isModalOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-gray-800 rounded-lg p-6 w-11/12 md:w-1/3">
                            <h2 className="text-2xl font-semibold text-white mb-4">Potwierdzenie Usunięcia Konta</h2>
                            <p className="text-gray-300 mb-6">
                                Czy na pewno chcesz usunąć swoje konto? Ta operacja jest nieodwracalna i spowoduje usunięcie wszystkich Twoich danych.
                            </p>
                            <div className="flex justify-end space-x-4">
                                <button
                                    onClick={closeDeleteModal}
                                    className="px-4 py-2 bg-gray-600 text-gray-200 rounded-md hover:bg-gray-700 transition-colors duration-200"
                                    disabled={isDeleting}
                                >
                                    Anuluj
                                </button>
                                <button
                                    onClick={handleDeleteAccount}
                                    className={`px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-200 flex items-center ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                    disabled={isDeleting}
                                >
                                    {isDeleting && (
                                        <svg
                                            className="animate-spin h-5 w-5 mr-2 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8v8H4z"
                                            ></path>
                                        </svg>
                                    )}
                                    {isDeleting ? 'Usuwanie...' : 'Usuń Konto'}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
