import React from 'react'
import { useAuthAdmin } from './admin_auth'
import { Navigate } from 'react-router-dom'

export const AdminRequireAuth = ({ children }) => {
    const auth = useAuthAdmin();

    console.log("AdminRequireAuth:", auth.admin_id, auth.admin_access_token);

    if (!auth.admin_id || !auth.admin_access_token || !auth.email) {
        return <Navigate to="/admin/login" />
    }

    return children;
}
