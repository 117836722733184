// src/pages/Products.js
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../components/auth';
import { useNavigate } from 'react-router-dom';
import Api from '../lib/Api';
import ToastHelper from '../helpers/toast_helper';
import { Transition } from '@headlessui/react';

export default function Products() {
    // Stany dla zarządzania kategoriami
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [categoryOrder, setCategoryOrder] = useState('');
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [categoryError, setCategoryError] = useState('');
    const [categories, setCategories] = useState([]);
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [categoriesError, setCategoriesError] = useState('');

    // Stany dla edycji kategorii
    const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false);
    const [editCategoryId, setEditCategoryId] = useState(null);
    const [editCategoryName, setEditCategoryName] = useState('');
    const [editCategoryOrder, setEditCategoryOrder] = useState('');
    const [editCategoryLoading, setEditCategoryLoading] = useState(false);
    const [editCategoryError, setEditCategoryError] = useState('');

    // Stany dla zarządzania produktami
    const [isProductModalOpen, setIsProductModalOpen] = useState(false);
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productImage, setProductImage] = useState('');
    const [productCategoryId, setProductCategoryId] = useState('');
    const [productLoading, setProductLoading] = useState(false);
    const [productError, setProductError] = useState('');
    const [products, setProducts] = useState([]);
    const [productsLoading, setProductsLoading] = useState(false);
    const [productsError, setProductsError] = useState('');

    // Stany dla edycji produktów
    const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
    const [editProductId, setEditProductId] = useState(null);
    const [editProductName, setEditProductName] = useState('');
    const [editProductDescription, setEditProductDescription] = useState('');
    const [editProductPrice, setEditProductPrice] = useState('');
    const [editProductImage, setEditProductImage] = useState('');
    const [editProductCategoryId, setEditProductCategoryId] = useState('');
    const [editProductLoading, setEditProductLoading] = useState(false);
    const [editProductError, setEditProductError] = useState('');

    // Stany dla niestandardowego dialogu potwierdzenia
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [confirmTitle, setConfirmTitle] = useState('');
    const [confirmMessage, setConfirmMessage] = useState('');
    const [confirmCallback, setConfirmCallback] = useState(() => { });

    const auth = useAuth();
    const navigate = useNavigate();
    const restaurantId = localStorage.getItem('restaurant_id');

    // Funkcja do pobierania kategorii
    const fetchCategories = useCallback(async () => {
        if (!restaurantId) return;

        setCategoriesLoading(true);
        setCategoriesError('');

        try {
            const fetchedCategories = await Api.GetCategories(restaurantId);
            setCategories(fetchedCategories);
        } catch (err) {
            setCategoriesError(err.message || 'Wystąpił błąd podczas pobierania kategorii.');
        } finally {
            setCategoriesLoading(false);
        }
    }, [restaurantId]);

    // Funkcja do pobierania produktów
    const fetchProducts = useCallback(async () => {
        if (!restaurantId) return;

        setProductsLoading(true);
        setProductsError('');

        try {
            const fetchedProducts = await Api.GetProducts(restaurantId);
            setProducts(fetchedProducts);
        } catch (err) {
            setProductsError(err.message || 'Wystąpił błąd podczas pobierania produktów.');
        } finally {
            setProductsLoading(false);
        }
    }, [restaurantId]);

    // Wywołanie funkcji pobierających dane po załadowaniu komponentu
    useEffect(() => {
        if (restaurantId) {
            fetchCategories();
            fetchProducts();
        } else {
            auth.logout();
            navigate('/login');
        }
    }, [restaurantId, fetchCategories, fetchProducts, auth, navigate]);

    // Obsługa dodawania kategorii
    const handleAddCategory = async (e) => {
        e.preventDefault();
        setCategoryLoading(true);
        setCategoryError('');

        try {
            await Api.AddCategory(restaurantId, categoryName, parseInt(categoryOrder, 10));
            setCategoryName('');
            setCategoryOrder('');
            setIsCategoryModalOpen(false);
            ToastHelper.showSuccessToast('Kategoria została dodana pomyślnie!');
            fetchCategories(); // Odśwież listę kategorii po dodaniu nowej
        } catch (err) {
            setCategoryError(err.message || 'Wystąpił nieznany błąd.');
        } finally {
            setCategoryLoading(false);
        }
    };

    // Obsługa edycji kategorii
    const handleEditCategory = async (e) => {
        e.preventDefault();
        setEditCategoryLoading(true);
        setEditCategoryError('');

        try {
            await Api.UpdateCategory(restaurantId, editCategoryId, editCategoryName, parseInt(editCategoryOrder, 10));
            setIsEditCategoryModalOpen(false);
            ToastHelper.showSuccessToast('Kategoria została zaktualizowana pomyślnie!');
            fetchCategories(); // Odśwież listę kategorii po edycji
        } catch (err) {
            setEditCategoryError(err.message || 'Wystąpił nieznany błąd.');
        } finally {
            setEditCategoryLoading(false);
        }
    };

    // Funkcja do wyświetlania dialogu potwierdzenia
    const showCustomConfirmDialog = (title, message, onConfirm) => {
        setConfirmTitle(title);
        setConfirmMessage(message);
        setConfirmCallback(() => () => {
            onConfirm();
            setIsConfirmOpen(false);
        });
        setIsConfirmOpen(true);
    };

    // Aktualizacja handlerów usuwania, aby używały dialogu potwierdzenia
    const handleDeleteCategory = async (categoryId) => {
        showCustomConfirmDialog(
            'Usuń Kategorię',
            'Czy na pewno chcesz usunąć tę kategorię? Ta operacja jest nieodwracalna.',
            async () => {
                try {
                    await Api.DeleteCategory(restaurantId, categoryId);
                    ToastHelper.showSuccessToast('Kategoria została usunięta pomyślnie!');
                    fetchCategories(); // Odśwież listę kategorii po usunięciu
                } catch (err) {
                    ToastHelper.showErrorToast(err.message || 'Wystąpił błąd podczas usuwania kategorii.');
                }
            }
        );
    };

    const handleDeleteProduct = async (productId) => {
        showCustomConfirmDialog(
            'Usuń Produkt',
            'Czy na pewno chcesz usunąć ten produkt? Ta operacja jest nieodwracalna.',
            async () => {
                try {
                    await Api.DeleteProduct(restaurantId, productId);
                    ToastHelper.showSuccessToast('Produkt został usunięty pomyślnie!');
                    fetchProducts(); // Odśwież listę produktów po usunięciu
                } catch (err) {
                    ToastHelper.showErrorToast(err.message || 'Wystąpił błąd podczas usuwania produktu.');
                }
            }
        );
    };

    // Handler dla potwierdzenia akcji
    const handleConfirm = () => {
        confirmCallback();
    };

    // Handler dla anulowania akcji
    const handleCancelConfirm = () => {
        setIsConfirmOpen(false);
    };

    // Obsługa dodawania produktu
    const handleAddProduct = async (e) => {
        e.preventDefault();
        setProductLoading(true);
        setProductError('');

        try {
            await Api.AddProduct(
                restaurantId,
                productName,
                productDescription,
                parseFloat(productPrice),
                productImage,
                parseInt(productCategoryId, 10)
            );
            setProductName('');
            setProductDescription('');
            setProductPrice('');
            setProductImage('');
            setProductCategoryId('');
            setIsProductModalOpen(false);
            ToastHelper.showSuccessToast('Produkt został dodany pomyślnie!');
            fetchProducts(); // Odśwież listę produktów po dodaniu nowego
        } catch (err) {
            setProductError(err.message || 'Wystąpił nieznany błąd.');
        } finally {
            setProductLoading(false);
        }
    };

    // Obsługa edycji produktu
    const handleEditProduct = async (e) => {
        e.preventDefault();
        setEditProductLoading(true);
        setEditProductError('');

        try {
            await Api.UpdateProduct(
                restaurantId,
                editProductId,
                editProductName,
                editProductDescription,
                parseFloat(editProductPrice),
                editProductImage,
                parseInt(editProductCategoryId, 10)
            );
            setIsEditProductModalOpen(false);
            ToastHelper.showSuccessToast('Produkt został zaktualizowany pomyślnie!');
            fetchProducts(); // Odśwież listę produktów po edycji
        } catch (err) {
            setEditProductError(err.message || 'Wystąpił nieznany błąd.');
        } finally {
            setEditProductLoading(false);
        }
    };

    // Obsługa kliknięcia edycji produktu
    const handleEditProductClick = (product) => {
        setEditProductId(product.id);
        setEditProductName(product.name);
        setEditProductDescription(product.description);
        setEditProductPrice(product.price);
        setEditProductImage(product.image);
        setEditProductCategoryId(product.category_id); // Ustawienie domyślnej kategorii
        setIsEditProductModalOpen(true);
    };

    return (
        <div className="min-h-screen bg-gray-900 text-white mt-16 p-6">
            <div className="container mx-auto">
                <h1 className="text-4xl font-bold text-center mb-8">Zarządzanie Produktami</h1>

                {/* Przyciski dodawania kategorii, produktów i podglądu menu */}
                <div className="flex flex-col md:flex-row justify-center mb-8 space-y-4 md:space-y-0 md:space-x-6">
                    <button
                        className="flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
                        onClick={() => setIsCategoryModalOpen(true)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                        Dodaj Kategorię
                    </button>
                    <button
                        className={`flex items-center justify-center px-6 py-3 bg-green-600 text-white rounded-lg shadow-md hover:bg-green-700 transition duration-300 ${categories.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        onClick={() => setIsProductModalOpen(true)}
                        disabled={categories.length === 0}
                        title={categories.length === 0 ? "Dodaj najpierw kategorię" : "Dodaj Produkt"}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                        Dodaj Produkt
                    </button>
                    {/* Nowy przycisk Podgląd Menu */}
                    <button
                        className="flex items-center justify-center px-6 py-3 bg-purple-600 text-white rounded-lg shadow-md hover:bg-purple-700 transition duration-300"
                        onClick={() => navigate(`/menu/${restaurantId}/test`)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.522 5 12 5c4.478 0 8.268 2.943 9.542 7-.822 3.585-4.048 6-9.542 6-5.494 0-8.72-2.415-9.542-6z" />
                        </svg>
                        Podgląd Menu
                    </button>
                </div>

                {/* Sekcja Wyświetlania Kategorii */}
                <div className="mb-12">
                    <h2 className="text-2xl font-semibold text-gray-300 mb-4">Kategorie</h2>
                    {categoriesLoading && <p className="text-gray-400">Ładowanie kategorii...</p>}
                    {categoriesError && <p className="text-red-500 mb-2">{categoriesError}</p>}
                    {!categoriesLoading && !categoriesError && categories.length === 0 && (
                        <p className="text-gray-400">Brak kategorii do wyświetlenia.</p>
                    )}
                    {!categoriesLoading && !categoriesError && categories.length > 0 && (
                        <>
                            {/* Tabela dla dużych ekranów */}
                            <div className="hidden md:block overflow-x-auto">
                                <table className="min-w-full bg-gray-800 text-white rounded-lg">
                                    <thead>
                                        <tr>
                                            <th className="py-3 px-6 bg-gray-700 text-left">ID Kategorii</th>
                                            <th className="py-3 px-6 bg-gray-700 text-left">Nazwa</th>
                                            <th className="py-3 px-6 bg-gray-700 text-left">Kolejność</th>
                                            <th className="py-3 px-6 bg-gray-700 text-left">Akcje</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categories.map(category => (
                                            <tr key={category.id} className="border-t border-gray-600">
                                                <td className="py-4 px-6">{category.id}</td>
                                                <td className="py-4 px-6">{category.name}</td>
                                                <td className="py-4 px-6">{category.display_order}</td>
                                                <td className="py-4 px-6">
                                                    <div className="flex space-x-2">
                                                        <button
                                                            className="px-3 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors duration-200"
                                                            onClick={() => {
                                                                setEditCategoryId(category.id);
                                                                setEditCategoryName(category.name);
                                                                setEditCategoryOrder(category.display_order);
                                                                setIsEditCategoryModalOpen(true);
                                                            }}
                                                        >
                                                            Edytuj
                                                        </button>
                                                        <button
                                                            className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors duration-200"
                                                            onClick={() => handleDeleteCategory(category.id)}
                                                        >
                                                            Usuń
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Lista dla małych ekranów */}
                            <div className="block md:hidden space-y-4">
                                {categories.map(category => (
                                    <div key={category.id} className="bg-gray-800 text-white p-4 rounded-lg shadow">
                                        <div className="mb-2">
                                            <p><span className="font-semibold">ID Kategorii:</span> {category.id}</p>
                                            <p><span className="font-semibold">Nazwa:</span> {category.name}</p>
                                            <p><span className="font-semibold">Kolejność:</span> {category.display_order}</p>
                                        </div>
                                        <div className="flex space-x-2">
                                            <button
                                                className="px-3 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors duration-200"
                                                onClick={() => {
                                                    setEditCategoryId(category.id);
                                                    setEditCategoryName(category.name);
                                                    setEditCategoryOrder(category.display_order);
                                                    setIsEditCategoryModalOpen(true);
                                                }}
                                            >
                                                Edytuj
                                            </button>
                                            <button
                                                className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors duration-200"
                                                onClick={() => handleDeleteCategory(category.id)}
                                            >
                                                Usuń
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>

                {/* Sekcja Wyświetlania Produktów */}
                <div>
                    <h2 className="text-2xl font-semibold text-gray-300 mb-4">Produkty</h2>
                    {productsLoading && <p className="text-gray-400">Ładowanie produktów...</p>}
                    {productsError && <p className="text-red-500 mb-2">{productsError}</p>}
                    {!productsLoading && !productsError && products.length === 0 && (
                        <p className="text-gray-400">Brak produktów do wyświetlenia.</p>
                    )}
                    {!productsLoading && !productsError && products.length > 0 && (
                        <>
                            {/* Tabela dla dużych ekranów */}
                            <div className="hidden md:block overflow-x-auto">
                                <table className="min-w-full bg-gray-800 text-white rounded-lg">
                                    <thead>
                                        <tr>
                                            <th className="py-3 px-6 bg-gray-700 text-left">ID Produktu</th>
                                            <th className="py-3 px-6 bg-gray-700 text-left">Nazwa</th>
                                            <th className="py-3 px-6 bg-gray-700 text-left">Opis</th>
                                            <th className="py-3 px-6 bg-gray-700 text-left">Cena</th>
                                            <th className="py-3 px-6 bg-gray-700 text-left">Kategoria</th>
                                            <th className="py-3 px-6 bg-gray-700 text-left">Obraz</th>
                                            <th className="py-3 px-6 bg-gray-700 text-left">Akcje</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map(product => (
                                            <tr key={product.id} className="border-t border-gray-600">
                                                <td className="py-4 px-6">{product.id}</td>
                                                <td className="py-4 px-6">{product.name}</td>
                                                <td className="py-4 px-6">{product.description}</td>
                                                <td className="py-4 px-6">{product.price.toFixed(2)} PLN</td>
                                                <td className="py-4 px-6">{product.category_name || 'Brak'}</td>
                                                <td className="py-4 px-6">
                                                    <img
                                                        src={product.image}
                                                        alt={product.name}
                                                        className="w-16 h-16 object-cover rounded-md"
                                                    />
                                                </td>
                                                <td className="py-4 px-6">
                                                    <div className="flex space-x-2">
                                                        <button
                                                            className="px-3 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors duration-200"
                                                            onClick={() => handleEditProductClick(product)}
                                                        >
                                                            Edytuj
                                                        </button>
                                                        <button
                                                            className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors duration-200"
                                                            onClick={() => handleDeleteProduct(product.id)}
                                                        >
                                                            Usuń
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Lista dla małych ekranów */}
                            <div className="block md:hidden space-y-4">
                                {products.map(product => (
                                    <div key={product.id} className="bg-gray-800 text-white p-4 rounded-lg shadow">
                                        <div className="mb-2">
                                            <p><span className="font-semibold">ID Produktu:</span> {product.id}</p>
                                            <p><span className="font-semibold">Nazwa:</span> {product.name}</p>
                                            <p><span className="font-semibold">Opis:</span> {product.description}</p>
                                            <p><span className="font-semibold">Cena:</span> {product.price.toFixed(2)} PLN</p>
                                            <p><span className="font-semibold">Kategoria:</span> {product.category_name || 'Brak'}</p>
                                        </div>
                                        <img
                                            src={product.image}
                                            alt={product.name}
                                            className="w-full h-48 object-cover rounded-md mb-4"
                                        />
                                        <div className="flex space-x-2">
                                            <button
                                                className="px-3 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors duration-200"
                                                onClick={() => handleEditProductClick(product)}
                                            >
                                                Edytuj
                                            </button>
                                            <button
                                                className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors duration-200"
                                                onClick={() => handleDeleteProduct(product.id)}
                                            >
                                                Usuń
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Modale Dodawania Kategorii */}
            {isCategoryModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity">
                    <div className="bg-gray-800 rounded-lg shadow-lg w-full max-w-md mx-4 p-6 transform transition-transform duration-300">
                        <h2 className="text-2xl font-semibold text-gray-200 mb-4">Dodaj Nową Kategorię</h2>
                        {categoryError && <p className="text-red-500 mb-2">{categoryError}</p>}
                        <form onSubmit={handleAddCategory}>
                            <div className="mb-4">
                                <label className="block text-gray-300 font-medium mb-1">Nazwa Kategorii</label>
                                <input
                                    type="text"
                                    value={categoryName}
                                    onChange={(e) => setCategoryName(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="mb-6">
                                <label className="block text-gray-300 font-medium mb-1">Kolejność</label>
                                <input
                                    type="number"
                                    value={categoryOrder}
                                    onChange={(e) => setCategoryOrder(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    min="1"
                                />
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    className="px-4 py-2 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-700 transition-colors duration-200"
                                    onClick={() => setIsCategoryModalOpen(false)}
                                    disabled={categoryLoading}
                                >
                                    Anuluj
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center"
                                    disabled={categoryLoading}
                                >
                                    {categoryLoading && (
                                        <svg className="animate-spin h-5 w-5 mr-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8v8H4z"
                                            ></path>
                                        </svg>
                                    )}
                                    {categoryLoading ? 'Dodawanie...' : 'Dodaj'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Modale Edycji Kategorii */}
            {isEditCategoryModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity">
                    <div className="bg-gray-800 rounded-lg shadow-lg w-full max-w-md mx-4 p-6 transform transition-transform duration-300">
                        <h2 className="text-2xl font-semibold text-gray-200 mb-4">Edytuj Kategorię</h2>
                        {editCategoryError && <p className="text-red-500 mb-2">{editCategoryError}</p>}
                        <form onSubmit={handleEditCategory}>
                            <div className="mb-4">
                                <label className="block text-gray-300 font-medium mb-1">Nazwa Kategorii</label>
                                <input
                                    type="text"
                                    value={editCategoryName}
                                    onChange={(e) => setEditCategoryName(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="mb-6">
                                <label className="block text-gray-300 font-medium mb-1">Kolejność</label>
                                <input
                                    type="number"
                                    value={editCategoryOrder}
                                    onChange={(e) => setEditCategoryOrder(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    min="1"
                                />
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    className="px-4 py-2 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-700 transition-colors duration-200"
                                    onClick={() => setIsEditCategoryModalOpen(false)}
                                    disabled={editCategoryLoading}
                                >
                                    Anuluj
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center"
                                    disabled={editCategoryLoading}
                                >
                                    {editCategoryLoading && (
                                        <svg className="animate-spin h-5 w-5 mr-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8v8H4z"
                                            ></path>
                                        </svg>
                                    )}
                                    {editCategoryLoading ? 'Aktualizowanie...' : 'Aktualizuj'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Modale Dodawania Produktu */}
            {isProductModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity">
                    <div className="bg-gray-800 rounded-lg shadow-lg w-full max-w-lg mx-4 p-6 transform transition-transform duration-300">
                        <h2 className="text-2xl font-semibold text-gray-200 mb-4">Dodaj Nowy Produkt</h2>
                        {productError && <p className="text-red-500 mb-2">{productError}</p>}
                        <form onSubmit={handleAddProduct}>
                            <div className="mb-4">
                                <label className="block text-gray-300 font-medium mb-1">Nazwa Produktu</label>
                                <input
                                    type="text"
                                    value={productName}
                                    onChange={(e) => setProductName(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-300 font-medium mb-1">Opis Produktu</label>
                                <textarea
                                    value={productDescription}
                                    onChange={(e) => setProductDescription(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                                    rows="3"
                                ></textarea>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-300 font-medium mb-1">Cena</label>
                                <input
                                    type="number"
                                    value={productPrice}
                                    onChange={(e) => setProductPrice(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                                    min="0.01"
                                    step="0.01"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-300 font-medium mb-1">URL Obrazu</label>
                                <input
                                    type="url"
                                    value={productImage}
                                    onChange={(e) => setProductImage(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                                    placeholder="https://example.com/image.jpg"
                                />
                            </div>
                            <div className="mb-6">
                                <label className="block text-gray-300 font-medium mb-1">Kategoria</label>
                                <select
                                    value={productCategoryId}
                                    onChange={(e) => setProductCategoryId(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                                >
                                    <option value="">-- Wybierz Kategorię --</option>
                                    {categories.map(category => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    className="px-4 py-2 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-700 transition-colors duration-200"
                                    onClick={() => setIsProductModalOpen(false)}
                                    disabled={productLoading}
                                >
                                    Anuluj
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200 flex items-center"
                                    disabled={productLoading}
                                >
                                    {productLoading && (
                                        <svg className="animate-spin h-5 w-5 mr-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8v8H4z"
                                            ></path>
                                        </svg>
                                    )}
                                    {productLoading ? 'Dodawanie...' : 'Dodaj'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Modale Edycji Produktu */}
            {isEditProductModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity">
                    <div className="bg-gray-800 rounded-lg shadow-lg w-full max-w-lg mx-4 p-6 transform transition-transform duration-300">
                        <h2 className="text-2xl font-semibold text-gray-200 mb-4">Edytuj Produkt</h2>
                        {editProductError && <p className="text-red-500 mb-2">{editProductError}</p>}
                        <form onSubmit={handleEditProduct}>
                            <div className="mb-4">
                                <label className="block text-gray-300 font-medium mb-1">Nazwa Produktu</label>
                                <input
                                    type="text"
                                    value={editProductName}
                                    onChange={(e) => setEditProductName(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-300 font-medium mb-1">Opis Produktu</label>
                                <textarea
                                    value={editProductDescription}
                                    onChange={(e) => setEditProductDescription(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                                    rows="3"
                                ></textarea>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-300 font-medium mb-1">Cena</label>
                                <input
                                    type="number"
                                    value={editProductPrice}
                                    onChange={(e) => setEditProductPrice(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                                    min="0.01"
                                    step="0.01"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-300 font-medium mb-1">URL Obrazu</label>
                                <input
                                    type="url"
                                    value={editProductImage}
                                    onChange={(e) => setEditProductImage(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                                    placeholder="https://example.com/image.jpg"
                                />
                            </div>
                            <div className="mb-6">
                                <label className="block text-gray-300 font-medium mb-1">Kategoria</label>
                                <select
                                    value={editProductCategoryId}
                                    onChange={(e) => setEditProductCategoryId(e.target.value)}
                                    required
                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                                >
                                    <option value="">-- Wybierz Kategorię --</option>
                                    {categories.map(category => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    className="px-4 py-2 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-700 transition-colors duration-200"
                                    onClick={() => setIsEditProductModalOpen(false)}
                                    disabled={editProductLoading}
                                >
                                    Anuluj
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200 flex items-center"
                                    disabled={editProductLoading}
                                >
                                    {editProductLoading && (
                                        <svg className="animate-spin h-5 w-5 mr-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8v8H4z"
                                            ></path>
                                        </svg>
                                    )}
                                    {editProductLoading ? 'Aktualizowanie...' : 'Aktualizuj'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Niestandardowy Dialog Potwierdzenia z animacją */}
            <ConfirmDialog
                isOpen={isConfirmOpen}
                title={confirmTitle}
                message={confirmMessage}
                onConfirm={handleConfirm}
                onCancel={handleCancelConfirm}
            />
        </div>
    );

    // Definicja komponentu ConfirmDialog
    function ConfirmDialog({ isOpen, title, message, onConfirm, onCancel }) {
        return (
            <Transition show={isOpen} as={React.Fragment}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="bg-gray-800 rounded-lg shadow-lg w-full max-w-md mx-4 p-6 transform transition-transform">
                                <h2 className="text-2xl font-semibold text-gray-200 mb-4">{title}</h2>
                                <p className="text-gray-300 mb-6">{message}</p>
                                <div className="flex justify-end space-x-4">
                                    <button
                                        className="px-4 py-2 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-700 transition-colors duration-200"
                                        onClick={onCancel}
                                    >
                                        Anuluj
                                    </button>
                                    <button
                                        className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-200"
                                        onClick={onConfirm}
                                    >
                                        Usuń
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Transition.Child>
            </Transition>
        );
    }
}
