import React, { useState, useEffect } from 'react';
import Api from '../lib/Api';

export default function Statistics() {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const restaurantId = localStorage.getItem('restaurant_id');
        if (!restaurantId) {
          throw new Error('Brak ID restauracji w localStorage.');
        }
        const data = await Api.GetStatistics(restaurantId);
        setStats(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
  }, []);

  const statCards = stats
    ? [
        {
          title: 'Liczba Stolików',
          value: stats.total_tables,
          icon: '🍽️',
        },
        {
          title: 'Liczba Kategorii',
          value: stats.total_categories,
          icon: '📂',
        },
        {
          title: 'Liczba Produktów',
          value: stats.total_products,
          icon: '🍔',
        },
        {
          title: 'Łączna Liczba Zamówień',
          value: stats.total_orders,
          icon: '🛒',
        },
        {
          title: 'Aktywne Zamówienia',
          value: stats.active_orders,
          icon: '⌛',
        },
        {
          title: 'Całkowity Przychód',
          value: `${stats.total_revenue?.toFixed(2) || '0.00'} PLN`,
          icon: '💰',
        },
      ]
    : [];

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center pt-20 px-4">
      {loading ? (
        <div className="text-gray-200 flex flex-col items-center mt-20">
          <svg
            className="animate-spin h-8 w-8 mb-4 text-gray-200"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
              fill="none"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
          <div>Ładowanie statystyk...</div>
        </div>
      ) : error ? (
        <div className="text-red-500 mt-20">Błąd: {error}</div>
      ) : (
        <div className="w-full max-w-7xl px-6">
          <h1 className="text-4xl font-bold text-center text-gray-100 mb-10">
            Statystyki Restauracji
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {statCards.map((card, index) => (
              <div
                key={index}
                className="bg-gray-800 rounded-lg shadow-lg p-6 text-white flex items-center transition transform hover:scale-105"
              >
                <div className="text-5xl mr-4">{card.icon}</div>
                <div>
                  <h3 className="text-lg font-semibold">{card.title}</h3>
                  <p className="text-2xl font-bold mt-1">{card.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
