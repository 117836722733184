// src/pages/AdminSettings.js
import React, { useState, useEffect, useCallback } from 'react';
import { FiEdit, FiTrash2, FiPlus, FiX } from 'react-icons/fi';
import Api from '../lib/Api';
import ToastHelper from '../../helpers/toast_helper';

export default function AdminSettings() {
    // Stany dla profilu administratora
    const [adminProfile, setAdminProfile] = useState(null);
    const [profileLoading, setProfileLoading] = useState(false);
    const [profileError, setProfileError] = useState('');
    const [profileSuccess, setProfileSuccess] = useState('');
    const [editingProfileField, setEditingProfileField] = useState(null);
    const [editedProfileData, setEditedProfileData] = useState({});

    // Stany dla listy administratorów
    const [admins, setAdmins] = useState([]);
    const [adminsLoading, setAdminsLoading] = useState(false);
    const [adminsError, setAdminsError] = useState('');

    // Stany dla edycji administratorów
    const [editingAdminId, setEditingAdminId] = useState(null);
    const [editedAdminData, setEditedAdminData] = useState({});
    const [adminFormLoading, setAdminFormLoading] = useState(false);
    const [adminFormError, setAdminFormError] = useState('');
    const [adminFormSuccess, setAdminFormSuccess] = useState('');

    // Stany dla usuwania administratora
    const [deletingAdminId, setDeletingAdminId] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    // Pobieranie profilu administratora
    const fetchAdminProfile = useCallback(async () => {
        setProfileLoading(true);
        setProfileError('');
        try {
            const profile = await Api.getAdminProfile();
            setAdminProfile(profile);
        } catch (error) {
            console.error('Błąd podczas pobierania profilu:', error);
            setProfileError('Błąd podczas pobierania profilu.');
            ToastHelper.showErrorToast('Błąd podczas pobierania profilu.');
        } finally {
            setProfileLoading(false);
        }
    }, []);

    // Pobieranie listy administratorów
    const fetchAdmins = useCallback(async () => {
        setAdminsLoading(true);
        setAdminsError('');
        try {
            const adminsList = await Api.getAllAdmins();
            setAdmins(adminsList);
        } catch (error) {
            console.error('Błąd podczas pobierania listy administratorów:', error);
            setAdminsError('Błąd podczas pobierania listy administratorów.');
            ToastHelper.showErrorToast('Błąd podczas pobierania listy administratorów.');
        } finally {
            setAdminsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchAdminProfile();
        fetchAdmins();
    }, [fetchAdminProfile, fetchAdmins]);

    // Obsługa zmian w profilu administratora
    const handleProfileFieldEdit = (field) => {
        setEditingProfileField(field);
        setEditedProfileData({ [field]: adminProfile[field] });
    };

    const handleProfileInputChange = (e) => {
        const { name, value } = e.target;
        setEditedProfileData(prev => ({ ...prev, [name]: value }));
    };

    const handleProfileSave = async (field) => {
        setProfileLoading(true);
        setProfileError('');
        setProfileSuccess('');

        try {
            const updateData = {};
            if (editedProfileData[field]) updateData[field] = editedProfileData[field];

            const response = await Api.updateAdminProfile(updateData);
            if (!response.error) {
                setProfileSuccess('Profil został zaktualizowany.');
                fetchAdminProfile();
                setEditingProfileField(null);
            } else {
                setProfileError(response.message || 'Błąd podczas aktualizacji profilu.');
            }
        } catch (error) {
            console.error('Błąd podczas aktualizacji profilu:', error);
            setProfileError('Błąd podczas aktualizacji profilu.');
        } finally {
            setProfileLoading(false);
        }
    };

    // Obsługa edycji administratora
    const handleEditAdminClick = (admin) => {
        setEditingAdminId(admin.id);
        setEditedAdminData({ email: admin.email, password: '' });
        setAdminFormError('');
        setAdminFormSuccess('');
    };

    const handleCancelEditAdmin = () => {
        setEditingAdminId(null);
        setEditedAdminData({});
        setAdminFormError('');
        setAdminFormSuccess('');
    };

    const handleAdminInputChange = (e) => {
        const { name, value } = e.target;
        setEditedAdminData(prev => ({ ...prev, [name]: value }));
    };

    const handleUpdateAdmin = async (e) => {
        e.preventDefault();
        setAdminFormLoading(true);
        setAdminFormError('');
        setAdminFormSuccess('');

        try {
            const updateData = {};
            if (editedAdminData.email) updateData.email = editedAdminData.email;
            if (editedAdminData.password) updateData.password = editedAdminData.password;

            if (Object.keys(updateData).length === 0) {
                setAdminFormError('Nie ma danych do aktualizacji.');
                setAdminFormLoading(false);
                return;
            }

            const response = await Api.updateAdmin(editingAdminId, updateData);
            if (!response.error) {
                setAdminFormSuccess('Administrator został zaktualizowany.');
                fetchAdmins();
                handleCancelEditAdmin();
            } else {
                setAdminFormError(response.message || 'Błąd podczas aktualizacji administratora.');
            }
        } catch (error) {
            console.error('Błąd podczas aktualizacji administratora:', error);
            setAdminFormError('Błąd podczas aktualizacji administratora.');
        } finally {
            setAdminFormLoading(false);
        }
    };

    // Tworzenie nowego administratora
    const handleCreateAdmin = async (e) => {
        e.preventDefault();
        setAdminFormLoading(true);
        setAdminFormError('');
        setAdminFormSuccess('');

        try {
            if (!editedAdminData.email || !editedAdminData.password) {
                setAdminFormError('Podaj email i hasło.');
                setAdminFormLoading(false);
                return;
            }

            const response = await Api.createAdmin(editedAdminData.email, editedAdminData.password);
            if (!response.error) {
                setAdminFormSuccess('Nowy administrator został utworzony.');
                setEditedAdminData({ email: '', password: '' });
                fetchAdmins();
            } else {
                setAdminFormError(response.message || 'Błąd podczas tworzenia administratora.');
            }
        } catch (error) {
            console.error('Błąd podczas tworzenia administratora:', error);
            setAdminFormError('Błąd podczas tworzenia administratora.');
        } finally {
            setAdminFormLoading(false);
        }
    };

    // Usuwanie administratora
    const handleDeleteAdmin = async (adminId) => {
        const confirmDelete = window.confirm('Czy na pewno chcesz usunąć tego administratora?');
        if (!confirmDelete) return;

        setIsDeleting(true);
        setDeletingAdminId(adminId);

        try {
            const response = await Api.deleteAdmin(adminId);
            if (!response.error) {
                ToastHelper.showSuccessToast('Administrator został usunięty.');
                fetchAdmins();
            } else {
                ToastHelper.showErrorToast('Błąd podczas usuwania administratora.');
            }
        } catch (error) {
            console.error('Błąd podczas usuwania administratora:', error);
            ToastHelper.showErrorToast('Błąd podczas usuwania administratora.');
        } finally {
            setIsDeleting(false);
            setDeletingAdminId(null);
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 p-6 pt-24">
            <div className="max-w-6xl mx-auto space-y-8">
                {/* Sekcja Profilu Administratora */}
                <div className="bg-gray-800 p-6 rounded-lg shadow-md">
                    <h2 className="text-2xl font-semibold text-white mb-4">Profil Administratora</h2>
                    {profileLoading ? (
                        <p className="text-gray-400">Ładowanie profilu...</p>
                    ) : profileError ? (
                        <p className="text-red-500">{profileError}</p>
                    ) : adminProfile && (
                        <div className="space-y-4">
                            {/* Pole Email */}
                            <div className="relative">
                                <label className="block text-sm font-medium text-gray-300 mb-1">Email</label>
                                {editingProfileField === 'email' ? (
                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="email"
                                            name="email"
                                            value={editedProfileData.email}
                                            onChange={handleProfileInputChange}
                                            className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            required
                                        />
                                        <button
                                            onClick={() => handleProfileSave('email')}
                                            className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                                            disabled={profileLoading}
                                        >
                                            Zapisz
                                        </button>
                                        <button
                                            onClick={() => setEditingProfileField(null)}
                                            className="px-3 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors duration-200"
                                        >
                                            Anuluj
                                        </button>
                                    </div>
                                ) : (
                                    <div className="flex items-center justify-between">
                                        <p className="text-white">{adminProfile.email}</p>
                                        <button
                                            className="text-blue-400 hover:text-blue-600"
                                            onClick={() => handleProfileFieldEdit('email')}
                                        >
                                            <FiEdit />
                                        </button>
                                    </div>
                                )}
                            </div>

                            {/* Pole Hasło */}
                            <div className="relative">
                                <label className="block text-sm font-medium text-gray-300 mb-1">Hasło</label>
                                {editingProfileField === 'password' ? (
                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="password"
                                            name="password"
                                            value={editedProfileData.password || ''}
                                            onChange={handleProfileInputChange}
                                            className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            placeholder="Nowe hasło"
                                        />
                                        <button
                                            onClick={() => handleProfileSave('password')}
                                            className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                                            disabled={profileLoading}
                                        >
                                            Zapisz
                                        </button>
                                        <button
                                            onClick={() => setEditingProfileField(null)}
                                            className="px-3 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors duration-200"
                                        >
                                            Anuluj
                                        </button>
                                    </div>
                                ) : (
                                    <div className="flex items-center justify-between">
                                        <p className="text-white">********</p>
                                        <button
                                            className="text-blue-400 hover:text-blue-600"
                                            onClick={() => handleProfileFieldEdit('password')}
                                        >
                                            <FiEdit />
                                        </button>
                                    </div>
                                )}
                            </div>

                            {profileError && <p className="text-red-500 text-sm">{profileError}</p>}
                            {profileSuccess && <p className="text-green-500 text-sm">{profileSuccess}</p>}
                        </div>
                    )}
                </div>

                {/* Sekcja Zarządzania Administratorami */}
                <div className="bg-gray-800 p-6 rounded-lg shadow-md">
                    <div className="flex items-center justify-between mb-4">
                        <h2 className="text-2xl font-semibold text-white">Zarządzanie Administratorami</h2>
                        <button
                            onClick={() => handleEditAdminClick({ id: 'new', email: '', password: '' })}
                            className="flex items-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200"
                        >
                            <FiPlus className="mr-2" /> Dodaj Administratora
                        </button>
                    </div>

                    {/* Formularz Dodawania Nowego Administratora */}
                    {editingAdminId === 'new' && (
                        <div className="mb-6 bg-gray-700 p-4 rounded-lg shadow-inner">
                            <h3 className="text-xl font-semibold text-white mb-3">Dodaj Nowego Administratora</h3>
                            <form onSubmit={handleCreateAdmin} className="space-y-4">
                                <div>
                                    <label htmlFor="newAdminEmail" className="block text-sm font-medium text-gray-300 mb-1">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="newAdminEmail"
                                        name="email"
                                        value={editedAdminData.email || ''}
                                        onChange={handleAdminInputChange}
                                        className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-600 text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="newAdminPassword" className="block text-sm font-medium text-gray-300 mb-1">
                                        Hasło
                                    </label>
                                    <input
                                        type="password"
                                        id="newAdminPassword"
                                        name="password"
                                        value={editedAdminData.password || ''}
                                        onChange={handleAdminInputChange}
                                        className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-600 text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                                        required
                                    />
                                </div>
                                {adminFormError && <p className="text-red-500 text-sm">{adminFormError}</p>}
                                {adminFormSuccess && <p className="text-green-500 text-sm">{adminFormSuccess}</p>}
                                <div className="flex space-x-2">
                                    <button
                                        type="submit"
                                        className={`flex-1 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200 flex items-center justify-center ${adminFormLoading ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                        disabled={adminFormLoading}
                                    >
                                        {adminFormLoading ? 'Tworzenie...' : 'Dodaj Administratora'}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleCancelEditAdmin}
                                        className="flex-1 px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors duration-200 flex items-center justify-center"
                                    >
                                        <FiX className="mr-2" /> Anuluj
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}

                    {/* Lista Administratorów */}
                    <div className="overflow-x-auto">
                        {adminsLoading ? (
                            <div className="flex items-center justify-center py-10">
                                <svg
                                    className="animate-spin h-8 w-8 text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8v8H4z"
                                    ></path>
                                </svg>
                            </div>
                        ) : adminsError ? (
                            <p className="text-red-500 text-center">{adminsError}</p>
                        ) : (
                            <table className="min-w-full bg-gray-700 rounded-lg overflow-hidden">
                                <thead>
                                    <tr>
                                        <th className="py-2 px-4 text-left text-sm font-semibold text-gray-300">ID</th>
                                        <th className="py-2 px-4 text-left text-sm font-semibold text-gray-300">Email</th>
                                        <th className="py-2 px-4 text-left text-sm font-semibold text-gray-300">Data Utworzenia</th>
                                        <th className="py-2 px-4 text-left text-sm font-semibold text-gray-300">Akcje</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {admins.map(admin => (
                                        <tr key={admin.id} className="border-t border-gray-600">
                                            <td className="py-2 px-4 text-sm text-gray-200">{admin.id}</td>
                                            <td className="py-2 px-4 text-sm text-gray-200">{admin.email}</td>
                                            <td className="py-2 px-4 text-sm text-gray-200">
                                                {new Date(admin.created_at).toLocaleDateString()}
                                            </td>
                                            <td className="py-2 px-4 text-sm text-gray-200 flex space-x-2">
                                                <button
                                                    onClick={() => handleEditAdminClick(admin)}
                                                    className="px-3 py-1 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition-colors duration-200 flex items-center"
                                                >
                                                    <FiEdit className="mr-1" /> Edytuj
                                                </button>
                                                <button
                                                    onClick={() => handleDeleteAdmin(admin.id)}
                                                    className={`px-3 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-200 flex items-center ${isDeleting && deletingAdminId === admin.id
                                                        ? 'opacity-50 cursor-not-allowed'
                                                        : ''
                                                        }`}
                                                    disabled={isDeleting && deletingAdminId === admin.id}
                                                >
                                                    {isDeleting && deletingAdminId === admin.id ? (
                                                        'Usuwanie...'
                                                    ) : (
                                                        <>
                                                            <FiTrash2 className="mr-1" /> Usuń
                                                        </>
                                                    )}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    {admins.length === 0 && (
                                        <tr>
                                            <td colSpan="4" className="py-4 text-center text-gray-400">
                                                Brak administratorów.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>

                    {/* Formularz Edycji Administratora */}
                    {editingAdminId && editingAdminId !== 'new' && (
                        <div className="mt-6 bg-gray-700 p-4 rounded-lg shadow-inner">
                            <h3 className="text-xl font-semibold text-white mb-3">Edycja Administratora</h3>
                            <form onSubmit={handleUpdateAdmin} className="space-y-4">
                                <div>
                                    <label htmlFor="editAdminEmail" className="block text-sm font-medium text-gray-300 mb-1">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="editAdminEmail"
                                        name="email"
                                        value={editedAdminData.email}
                                        onChange={handleAdminInputChange}
                                        className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-600 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="editAdminPassword" className="block text-sm font-medium text-gray-300 mb-1">
                                        Nowe Hasło
                                    </label>
                                    <input
                                        type="password"
                                        id="editAdminPassword"
                                        name="password"
                                        value={editedAdminData.password}
                                        onChange={handleAdminInputChange}
                                        className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-600 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Wprowadź nowe hasło, jeśli chcesz je zmienić"
                                    />
                                </div>
                                {adminFormError && <p className="text-red-500 text-sm">{adminFormError}</p>}
                                {adminFormSuccess && <p className="text-green-500 text-sm">{adminFormSuccess}</p>}
                                <div className="flex space-x-2">
                                    <button
                                        type="submit"
                                        className={`flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center ${adminFormLoading ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                        disabled={adminFormLoading}
                                    >
                                        {adminFormLoading ? 'Aktualizowanie...' : 'Aktualizuj Administratora'}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleCancelEditAdmin}
                                        className="flex-1 px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors duration-200 flex items-center justify-center"
                                    >
                                        <FiX className="mr-2" /> Anuluj
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
