// src/pages/Home.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FiTable,
  FiClipboard,
  FiCalendar,
  FiChevronDown,
  FiPlus,
} from 'react-icons/fi';
import { Transition } from '@headlessui/react';
import { useAuth } from '../components/auth';
import Api from '../lib/Api';
import ToastHelper from '../helpers/toast_helper';
import QrCodeGenerator from '../components/QrCodeGenerator'; // Import komponentu QR

function Home() {
  const [restaurantId, setRestaurantId] = useState(null);
  const [tables, setTables] = useState([]);
  const [orders, setOrders] = useState([]); // Nowy stan dla zamówień
  const [selectedTable, setSelectedTable] = useState(null);

  const [isTablesOpen, setIsTablesOpen] = useState(true);
  const [isOrdersOpen, setIsOrdersOpen] = useState(true);
  const [isReservationsOpen, setIsReservationsOpen] = useState(true);

  // State for Add Table Modal
  const [isAddTableOpen, setIsAddTableOpen] = useState(false);
  const [newTableNumber, setNewTableNumber] = useState('');
  const [isAddingTable, setIsAddingTable] = useState(false);
  const [addTableError, setAddTableError] = useState(null);

  // Nowe stany dla szczegółów zamówienia
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderLoading, setOrderLoading] = useState(false);
  const [orderError, setOrderError] = useState(null);

  // New state variables for table order
  const [tableOrder, setTableOrder] = useState(null);
  const [tableOrderLoading, setTableOrderLoading] = useState(false);
  const [tableOrderError, setTableOrderError] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();

  const toggleTables = () => {
    setIsTablesOpen(!isTablesOpen);
  };

  const toggleOrders = () => {
    setIsOrdersOpen(!isOrdersOpen);
  };

  const toggleReservations = () => {
    setIsReservationsOpen(!isReservationsOpen);
  };

  // Funkcja do normalizacji statusu
  const normalizeString = (str) => {
    return str
      .normalize('NFD') // Decompose combined letters into base letters and diacritics
      .replace(/[\u0300-\u036f]/g, '') // Remove diacritic marks
      .toLowerCase()
      .trim();
  };

  // Funkcja do pobierania stolików
  const fetchTables = useCallback(async () => {
    if (!restaurantId) {
      console.error('restaurantId nie jest ustawione');
      return;
    }
    try {
      const response = await Api.GetTables(restaurantId);
      if (response && Array.isArray(response)) {
        setTables(response);
      } else {
        console.error('Nieprawidłowa odpowiedź z API:', response);
        setTables([]);
      }
    } catch (error) {
      console.error('Błąd podczas pobierania stolików:', error);
      setTables([]);
    }
  }, [restaurantId]);

  // Funkcja do pobierania zamówień
  const fetchOrders = useCallback(async () => {
    if (!restaurantId) {
      console.error('restaurantId nie jest ustawione');
      return;
    }
    try {
      const response = await Api.GetExistingOrders(restaurantId);
      if (response && Array.isArray(response)) {
        setOrders(response);
      } else {
        console.error('Nieprawidłowa odpowiedź z API:', response);
        setOrders([]);
      }
    } catch (error) {
      console.error('Błąd podczas pobierania zamówień:', error);
      setOrders([]);
    }
  }, [restaurantId]);

  useEffect(() => {
    const storedRestaurantId = localStorage.getItem('restaurant_id');
    if (storedRestaurantId) {
      setRestaurantId(storedRestaurantId);
    } else {
      auth.logout();
      navigate('/login');
    }
  }, [navigate, auth]);

  useEffect(() => {
    if (restaurantId) {
      fetchTables();
      fetchOrders(); // Pobierz zamówienia po ustawieniu restaurantId
    }
  }, [restaurantId, fetchTables, fetchOrders]);

  const openTableDetails = (table) => {
    console.log('Table clicked:', table);
    setSelectedTable(table);
    setShowQrCode(false); // Reset QR Code display when opening a new table
  };

  const closeTableDetails = () => {
    setSelectedTable(null);
    setShowQrCode(false);
    setTableOrder(null); // Reset table order when closing the modal
  };

  // Functions to handle Add Table Modal
  const openAddTableModal = () => {
    setNewTableNumber('');
    setAddTableError(null);
    setIsAddTableOpen(true);
  };

  const closeAddTableModal = () => {
    setIsAddTableOpen(false);
  };

  const handleAddTable = async (e) => {
    e.preventDefault();
    if (!newTableNumber.trim()) {
      setAddTableError('Numer stolika nie może być pusty.');
      return;
    }

    setIsAddingTable(true);
    setAddTableError(null);
    try {
      // Zakładam, że Api ma metodę AddTable
      const response = await Api.AddTable(restaurantId, newTableNumber);
      console.log(response);
      if (response.error === false && response.message === null) {
        await fetchTables();
        closeAddTableModal();
        ToastHelper.showSuccessToast('Dodano nowy stolik!');
      } else {
        setAddTableError(response.message || 'Nie udało się dodać stolika.');
      }
    } catch (error) {
      console.error('Błąd podczas dodawania stolika:', error);
      setAddTableError('Błąd podczas dodawania stolika.');
    } finally {
      setIsAddingTable(false);
    }
  };

  // State to control QR Code display
  const [showQrCode, setShowQrCode] = useState(false);

  // Funkcje do obsługi szczegółów zamówienia
  const openOrderDetails = async (order) => {
    setSelectedOrder(order);
    setOrderLoading(true);
    setOrderError(null);
    try {
      const orderDetails = await Api.GetOrderDetails(restaurantId, order.order_id);
      setSelectedOrder({ ...order, products: orderDetails.products });
    } catch (error) {
      setOrderError(error.message);
    } finally {
      setOrderLoading(false);
    }
  };

  const closeOrderDetails = () => {
    setSelectedOrder(null);
  };

  // Funkcja do pobierania zamówienia dla stolika
  const fetchTableOrder = useCallback(
    async (tableId) => {
      console.log('Fetching order for table ID:', tableId); // Debug log
      setTableOrderLoading(true);
      setTableOrderError(null);
      try {
        const order = await Api.getTableOrder(restaurantId, tableId);
        console.log('Order received:', order); // Debug log
        setTableOrder(order);
      } catch (error) {
        console.error('Error fetching table order:', error);
        setTableOrderError(error.message);
      } finally {
        setTableOrderLoading(false);
      }
    },
    [restaurantId]
  );

  // Fetch table order when selectedTable changes
  useEffect(() => {
    console.log('useEffect for selectedTable called'); // Debug log
    if (selectedTable) {
      const statusNormalized = normalizeString(selectedTable.status);
      console.log('Normalized status:', statusNormalized); // Debug log
      if (statusNormalized === 'zajety') {
        fetchTableOrder(selectedTable.id);
      } else {
        setTableOrder(null);
      }
    } else {
      setTableOrder(null);
    }
  }, [selectedTable, fetchTableOrder]);

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Main Content */}
      <div className="flex flex-col md:flex-row justify-center items-start mt-16 pt-4 px-4 space-y-4 md:space-y-0 md:space-x-4">
        {/* Tables Section */}
        <div className="w-full md:w-1/3 bg-gray-700 rounded-lg text-white shadow-lg">
          <div className="flex items-stretch justify-between bg-gray-800 hover:bg-gray-700 transition-colors duration-200">
            {/* Header Button */}
            <button
              className="flex-grow flex items-center justify-between px-4 py-4 focus:outline-none"
              onClick={toggleTables}
            >
              <div className="flex items-center space-x-2">
                <FiTable className="text-2xl" />
                <h2 className="text-xl font-bold">Stoliki</h2>
              </div>
              <FiChevronDown
                className={`transition-transform ${isTablesOpen ? 'rotate-180' : ''}`}
              />
            </button>
            <button
              className="p-2 focus:outline-none"
              onClick={openAddTableModal}
              title="Dodaj stolik"
            >
              <FiPlus className="text-xl transition-colors duration-200 hover:text-green-500" />
            </button>
          </div>
          <Transition
            show={isTablesOpen}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-y-0 opacity-0"
            enterTo="transform scale-y-100 opacity-100"
            leave="transition duration-200 ease-in"
            leaveFrom="transform scale-y-100 opacity-100"
            leaveTo="transform scale-y-0 opacity-0"
            className="origin-top"
          >
            <div className="p-4 max-h-80 overflow-y-auto">
              {tables.length > 0 ? (
                <ul className="space-y-2">
                  {tables.map((table) => (
                    <li
                      key={table.id}
                      onClick={() => openTableDetails(table)}
                      className="flex items-center justify-between p-3 bg-gray-800 rounded-md hover:bg-gray-600 cursor-pointer"
                    >
                      <span>{`Stolik ${table.table_number}`}</span>
                      <span
                        className={`px-2 py-1 rounded-full text-xs ${table.status === 'Wolny'
                            ? 'bg-green-500 text-white'
                            : 'bg-red-500 text-white'
                          }`}
                      >
                        {table.status}
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-center">(puste)</p>
              )}
            </div>
          </Transition>
        </div>

        {/* Orders Section */}
        <div className="w-full md:w-1/3 bg-gray-700 text-white rounded-lg shadow-lg">
          <button
            className="w-full flex items-center justify-between px-4 py-4 bg-gray-800 hover:bg-gray-700 focus:outline-none"
            onClick={toggleOrders}
          >
            <div className="flex items-center space-x-2">
              <FiClipboard className="text-2xl" />
              <h2 className="text-xl font-bold">Zamówienia</h2>
            </div>
            <FiChevronDown
              className={`transition-transform ${isOrdersOpen ? 'rotate-180' : ''}`}
            />
          </button>
          <Transition
            show={isOrdersOpen}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-y-0 opacity-0"
            enterTo="transform scale-y-100 opacity-100"
            leave="transition duration-200 ease-in"
            leaveFrom="transform scale-y-100 opacity-100"
            leaveTo="transform scale-y-0 opacity-0"
            className="origin-top"
          >
            <div className="p-4 max-h-80 overflow-y-auto">
              {orders.length > 0 ? (
                <ul className="space-y-2">
                  {orders.map((order) => (
                    <li
                      key={order.order_id}
                      className="flex flex-col p-3 bg-gray-800 rounded-md"
                    >
                      <div className="flex justify-between items-center">
                        <span>{`Zamówienie #${order.order_id}`}</span>
                        <div className="flex items-center space-x-2">
                          <span
                            className={`px-2 py-1 rounded-full text-xs ${order.status === 'Złożone'
                                ? 'bg-yellow-500 text-yellow'
                                : order.status === 'Wydane'
                                  ? 'bg-green-500 text-white'
                                  : order.status === 'Anulowane'
                                    ? 'bg-red-500 text-white'
                                    : 'bg-gray-500 text-white'
                              }`}
                          >
                            {order.status}
                          </span>
                          <button
                            onClick={() => openOrderDetails(order)}
                            className="px-3 py-1 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none"
                          >
                            Szczegóły
                          </button>
                        </div>
                      </div>
                      <div className="mt-2">
                        <p>
                          <span className="font-medium">Stolik:</span>{' '}
                          {order.table_number}
                        </p>
                        <p>
                          <span className="font-medium">Data utworzenia:</span>{' '}
                          {new Date(order.created_at).toLocaleString()}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-center">(brak zamówień)</p>
              )}
            </div>
          </Transition>
        </div>

        {/* Reservations Section */}
        <div className="w-full md:w-1/3 bg-gray-700 text-white rounded-lg shadow-lg">
          <button
            className="w-full flex items-center justify-between px-4 py-4 bg-gray-800 hover:bg-gray-700 focus:outline-none"
            onClick={toggleReservations}
          >
            <div className="flex items-center space-x-2">
              <FiCalendar className="text-2xl" />
              <h2 className="text-xl font-bold">Rezerwacje</h2>
            </div>
            <FiChevronDown
              className={`transition-transform ${isReservationsOpen ? 'rotate-180' : ''
                }`}
            />
          </button>
          <Transition
            show={isReservationsOpen}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-y-0 opacity-0"
            enterTo="transform scale-y-100 opacity-100"
            leave="transition duration-200 ease-in"
            leaveFrom="transform scale-y-100 opacity-100"
            leaveTo="transform scale-y-0 opacity-0"
            className="origin-top"
          >
            <div className="p-4 max-h-80 overflow-y-auto">
              <p className="text-center">(puste)</p>
            </div>
          </Transition>
        </div>
      </div>

      {/* Table Details Modal */}
      {selectedTable && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Transition
            show={true}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-75 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-200 ease-in"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-75 opacity-0"
          >
            {/* Ograniczenie wysokości modalu do 1/3 wysokości ekranu */}
            <div className="bg-gray-800 text-gray-200 p-6 rounded-lg shadow-lg w-full max-w-lg max-h-[80vh] overflow-y-auto">
              <h2 className="text-2xl mb-4 font-semibold">{`Szczegóły stolika ${selectedTable.table_number}`}</h2>
              <p className="mb-2">
                <span className="font-medium">Status:</span> {selectedTable.status}
              </p>
              <p className="mb-4">
                <span className="font-medium">ID:</span> {selectedTable.id}
              </p>

              {/* Przycisk do toggle'owania wyświetlania QR Code */}
              <button
                onClick={() => setShowQrCode(!showQrCode)}
                className="mb-4 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none"
              >
                {showQrCode ? 'Ukryj QR Code' : 'Generuj QR Code'}
              </button>

              {/* Komponent generujący QR Code */}
              {showQrCode && (
                <div className="flex justify-center">
                  <QrCodeGenerator
                    restaurantId={restaurantId}
                    tableId={selectedTable.id}
                  />
                </div>
              )}

              {/* Display order if the table is occupied and order exists */}
              {tableOrderLoading ? (
                <p className="text-center">Ładowanie zamówienia...</p>
              ) : tableOrderError ? (
                <p className="text-red-500 text-center">Błąd: {tableOrderError}</p>
              ) : tableOrder ? (
                <>
                  <h3 className="text-xl mb-2">Aktualne zamówienie:</h3>
                  {tableOrder.products && tableOrder.products.length > 0 ? (
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-gray-700 text-white rounded-lg mb-4">
                        <thead>
                          <tr>
                            <th className="py-2 px-4 border-b border-gray-600 text-left">
                              Nazwa Produktu
                            </th>
                            <th className="py-2 px-4 border-b border-gray-600 text-left">
                              Ilość
                            </th>
                            <th className="py-2 px-4 border-b border-gray-600 text-left">
                              Cena Jednostkowa
                            </th>
                            <th className="py-2 px-4 border-b border-gray-600 text-left">
                              Cena Całkowita
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableOrder.products.map((product) => (
                            <tr key={product.product_id}>
                              <td className="py-2 px-4 border-b border-gray-600">
                                {product.product_name}
                              </td>
                              <td className="py-2 px-4 border-b border-gray-600">
                                {product.quantity}
                              </td>
                              <td className="py-2 px-4 border-b border-gray-600">
                                {product.price.toFixed(2)} PLN
                              </td>
                              <td className="py-2 px-4 border-b border-gray-600">
                                {(
                                  product.price * product.quantity
                                ).toFixed(2)}{' '}
                                PLN
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="mb-4">Brak produktów w zamówieniu.</p>
                  )}
                  {/* Display total price */}
                  <p className="text-right font-semibold">
                    {`Łączna Cena: ${tableOrder.products
                        ? tableOrder.products
                          .reduce(
                            (acc, product) =>
                              acc + product.price * product.quantity,
                            0
                          )
                          .toFixed(2)
                        : '0.00'
                      } PLN`}
                  </p>
                </>
              ) : (
                <p className="text-center">Brak aktywnego zamówienia dla tego stolika.</p>
              )}

              <button
                onClick={closeTableDetails}
                className="w-full mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none"
              >
                Zamknij
              </button>
            </div>
          </Transition>
        </div>
      )}

      {/* Add Table Modal */}
      {isAddTableOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Transition
            show={isAddTableOpen}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-75 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-200 ease-in"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-75 opacity-0"
          >
            <div className="bg-gray-800 text-gray-200 p-6 rounded-lg shadow-lg w-full max-w-md max-h-full overflow-y-auto">
              <h2 className="text-2xl mb-4 font-semibold">Dodaj nowy stolik</h2>
              <form onSubmit={handleAddTable}>
                <div className="mb-4">
                  <label
                    htmlFor="tableNumber"
                    className="block text-sm font-medium mb-1"
                  >
                    Numer stolika
                  </label>
                  <input
                    type="text"
                    id="tableNumber"
                    value={newTableNumber}
                    onChange={(e) => setNewTableNumber(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-600"
                    placeholder="Wprowadź numer stolika"
                    required
                  />
                </div>
                {addTableError && (
                  <p className="text-red-500 text-sm mb-4">{addTableError}</p>
                )}
                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={closeAddTableModal}
                    className="px-4 py-2 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-700 transition-colors duration-200 focus:outline-none"
                  >
                    Anuluj
                  </button>
                  <button
                    type="submit"
                    className={`px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200 flex items-center ${isAddingTable ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    disabled={isAddingTable}
                  >
                    {isAddingTable && (
                      <svg
                        className="animate-spin h-5 w-5 mr-2 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v8H4z"
                        ></path>
                      </svg>
                    )}
                    {isAddingTable ? 'Dodawanie...' : 'Dodaj'}
                  </button>
                </div>
              </form>
            </div>
          </Transition>
        </div>
      )}

      {/* Order Details Modal */}
      {selectedOrder && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
          <div className="bg-gray-800 text-gray-200 p-6 rounded-lg shadow-lg w-full max-w-2xl max-h-full overflow-y-auto">
            {orderLoading ? (
              <p className="text-center">Ładowanie szczegółów zamówienia...</p>
            ) : orderError ? (
              <p className="text-red-500 text-center">Błąd: {orderError}</p>
            ) : (
              <>
                <h2 className="text-2xl mb-4 font-semibold">{`Zamówienie #${selectedOrder.order_id}`}</h2>
                <p className="mb-2">
                  <span className="font-medium">Status:</span>{' '}
                  {selectedOrder.status || 'Nieznany'}
                </p>
                <p className="mb-4">
                  <span className="font-medium">Data Utworzenia:</span>{' '}
                  {selectedOrder.created_at
                    ? new Date(selectedOrder.created_at).toLocaleString()
                    : 'Nieznana'}
                </p>

                {/* Lista produktów */}
                <h3 className="text-xl mb-2">Produkty:</h3>
                {selectedOrder.products && selectedOrder.products.length > 0 ? (
                  <div className="overflow-x-auto">
                    <table className="min-w-full bg-gray-700 text-white rounded-lg mb-4">
                      <thead>
                        <tr>
                          <th className="py-2 px-4 border-b border-gray-600 text-left">
                            Nazwa Produktu
                          </th>
                          <th className="py-2 px-4 border-b border-gray-600 text-left">
                            Ilość
                          </th>
                          <th className="py-2 px-4 border-b border-gray-600 text-left">
                            Cena Jednostkowa
                          </th>
                          <th className="py-2 px-4 border-b border-gray-600 text-left">
                            Cena Całkowita
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedOrder.products.map((product) => (
                          <tr key={product.product_id}>
                            <td className="py-2 px-4 border-b border-gray-600">
                              {product.product_name}
                            </td>
                            <td className="py-2 px-4 border-b border-gray-600">
                              {product.quantity}
                            </td>
                            <td className="py-2 px-4 border-b border-gray-600">
                              {product.price.toFixed(2)} PLN
                            </td>
                            <td className="py-2 px-4 border-b border-gray-600">
                              {(
                                product.price * product.quantity
                              ).toFixed(2)}{' '}
                              PLN
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="mb-4">Brak produktów w zamówieniu.</p>
                )}

                {/* Obliczenie i wyświetlenie całkowitej ceny */}
                <p className="text-right font-semibold">
                  {`Łączna Cena: ${selectedOrder.products
                      ? selectedOrder.products
                        .reduce(
                          (acc, product) => acc + product.price * product.quantity,
                          0
                        )
                        .toFixed(2)
                      : '0.00'
                    } PLN`}
                </p>

                {/* Przycisk do zmiany statusu na "Wydane" */}
                {selectedOrder.status !== 'Wydane' && selectedOrder.status !== 'Anulowane' && (
                  <button
                    onClick={async () => {
                      try {
                        await Api.CompleteOrder(
                          restaurantId,
                          selectedOrder.order_id
                        );
                        ToastHelper.showSuccessToast(
                          'Status zamówienia zmieniony na "Wydane"'
                        );
                        // Aktualizacja statusu zamówienia lokalnie
                        setSelectedOrder({
                          ...selectedOrder,
                          status: 'Wydane',
                        });
                        // Odświeżenie listy zamówień
                        fetchOrders();
                        fetchTables();
                      } catch (error) {
                        ToastHelper.showErrorToast(error.message);
                      }
                    }}
                    className="mt-4 mr-4 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none w-full md:w-auto"
                  >
                    Zmień status na "Wydane"
                  </button>
                )}

                {/* Przycisk do zmiany statusu na "Anulowane" */}
                {selectedOrder.status !== 'Anulowane' && selectedOrder.status !== 'Wydane' && (
                  <button
                    onClick={async () => {
                      try {
                        await Api.CancelOrder(
                          restaurantId,
                          selectedOrder.order_id
                        );
                        ToastHelper.showSuccessToast(
                          'Status zamówienia zmieniony na "Anulowane"'
                        );
                        // Aktualizacja statusu zamówienia lokalnie
                        setSelectedOrder({
                          ...selectedOrder,
                          status: 'Anulowane',
                        });
                        // Odświeżenie listy zamówień
                        fetchOrders();
                        fetchTables();
                      } catch (error) {
                        ToastHelper.showErrorToast(error.message);
                      }
                    }}
                    className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 focus:outline-none w-full md:w-auto"
                  >
                    Zmień status na "Anulowane"
                  </button>
                )}
                <button
                  onClick={closeOrderDetails}
                  className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none w-full md:w-auto"
                >
                  Zamknij
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
