// src/components/Navbar.js
import { NavLink, useNavigate } from 'react-router-dom';
import { FiChevronDown, FiSettings, FiLogOut, FiHelpCircle, FiMenu, FiX } from 'react-icons/fi';
import logo from '../../img/menuscan_logo3bg.png';
import { useAuth } from '../../components/auth';
import { useState, useEffect, useRef } from 'react';

export default function Navbar() {
    const [restaurantName, setRestaurantName] = useState(null);
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const navigate = useNavigate();
    const auth = useAuth();
    const dropdownRef = useRef(null);

    const toggleUserDropdown = () => {
        setIsUserDropdownOpen((prev) => !prev);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen((prev) => !prev);
    };

    const handleLogout = () => {
        auth.logout();
        console.log('Wylogowano');
        navigate('/login'); // Nawiguj po wylogowaniu
    };

    useEffect(() => {
        const storedRestaurantName = localStorage.getItem('restaurant_name');
        if (storedRestaurantName) {
            setRestaurantName(storedRestaurantName);
        } else {
            auth.logout();
            navigate('/login');
        }
    }, [navigate, auth]);

    // Zmiana listenera na 'click' i poprawne obsługiwanie zdarzeń
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsUserDropdownOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <header className="w-full flex justify-between items-center px-6 bg-gray-800 text-white fixed top-0 left-0 z-50 shadow-md h-16">
                <div className="flex items-center space-x-4">
                    <NavLink to="/">
                        <img src={logo} alt="Logo" className="h-8 md:h-12" />
                    </NavLink>
                    <nav className="hidden md:flex items-center space-x-6 text-lg font-medium">
                        <NavLink
                            to="/"
                            className={({ isActive }) =>
                                `select-none hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                            }
                        >
                            Start
                        </NavLink>
                        <NavLink
                            to="/order-history"
                            className={({ isActive }) =>
                                `select-none hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                            }
                        >
                            Historia zamówień
                        </NavLink>
                        <NavLink
                            to="/products"
                            className={({ isActive }) =>
                                `select-none hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                            }
                        >
                            Produkty
                        </NavLink>
                        <NavLink
                            to="/statistics"
                            className={({ isActive }) =>
                                `select-none hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                            }
                        >
                            Statystyki
                        </NavLink>
                        <NavLink
                            to="/settings"
                            className={({ isActive }) =>
                                `select-none hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                            }
                        >
                            Ustawienia
                        </NavLink>
                    </nav>
                </div>
                <div className="flex items-center space-x-4">
                    {/* Przycisk menu mobilnego */}
                    <button
                        className="md:hidden text-white focus:outline-none"
                        onClick={toggleMobileMenu}
                        aria-label="Menu"
                    >
                        <div className="relative w-6 h-6">
                            <FiMenu
                                size={24}
                                className={`absolute inset-0 transform transition-all duration-500 ${isMobileMenuOpen ? 'opacity-0 rotate-90' : 'opacity-100 rotate-0'}`}
                            />
                            <FiX
                                size={24}
                                className={`absolute inset-0 transform transition-all duration-500 ${isMobileMenuOpen ? 'opacity-100 rotate-0' : 'opacity-0 rotate-90'}`}
                            />
                        </div>
                    </button>
                    {/* Dropdown użytkownika */}
                    <div className="relative hidden md:flex" ref={dropdownRef}>
                        <button
                            onClick={toggleUserDropdown}
                            className="flex items-center space-x-2 text-lg focus:outline-none hover:text-gray-400"
                            aria-haspopup="true"
                            aria-expanded={isUserDropdownOpen}
                        >
                            <span>{restaurantName}</span>
                            <FiChevronDown
                                className={`transition-transform duration-300 ${isUserDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                            />
                        </button>
                        <ul
                            className={`absolute right-0 top-full mt-1 bg-gray-700 text-white shadow-lg rounded-md py-2 w-48 z-60 transition ease-out duration-200 transform origin-top-right ${isUserDropdownOpen
                                ? 'opacity-100 scale-100 pointer-events-auto'
                                : 'opacity-0 scale-95 pointer-events-none'
                                }`}
                        >
                            <li
                                className="px-4 py-2 hover:bg-gray-600 cursor-pointer flex items-center space-x-2"
                                onClick={(e) => {
                                    e.stopPropagation(); // Zapobiega propagacji zdarzenia
                                    navigate('/settings');
                                    setIsUserDropdownOpen(false);
                                }}
                            >
                                <FiSettings />
                                <span>Ustawienia</span>
                            </li>
                            <li
                                className="px-4 py-2 hover:bg-gray-600 cursor-pointer flex items-center space-x-2"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate('/support');
                                    setIsUserDropdownOpen(false);
                                }}
                            >
                                <FiHelpCircle />
                                <span>Pomoc techniczna</span>
                            </li>
                            <li
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleLogout();
                                    setIsUserDropdownOpen(false);
                                }}
                                className="px-4 py-2 hover:bg-gray-600 cursor-pointer flex items-center space-x-2"
                            >
                                <FiLogOut />
                                <span>Wyloguj</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            {/* Menu mobilne */}
            <div
                className={`md:hidden bg-gray-800 text-white px-6 overflow-hidden fixed top-16 left-0 right-0 z-40 transition-max-height duration-500 ease-in-out ${isMobileMenuOpen ? 'max-h-screen' : 'max-h-0'
                    }`}
            >
                <nav className="flex flex-col space-y-4 text-lg font-medium py-4">
                    <NavLink
                        to="/"
                        className={({ isActive }) =>
                            `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                        }
                        onClick={toggleMobileMenu}
                    >
                        Start
                    </NavLink>
                    <NavLink
                        to="/order-history"
                        className={({ isActive }) =>
                            `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                        }
                        onClick={toggleMobileMenu}
                    >
                        Historia zamówień
                    </NavLink>
                    <NavLink
                        to="/products"
                        className={({ isActive }) =>
                            `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                        }
                        onClick={toggleMobileMenu}
                    >
                        Produkty
                    </NavLink>
                    <NavLink
                        to="/statistics"
                        className={({ isActive }) =>
                            `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                        }
                        onClick={toggleMobileMenu}
                    >
                        Statystyki
                    </NavLink>
                    <NavLink
                        to="/settings"
                        className={({ isActive }) =>
                            `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                        }
                        onClick={toggleMobileMenu}
                    >
                        Ustawienia
                    </NavLink>
                    <div className="border-t border-gray-700 mt-4 pt-4">
                        <div className="flex items-center space-x-2">
                            <span>{`${restaurantName}`}</span>
                        </div>
                        <button
                            onClick={handleLogout}
                            className="flex items-center space-x-2 hover:text-gray-300 w-full text-left mt-4"
                        >
                            <FiLogOut />
                            <span>Wyloguj</span>
                        </button>
                        {/* Dodanie przycisku Pomoc techniczna w menu mobilnym */}
                        <button
                            onClick={() => {
                                navigate('/support');
                                toggleMobileMenu();
                            }}
                            className="flex items-center space-x-2 hover:text-gray-300 w-full text-left mt-2"
                        >
                            <FiHelpCircle />
                            <span>Pomoc techniczna</span>
                        </button>
                    </div>
                </nav>
            </div>
        </>
    );
}
