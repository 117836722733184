import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importujemy do nawigacji
import Fingerprint2 from 'fingerprintjs2';
import Api from '../lib/Api';
import { useAuthAdmin } from '../components/admin_auth';
import ToastHelper from '../../helpers/toast_helper';

function AdminLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const auth = useAuthAdmin();

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            setError('Wszystkie pola są wymagane.');
            return;
        }

        const components = await Fingerprint2.getPromise();
        const values = components.map(component => component.value);
        const browserFingerprint = Fingerprint2.x64hash128(values.join(''), 31);

        try {
            const response = await Api.LoginAdmin(email, password, browserFingerprint);
            if (response.error === false) {
                ToastHelper.showSuccessToast('Zalogowano pomyślnie!');
                auth.login(response.admin_id, response.access_token, response.email);
                navigate(`/admin`);
            } else {
                setError(response.message || 'Wystąpił błąd podczas logowania.');
            }
        } catch (error) {
            setError('Wystąpił błąd podczas łączenia z serwerem. Błąd: ' + error);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-900 to-gray-800 text-white">
            <div className="max-w-md w-full bg-gray-800 p-6 rounded-md shadow-lg">
                <h2 className="text-2xl font-semibold mb-4 text-center">Panel Administratora</h2>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <form onSubmit={handleLogin}>
                    <div className="mb-4">
                        <label className="block mb-2 text-gray-300">Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring focus:ring-green-500"
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block mb-2 text-gray-300">Hasło:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring focus:ring-green-500"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full p-3 bg-green-600 hover:bg-green-700 text-white rounded"
                    >
                        Zaloguj
                    </button>
                </form>
            </div>
        </div>
    );
}

export default AdminLogin;
