// src/components/AdminNavbar.js
import { NavLink, useNavigate } from 'react-router-dom';
import { FiChevronDown, FiSettings, FiLogOut, FiHelpCircle, FiMenu, FiX } from 'react-icons/fi';
import logo from '../../../img/menuscan_logo3bg.png';
import { useAuthAdmin } from '../../components/admin_auth';
import { useState, useEffect, useRef } from 'react';

export default function AdminNavbar() {
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [adminEmail] = useState(() => localStorage.getItem('admin_email'));
    const navigate = useNavigate();
    const auth = useAuthAdmin();
    const dropdownRef = useRef(null);

    const toggleUserDropdown = () => {
        setIsUserDropdownOpen((prev) => !prev);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen((prev) => !prev);
    };

    const handleLogout = () => {
        auth.logout();
        console.log('Wylogowano');
        navigate('/admin/login'); // Navigate after logout
    };

    // Handle clicks outside the dropdown to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsUserDropdownOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <header className="w-full flex justify-between items-center px-6 bg-gray-800 text-white fixed top-0 left-0 z-50 shadow-md h-16">
                <div className="flex items-center space-x-4">
                    <NavLink to="/admin">
                        <img src={logo} alt="Logo" className="h-8 md:h-12" />
                    </NavLink>
                    <nav className="hidden md:flex items-center space-x-6 text-lg font-medium">
                        <NavLink
                            to="/admin"
                            end
                            className={({ isActive }) =>
                                `select-none hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                            }
                        >
                            Strona główna
                        </NavLink>
                        <NavLink
                            to="/admin/users"
                            className={({ isActive }) =>
                                `select-none hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                            }
                        >
                            Użytkownicy
                        </NavLink>
                        <NavLink
                            to="/admin/support"
                            className={({ isActive }) =>
                                `select-none hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                            }
                        >
                            Pomoc techniczna
                        </NavLink>
                        <NavLink
                            to="/admin/settings"
                            className={({ isActive }) =>
                                `select-none hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                            }
                        >
                            Ustawienia
                        </NavLink>
                    </nav>
                </div>
                <div className="flex items-center space-x-4">
                    {/* Mobile Menu Button */}
                    <button
                        className="md:hidden text-white focus:outline-none"
                        onClick={toggleMobileMenu}
                        aria-label="Menu"
                    >
                        <div className="relative w-6 h-6">
                            <FiMenu
                                size={24}
                                className={`absolute inset-0 transform transition-all duration-500 ${isMobileMenuOpen ? 'opacity-0 rotate-90' : 'opacity-100 rotate-0'}`}
                            />
                            <FiX
                                size={24}
                                className={`absolute inset-0 transform transition-all duration-500 ${isMobileMenuOpen ? 'opacity-100 rotate-0' : 'opacity-0 rotate-90'}`}
                            />
                        </div>
                    </button>
                    {/* User Dropdown */}
                    <div className="relative hidden md:flex" ref={dropdownRef}>
                        <button
                            onClick={toggleUserDropdown}
                            className="flex items-center space-x-2 text-lg focus:outline-none hover:text-gray-400"
                            aria-haspopup="true"
                            aria-expanded={isUserDropdownOpen}
                        >
                            <span>{adminEmail}</span>
                            <FiChevronDown
                                className={`transition-transform duration-300 ${isUserDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                            />
                        </button>
                        <ul
                            className={`absolute right-0 top-full mt-1 bg-gray-700 text-white shadow-lg rounded-md py-2 w-48 z-60 transition ease-out duration-200 transform origin-top-right ${isUserDropdownOpen
                                ? 'opacity-100 scale-100 pointer-events-auto'
                                : 'opacity-0 scale-95 pointer-events-none'
                                }`}
                        >
                            <li
                                className="px-4 py-2 hover:bg-gray-600 cursor-pointer flex items-center space-x-2"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent event propagation
                                    navigate('/admin/settings');
                                    setIsUserDropdownOpen(false);
                                }}
                            >
                                <FiSettings />
                                <span>Ustawienia</span>
                            </li>
                            <li
                                className="px-4 py-2 hover:bg-gray-600 cursor-pointer flex items-center space-x-2"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate('/admin/support');
                                    setIsUserDropdownOpen(false);
                                }}
                            >
                                <FiHelpCircle />
                                <span>Pomoc techniczna</span>
                            </li>
                            <li
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleLogout();
                                    setIsUserDropdownOpen(false);
                                }}
                                className="px-4 py-2 hover:bg-gray-600 cursor-pointer flex items-center space-x-2"
                            >
                                <FiLogOut />
                                <span>Wyloguj</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            {/* Mobile Menu */}
            <div
                className={`md:hidden bg-gray-800 text-white px-6 overflow-hidden fixed top-16 left-0 right-0 z-40 transition-max-height duration-500 ease-in-out ${isMobileMenuOpen ? 'max-h-screen' : 'max-h-0'
                    }`}
            >
                <nav className="flex flex-col space-y-4 text-lg font-medium py-4">
                    <NavLink
                        to="/admin"
                        className={({ isActive }) =>
                            `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                        }
                        onClick={toggleMobileMenu}
                    >
                        Strona główna
                    </NavLink>
                    <NavLink
                        to="/admin/users"
                        className={({ isActive }) =>
                            `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                        }
                        onClick={toggleMobileMenu}
                    >
                        Użytkownicy
                    </NavLink>
                    <NavLink
                        to="/admin/support"
                        className={({ isActive }) =>
                            `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                        }
                        onClick={toggleMobileMenu}
                    >
                        Pomoc techniczna
                    </NavLink>
                    <div className="border-t border-gray-700 mt-4 pt-4">
                        <button
                            onClick={handleLogout}
                            className="flex items-center space-x-2 hover:text-gray-300 w-full text-left"
                        >
                            <FiLogOut />
                            <span>Wyloguj</span>
                        </button>
                        <button
                            onClick={() => {
                                navigate('/admin/support');
                                toggleMobileMenu();
                            }}
                            className="flex items-center space-x-2 hover:text-gray-300 w-full text-left mt-2"
                        >
                            <FiHelpCircle />
                            <span>Pomoc techniczna</span>
                        </button>
                    </div>
                </nav>
            </div>
        </>
    );
}
