import React, { useEffect, useState } from 'react';

function AdminHome() {
    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            setCurrentTime(now.toLocaleTimeString());
        };

        const timer = setInterval(updateTime, 1000);
        updateTime(); // Initialize immediately

        return () => clearInterval(timer); // Clean up on unmount
    }, []);

    return (
        <div className='min-h-screen flex flex-col items-center justify-top pt-20 bg-gradient-to-r from-gray-900 to-gray-800 text-white p-6'>
            <h1 className='text-4xl font-bold mb-4 text-center'>Panel Administracyjny</h1>
            <div className='w-max'>
                <p className='text-xl'>{currentTime}</p>
            </div>
        </div>
    );
};

export default AdminHome;
