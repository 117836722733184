export default class Api {
    static api_url = 'https://menuscan.pl/api/v1';
    static x_api_key = 'test';

    // Metoda pomocnicza do ustawiania nagłówków
    static getHeaders(includeAuth = true) {
        const headers = {
            'Content-Type': 'application/json',
            'x-api-key': this.x_api_key,
        };
        if (includeAuth) {
            headers['access_token'] = localStorage.getItem('access_token');
        }
        return headers;
    }

    static async RegisterUser(email, password, phone, restaurant_name, street, city, postal_code, country) {
        const userData = {
            email,
            password,
            phone,
            restaurant_name,
            street,
            city,
            postal_code,
            country
        };

        try {
            const response = await fetch(`${this.api_url}/register`, {
                method: 'POST',
                headers: this.getHeaders(false),
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd rejestracji.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Wystąpił problem z rejestracją:', error);
            throw error;
        }
    }


    static async CheckDatabase(id) {
        try {
            const response = await fetch(`${this.api_url}/${id}/restaurant/verify`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd w CheckDatabase.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd w CheckDatabase:', error);
            throw error;
        }
    }

    static async LoginUser(email, password, browserFingerprint) {
        const loginData = { email, password, browserFingerprint };

        try {
            const response = await fetch(`${this.api_url}/login`, {
                method: 'POST',
                headers: this.getHeaders(false),
                body: JSON.stringify(loginData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd logowania.');
            }

            const data = await response.json();

            if (!data.error) {
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('user_id', data.user_id);
                localStorage.setItem('restaurant_id', data.restaurant_id);
            }

            return data;
        } catch (error) {
            console.error('Wystąpił problem z logowaniem:', error);
            throw error;
        }
    }

    static async AddTable(id, table_number) {
        const tableData = { table_number };

        try {
            const response = await fetch(`${this.api_url}/${id}/tables`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(tableData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas dodawania stolika.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd podczas dodawania stolika:', error);
            throw error;
        }
    }

    static async AddCategory(id, category_name, order) {
        const categoryData = { name: category_name, display_order: order };

        try {
            const response = await fetch(`${this.api_url}/${id}/category`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(categoryData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas dodawania kategorii.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd podczas dodawania kategorii:', error);
            throw error;
        }
    }

    static async GetCategories(id) {
        try {
            const response = await fetch(`${this.api_url}/${id}/category`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd pobierania kategorii.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.categories;
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem kategorii:', error);
            throw error;
        }
    }

    static async DeleteUser() {
        try {
            const response = await fetch(`${this.api_url}/user`, {
                method: 'DELETE',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas usuwania konta użytkownika.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Wystąpił problem z usuwaniem konta użytkownika:', error);
            throw error;
        }
    }

    static async GetCategoriesWithProducts(id) {
        try {
            const response = await fetch(`${this.api_url}/${id}`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd pobierania kategorii z produktami.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.categories;
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem kategorii z produktami:', error);
            throw error;
        }
    }

    /**
     * Tworzenie nowego zamówienia
     * @param {string} restaurantId - ID restauracji
     * @param {number} tableId - ID stolika
     * @returns {Object} Odpowiedź serwera z order_id
     * @throws {Error} Jeśli wystąpi błąd podczas tworzenia zamówienia
     */
    static async CreateOrder(restaurantId, tableId) {
        const orderData = { table_id: tableId };

        try {
            const response = await fetch(`${this.api_url}/${restaurantId}/order`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(orderData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas tworzenia zamówienia.');
            }

            const data = await response.json();
            return data; // { error, message, order_id }
        } catch (error) {
            console.error('Wystąpił problem z tworzeniem zamówienia:', error);
            throw error;
        }
    }

    /**
     * Dodawanie produktu do zamówienia
     * @param {string} restaurantId - ID restauracji
     * @param {number} orderId - ID zamówienia
     * @param {number} productId - ID produktu
     * @param {number} quantity - Ilość
     * @returns {Object} Odpowiedź serwera
     * @throws {Error} Jeśli wystąpi błąd podczas dodawania produktu do zamówienia
     */
    static async AddProductToOrder(restaurantId, orderId, productId, quantity) {
        const productOrderData = { product_id: productId, quantity };

        try {
            const response = await fetch(`${this.api_url}/${restaurantId}/order/${orderId}/product`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(productOrderData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas dodawania produktu do zamówienia.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd podczas dodawania produktu do zamówienia:', error);
            throw error;
        }
    }

    /**
     * Pobieranie szczegółów zamówienia
     * @param {string} restaurantId - ID restauracji
     * @param {number} orderId - ID zamówienia
     * @returns {Object} Dane zamówienia z produktami
     * @throws {Error} Jeśli wystąpi błąd podczas pobierania danych zamówienia
     */
    static async GetOrderDetails(restaurantId, orderId) {
        try {
            const response = await fetch(`${this.api_url}/${restaurantId}/order/${orderId}`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd pobierania szczegółów zamówienia.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.order; // { id, products: [ ... ] }
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem szczegółów zamówienia:', error);
            throw error;
        }
    }

    static async GetStatistics(id) {
        try {
            const response = await fetch(`${this.api_url}/${id}/statistics`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd pobierania statystyk.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.statistics; // Zwraca obiekt ze statystykami
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem statystyk:', error);
            throw error;
        }
    }

    static async AddProduct(id, name, description, price, image, category_id) {
        const productData = { name, description, price, image, category_id };

        try {
            const response = await fetch(`${this.api_url}/${id}/product`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(productData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas dodawania produktu.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd podczas dodawania produktu:', error);
            throw error;
        }
    }

    // Funkcja do pobierania produktów
    static async GetProducts(id) {
        try {
            const response = await fetch(`${this.api_url}/${id}/products`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd pobierania produktów.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.products; // Zwróć listę produktów
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem produktów:', error);
            throw error;
        }
    }

    static async GetTables(id) {
        try {
            const response = await fetch(`${this.api_url}/${id}/tables`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd pobierania stolików.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.tables; // Zwróć listę stolików
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem stolików:', error);
            throw error;
        }
    }

    /**
     * Modyfikacja kategorii
     * @param {string} id - ID restauracji
     * @param {number} categoryId - ID kategorii
     * @param {string} name - Nowa nazwa kategorii
     * @param {number} display_order - Nowy porządek wyświetlania
     */
    static async UpdateCategory(id, categoryId, name, display_order) {
        const categoryData = { name, display_order };

        try {
            const response = await fetch(`${this.api_url}/${id}/category/${categoryId}`, {
                method: 'PUT',
                headers: this.getHeaders(),
                body: JSON.stringify(categoryData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas aktualizacji kategorii.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd podczas aktualizacji kategorii:', error);
            throw error;
        }
    }

    /**
     * Usuwanie kategorii
     * @param {string} id - ID restauracji
     * @param {number} categoryId - ID kategorii
     */
    static async DeleteCategory(id, categoryId) {
        try {
            const response = await fetch(`${this.api_url}/${id}/category/${categoryId}`, {
                method: 'DELETE',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas usuwania kategorii.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd podczas usuwania kategorii:', error);
            throw error;
        }
    }

    /**
     * Aktualizacja danych użytkownika
     * @param {Object} userData - Obiekt z danymi do aktualizacji (email, password, phone, restaurant_name)
     * @returns {Object} Odpowiedź serwera
     * @throws {Error} Jeśli wystąpi błąd podczas aktualizacji danych
     */
    static async UpdateUser(userData) {
        try {
            const response = await fetch(`${this.api_url}/user`, {
                method: 'PUT',
                headers: this.getHeaders(),
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas aktualizacji danych użytkownika.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Wystąpił problem z aktualizacją danych użytkownika:', error);
            throw error;
        }
    }

    /**
     * Pobieranie danych użytkownika oraz powiązanych informacji o restauracji na podstawie access_token
     * @returns {Object} Dane użytkownika i restauracji
     * @throws {Error} Jeśli wystąpi błąd podczas pobierania danych
     */
    static async GetUser() {
        try {
            const response = await fetch(`${this.api_url}/user`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd pobierania danych użytkownika.');
            }

            const data = await response.json();

            if (!data.error) {
                return {
                    user: data.user,
                    restaurant: data.restaurant
                };
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem danych użytkownika:', error);
            throw error;
        }
    }


    /**
     * Modyfikacja produktu
     * @param {string} id - ID restauracji
     * @param {number} productId - ID produktu
     * @param {string} name - Nowa nazwa produktu
     * @param {string} description - Nowy opis
     * @param {number} price - Nowa cena
     * @param {string} image - Nowy obrazek
     * @param {number} category_id - Nowe ID kategorii
     */
    static async UpdateProduct(id, productId, name, description, price, image, category_id) {
        const productData = { name, description, price, image, category_id };

        try {
            const response = await fetch(`${this.api_url}/${id}/product/${productId}`, {
                method: 'PUT',
                headers: this.getHeaders(),
                body: JSON.stringify(productData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas aktualizacji produktu.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd podczas aktualizacji produktu:', error);
            throw error;
        }
    }

    /**
     * Usuwanie produktu
     * @param {string} id - ID restauracji
     * @param {number} productId - ID produktu
     */
    static async DeleteProduct(id, productId) {
        try {
            const response = await fetch(`${this.api_url}/${id}/product/${productId}`, {
                method: 'DELETE',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas usuwania produktu.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd podczas usuwania produktu:', error);
            throw error;
        }
    }

    /**
     * Zakończenie zamówienia
     * @param {string} restaurantId - ID restauracji
     * @param {number} orderId - ID zamówienia
     * @returns {Object} Odpowiedź serwera
     * @throws {Error} Jeśli wystąpi błąd podczas zakończenia zamówienia
     */
    static async CompleteOrder(restaurantId, orderId) {
        try {
            const response = await fetch(`${this.api_url}/${restaurantId}/order/${orderId}/complete`, {
                method: 'PUT',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas zakończenia zamówienia.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd podczas zakończenia zamówienia:', error);
            throw error;
        }
    }

    /**
     * Pobieranie aktywnego zamówienia dla danego stolika
     * @param {string} restaurantId - ID restauracji
     * @param {number} tableId - ID stolika
     * @returns {Object} Dane zamówienia
     * @throws {Error} Jeśli wystąpi błąd podczas pobierania danych
     */
    // Api.js

    static async GetActiveOrderForTable(restaurantId, tableId) {
        try {
            const response = await fetch(`${this.api_url}/${restaurantId}/order/active?table_id=${tableId}`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd pobierania aktywnego zamówienia.');
            }

            const data = await response.json();

            return data; // Zwracamy cały obiekt: { "error": false, "order": ... } lub { "error": true, "message": ... }

        } catch (error) {
            console.error('Wystąpił problem z pobieraniem aktywnego zamówienia:', error);
            throw error;
        }
    }

    /**
     * Pobieranie szczegółów zamówienia dla konkretnego stolika w restauracji
     * @param {string|number} restaurantId - ID restauracji
     * @param {string|number} tableId - ID stolika w restauracji
     * @returns {Object|null} Szczegóły zamówienia lub null, jeśli zamówienie nie istnieje
     * @throws {Error} Jeśli wystąpi błąd podczas pobierania zamówienia
     */
    static async getTableOrder(restaurantId, tableId) {
        try {
            const response = await fetch(`${this.api_url}/${restaurantId}/tables/${tableId}/order`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas pobierania zamówienia.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.order; // Zwraca szczegóły zamówienia lub null
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Błąd podczas pobierania zamówienia:', error);
            throw error;
        }
    }

    /**
     * Anulowanie zamówienia
     * @param {string} restaurantId - ID restauracji
     * @param {number} orderId - ID zamówienia
     * @returns {Object} Odpowiedź serwera
     * @throws {Error} Jeśli wystąpi błąd podczas anulowania zamówienia
     */
    static async CancelOrder(restaurantId, orderId) {
        try {
            const response = await fetch(`${this.api_url}/${restaurantId}/order/${orderId}/cancel`, {
                method: 'PUT',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas anulowania zamówienia.');
            }

            const data = await response.json();
            return data; // { "error": false, "message": "Zamówienie zostało anulowane." } lub błąd
        } catch (error) {
            console.error('Błąd podczas anulowania zamówienia:', error);
            throw error;
        }
    }

    /**
     * Pobieranie wszystkich złożonych zamówień dla restauracji z opcjonalnym filtrowaniem
     * @param {string} id - ID restauracji
     * @param {Object} filters - Opcjonalne filtry { table_number: string }
     * @returns {Array} Lista zamówień
     * @throws {Error} Jeśli wystąpi błąd podczas pobierania zamówień
     */
    static async GetExistingOrders(id, filters = {}) {
        try {
            // Budowanie URL z parametrami zapytania
            const url = new URL(`${this.api_url}/${id}/orders/existing`);
            Object.keys(filters).forEach(key => {
                if (filters[key]) {
                    url.searchParams.append(key, filters[key]);
                }
            });

            const response = await fetch(url.toString(), {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd pobierania zamówień.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.orders; // Zwraca listę zamówień
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem zamówień:', error);
            throw error;
        }
    }

    /**
 * Pobieranie wszystkich zamówień dla restauracji z opcjonalnym filtrowaniem
 * @param {string} id - ID restauracji
 * @param {Object} filters - Opcjonalne filtry { table_number: string, order_date: string }
 * @returns {Array} Lista zamówień
 * @throws {Error} Jeśli wystąpi błąd podczas pobierania zamówień
 */
static async GetOrders(id, filters = {}) {
    try {
        // Budowanie URL z parametrami zapytania
        const url = new URL(`${this.api_url}/${id}/orders`);
        Object.keys(filters).forEach(key => {
            if (filters[key]) {
                url.searchParams.append(key, filters[key]);
            }
        });

        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: this.getHeaders(),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Błąd pobierania zamówień.');
        }

        const data = await response.json();

        if (!data.error) {
            return data.orders; // Zwraca listę zamówień
        }

        throw new Error(data.message || 'Błąd: Brak danych.');
    } catch (error) {
        console.error('Wystąpił problem z pobieraniem zamówień:', error);
        throw error;
    }
}



    // ---------------------- User Support Functions ----------------------

    /**
     * Tworzenie nowego zgłoszenia wsparcia
     * @param {string} title - Tytuł zgłoszenia
     * @param {string} description - Opis zgłoszenia
     * @returns {Object} Odpowiedź serwera z support_id
     * @throws {Error} Jeśli wystąpi błąd podczas tworzenia zgłoszenia
     */
    static async createSupportTicket(title, description) {
        const supportData = { title, description };

        try {
            const response = await fetch(`${this.api_url}/support`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(supportData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas tworzenia zgłoszenia wsparcia.');
            }

            const data = await response.json();
            return data; // { error, message, support_id }
        } catch (error) {
            console.error('Wystąpił problem z tworzeniem zgłoszenia wsparcia:', error);
            throw error;
        }
    }

    /**
 * Wysyłanie wiadomości do zgłoszenia wsparcia
 * @param {number} supportId - ID zgłoszenia
 * @param {string} message - Treść wiadomości
 * @returns {Object} Odpowiedź serwera
 * @throws {Error} Jeśli wystąpi błąd podczas wysyłania wiadomości
 */
    static async sendSupportMessage(supportId, message) {
        const messageData = { message };

        try {
            const response = await fetch(`${this.api_url}/support/${supportId}/messages`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(messageData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                // Specjalna obsługa błędu gdy użytkownik próbuje wysłać kolejną wiadomość
                if (response.status === 400 && errorData.message.includes('Nie możesz wysłać kolejnej wiadomości')) {
                    throw new Error('Nie możesz wysłać kolejnej wiadomości, zanim support nie odpowie.');
                }
                throw new Error(errorData.message || 'Błąd podczas wysyłania wiadomości do zgłoszenia.');
            }

            const data = await response.json();
            return data; // { error, message }
        } catch (error) {
            console.error('Błąd podczas wysyłania wiadomości do zgłoszenia:', error);
            throw error;
        }
    }


    static async closeSupportTicket(supportId) {
        try {
            const response = await fetch(`${this.api_url}/support/${supportId}`, {
                method: 'DELETE',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas wysyłania wiadomości do zgłoszenia.');
            }

            const data = await response.json();
            return data; // { error, message }
        } catch (error) {
            console.error('Błąd podczas wysyłania wiadomości do zgłoszenia:', error);
            throw error;
        }
    }

    /**
 * Edycja ostatniej wiadomości w zgłoszeniu wsparcia
 * @param {number} supportId - ID zgłoszenia
 * @param {number} messageId - ID wiadomości do edycji
 * @param {string} newMessage - Nowa treść wiadomości
 * @returns {Object} Odpowiedź serwera
 * @throws {Error} Jeśli wystąpi błąd podczas edycji wiadomości
 */
    static async editSupportMessage(supportId, messageId, newMessage) {
        const editData = { message: newMessage };

        try {
            const response = await fetch(`${this.api_url}/support/${supportId}/messages/${messageId}`, {
                method: 'PUT',
                headers: this.getHeaders(),
                body: JSON.stringify(editData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas edycji wiadomości wsparcia.');
            }

            const data = await response.json();
            return data; // { error, message }
        } catch (error) {
            console.error('Błąd podczas edycji wiadomości wsparcia:', error);
            throw error;
        }
    }


    /**
     * Pobieranie wszystkich zgłoszeń wsparcia dla użytkownika
     * @returns {Array} Lista zgłoszeń wsparcia
     * @throws {Error} Jeśli wystąpi błąd podczas pobierania zgłoszeń
     */
    static async getSupportTickets() {
        try {
            const response = await fetch(`${this.api_url}/support`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas pobierania zgłoszeń wsparcia.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.support_tickets; // Zwraca listę zgłoszeń
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem zgłoszeń wsparcia:', error);
            throw error;
        }
    }

    /**
     * Pobieranie szczegółów konkretnego zgłoszenia wsparcia wraz z wiadomościami
     * @param {number} supportId - ID zgłoszenia
     * @returns {Object} Szczegóły zgłoszenia i lista wiadomości
     * @throws {Error} Jeśli wystąpi błąd podczas pobierania danych zgłoszenia
     */
    static async getSupportTicket(supportId) {
        try {
            const response = await fetch(`${this.api_url}/support/${supportId}`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas pobierania zgłoszenia wsparcia.');
            }

            const data = await response.json();

            if (!data.error) {
                return {
                    support_ticket: data.support_ticket,
                    messages: data.messages
                };
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem zgłoszenia wsparcia:', error);
            throw error;
        }
    }

}
