// src/lib/Api.js
export default class Api {
    static api_url = 'http://localhost/api/v1';
    static x_api_key = 'test';

    // Metoda pomocnicza do ustawiania nagłówków
    static getHeaders(includeAuth = true) {
        const headers = {
            'Content-Type': 'application/json',
            'x-api-key': this.x_api_key,
        };
        if (includeAuth) {
            headers['access_token'] = localStorage.getItem('admin_access_token');
        }
        return headers;
    }

    /**
     * Logowanie administratora
     * @param {string} email - Email administratora
     * @param {string} password - Hasło administratora
     * @param {string} browserFingerprint - Unikalny identyfikator przeglądarki
     * @returns {Object} Odpowiedź serwera zawierająca token dostępu
     * @throws {Error} Jeśli wystąpi błąd podczas logowania
     */
    static async LoginAdmin(email, password, browserFingerprint) {
        const loginData = { email, password, browserFingerprint };

        try {
            const response = await fetch(`${this.api_url}/login`, {
                method: 'POST',
                headers: this.getHeaders(false),
                body: JSON.stringify(loginData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd logowania.');
            }

            const data = await response.json();

            if (!data.error) {
                localStorage.setItem('admin_access_token', data.access_token);
            }

            return data;
        } catch (error) {
            console.error('Wystąpił problem z logowaniem:', error);
            throw error;
        }
    }

    /**
     * Pobieranie wszystkich zgłoszeń wsparcia
     * @returns {Array} Lista wszystkich zgłoszeń wsparcia
     * @throws {Error} Jeśli wystąpi błąd podczas pobierania zgłoszeń
     */
    static async getAllSupportTickets() {
        try {
            const response = await fetch(`${this.api_url}/support`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd pobierania zgłoszeń wsparcia.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.support_tickets; // Zwraca listę zgłoszeń
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem zgłoszeń wsparcia:', error);
            throw error;
        }
    }

    /**
     * Pobieranie szczegółów konkretnego zgłoszenia wsparcia wraz z wiadomościami
     * @param {number} supportId - ID zgłoszenia
     * @returns {Object} Szczegóły zgłoszenia i lista wiadomości
     * @throws {Error} Jeśli wystąpi błąd podczas pobierania zgłoszenia
     */
    static async getSupportTicket(supportId) {
        try {
            const response = await fetch(`${this.api_url}/support/${supportId}`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd pobierania zgłoszenia wsparcia.');
            }

            const data = await response.json();

            if (!data.error) {
                return {
                    support_ticket: data.support_ticket,
                    messages: data.messages,
                };
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem zgłoszenia wsparcia:', error);
            throw error;
        }
    }

    /**
     * Aktualizacja statusu zgłoszenia wsparcia
     * @param {number} supportId - ID zgłoszenia
     * @param {string} status - Nowy status zgłoszenia (np. "Open", "In Progress", "Closed")
     * @returns {Object} Odpowiedź serwera
     * @throws {Error} Jeśli wystąpi błąd podczas aktualizacji statusu
     */
    static async updateSupportStatus(supportId, status) {
        const statusData = { status };

        try {
            const response = await fetch(`${this.api_url}/support/${supportId}/status`, {
                method: 'PUT',
                headers: this.getHeaders(),
                body: JSON.stringify(statusData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas aktualizacji statusu zgłoszenia.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd podczas aktualizacji statusu zgłoszenia:', error);
            throw error;
        }
    }

    /**
     * Usuwanie zgłoszenia wsparcia
     * @param {number} supportId - ID zgłoszenia
     * @returns {Object} Odpowiedź serwera
     * @throws {Error} Jeśli wystąpi błąd podczas usuwania zgłoszenia
     */
    static async deleteSupportTicket(supportId) {
        try {
            const response = await fetch(`${this.api_url}/support/${supportId}`, {
                method: 'DELETE',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas usuwania zgłoszenia wsparcia.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd podczas usuwania zgłoszenia wsparcia:', error);
            throw error;
        }
    }

    /**
     * Dodawanie wiadomości do zgłoszenia wsparcia jako administrator
     * @param {number} supportId - ID zgłoszenia
     * @param {string} message - Treść wiadomości
     * @returns {Object} Odpowiedź serwera
     * @throws {Error} Jeśli wystąpi błąd podczas dodawania wiadomości
     */
    static async addSupportMessage(supportId, message) {
        const messageData = { message, sender_type: 'admin' };

        try {
            const response = await fetch(`${this.api_url}/support/${supportId}/messages`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(messageData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas dodawania wiadomości do zgłoszenia.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd podczas dodawania wiadomości do zgłoszenia:', error);
            throw error;
        }
    }

    /**
     * Pobieranie statystyk zgłoszeń wsparcia
     * @returns {Object} Statystyki zgłoszeń
     * @throws {Error} Jeśli wystąpi błąd podczas pobierania statystyk
     */
    static async getSupportStatistics() {
        try {
            const response = await fetch(`${this.api_url}/support/statistics`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd pobierania statystyk zgłoszeń.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.statistics; // Zwraca obiekt ze statystykami
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem statystyk zgłoszeń:', error);
            throw error;
        }
    }

    // ---------------------- Nowe Funkcje dla Administratorów ----------------------

    /**
     * Pobieranie profilu administratora
     * @returns {Object} Dane profilu administratora
     * @throws {Error} Jeśli wystąpi błąd podczas pobierania profilu
     */
    static async getAdminProfile() {
        try {
            const response = await fetch(`${this.api_url}/profile`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas pobierania profilu administratora.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.admin;
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem profilu administratora:', error);
            throw error;
        }
    }

    /**
     * Aktualizacja profilu administratora
     * @param {Object} data - Dane do aktualizacji (email, password)
     * @returns {Object} Odpowiedź serwera
     * @throws {Error} Jeśli wystąpi błąd podczas aktualizacji profilu
     */
    static async updateAdminProfile(data) {
        try {
            const response = await fetch(`${this.api_url}/profile`, {
                method: 'PUT',
                headers: this.getHeaders(),
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas aktualizacji profilu administratora.');
            }

            const result = await response.json();

            return result;
        } catch (error) {
            console.error('Błąd podczas aktualizacji profilu administratora:', error);
            throw error;
        }
    }

    /**
     * Tworzenie nowego administratora
     * @param {string} email - Email nowego administratora
     * @param {string} password - Hasło nowego administratora
     * @returns {Object} Odpowiedź serwera
     * @throws {Error} Jeśli wystąpi błąd podczas tworzenia administratora
     */
    static async createAdmin(email, password) {
        const createData = { email, password };

        try {
            const response = await fetch(`${this.api_url}/create`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(createData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas tworzenia administratora.');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.error('Błąd podczas tworzenia administratora:', error);
            throw error;
        }
    }

    /**
     * Pobieranie listy wszystkich administratorów
     * @returns {Array} Lista administratorów
     * @throws {Error} Jeśli wystąpi błąd podczas pobierania listy
     */
    static async getAllAdmins() {
        try {
            const response = await fetch(`${this.api_url}/list`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas pobierania listy administratorów.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.admins;
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Błąd podczas pobierania listy administratorów:', error);
            throw error;
        }
    }

    /**
     * Aktualizacja danych konkretnego administratora
     * @param {number} adminId - ID administratora
     * @param {Object} data - Dane do aktualizacji (email, password)
     * @returns {Object} Odpowiedź serwera
     * @throws {Error} Jeśli wystąpi błąd podczas aktualizacji
     */
    static async updateAdmin(adminId, data) {
        try {
            const response = await fetch(`${this.api_url}/update/${adminId}`, {
                method: 'PUT',
                headers: this.getHeaders(),
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas aktualizacji administratora.');
            }

            const result = await response.json();

            return result;
        } catch (error) {
            console.error('Błąd podczas aktualizacji administratora:', error);
            throw error;
        }
    }

    /**
     * Pobieranie listy wszystkich użytkowników
     * @returns {Array} Lista użytkowników
     * @throws {Error} Jeśli wystąpi błąd podczas pobierania listy
     */
    static async getAllUsers() {
        try {
            const response = await fetch(`${this.api_url}/users`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas pobierania listy użytkowników.');
            }

            const data = await response.json();

            if (!data.error) {
                return data.users;
            }

            throw new Error(data.message || 'Błąd: Brak danych.');
        } catch (error) {
            console.error('Błąd podczas pobierania listy użytkowników:', error);
            throw error;
        }
    }

    /**
 * Fetch specific user data along with restaurant data
 * @param {number} userId - User ID
 * @returns {Object} User and restaurant data
 * @throws {Error} If an error occurs while fetching user data
 */
static async getUser(userId) {
    try {
        const response = await fetch(`${this.api_url}/users/${userId}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error fetching user data.');
        }

        const data = await response.json();

        if (!data.error) {
            return {
                user: data.user,
                restaurant: data.restaurant,
            };
        }

        throw new Error(data.message || 'Error: No data.');
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}


    /**
 * Update user and restaurant data
 * @param {number} userId - User ID
 * @param {Object} data - Data to update (email, password, phone, restaurant_name, street, city, postal_code, country)
 * @returns {Object} Server response
 * @throws {Error} If an error occurs during the update
 */
static async updateUser(userId, data) {
    try {
        const response = await fetch(`${this.api_url}/users/${userId}`, {
            method: 'PUT',
            headers: this.getHeaders(),
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error updating user.');
        }

        const result = await response.json();

        return result;
    } catch (error) {
        console.error('Error updating user:', error);
        throw error;
    }
}


    /**
     * Usuwanie użytkownika
     * @param {number} userId - ID użytkownika do usunięcia
     * @returns {Object} Odpowiedź serwera
     * @throws {Error} Jeśli wystąpi błąd podczas usuwania użytkownika
     */
    static async deleteUser(userId) {
        try {
            const response = await fetch(`${this.api_url}/users/${userId}`, {
                method: 'DELETE',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas usuwania użytkownika.');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.error('Błąd podczas usuwania użytkownika:', error);
            throw error;
        }
    }

    /**
     * Usuwanie administratora
     * @param {number} adminId - ID administratora do usunięcia
     * @returns {Object} Odpowiedź serwera
     * @throws {Error} Jeśli wystąpi błąd podczas usuwania administratora
     */
    static async deleteAdmin(adminId) {
        try {
            const response = await fetch(`${this.api_url}/delete/${adminId}`, {
                method: 'DELETE',
                headers: this.getHeaders(),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas usuwania administratora.');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.error('Błąd podczas usuwania administratora:', error);
            throw error;
        }
    }
}
