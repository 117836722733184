import { useState, useContext, createContext, useEffect } from "react";

const AdminAuthContext = createContext();

export const AdminAuthProvider = ({ children }) => {
    const [admin_access_token, setAdminAccessToken] = useState(() => localStorage.getItem('admin_access_token'));
    const [admin_id, setAdminId] = useState(() => localStorage.getItem('admin_id'));
    const [email, setEmail] = useState(() => localStorage.getItem('admin_email'));

    const login = (admin_id, admin_access_token, email) => {
        console.log("Logging in:", admin_id, admin_access_token);
        setAdminId(admin_id);
        setEmail(email);
        setAdminAccessToken(admin_access_token);
        localStorage.setItem('admin_access_token', admin_access_token);
        localStorage.setItem('admin_id', admin_id);
        localStorage.setItem('admin_email', email);
    };

    const logout = () => {
        console.log("admin id: " + admin_id);
        console.log("admin access token: " + admin_access_token);
        console.log("Logging out admin");
        setAdminId(null);
        setAdminAccessToken(null);
        setEmail(null);
        localStorage.removeItem('admin_access_token');
        localStorage.removeItem('admin_id');
        localStorage.removeItem('admin_email');
    };

    useEffect(() => {
        const storedToken = localStorage.getItem('admin_access_token');
        const storedAdmin = localStorage.getItem('admin_id');
        const storedEmail = localStorage.getItem('admin_email');

        console.log("Stored admin Token:", storedToken);
        console.log("Stored admin Email:", storedEmail);
        console.log("Stored Admin id:", storedAdmin);

        if (storedToken && storedAdmin && storedEmail) {
            setAdminAccessToken(storedToken);
            setAdminId(storedAdmin);
            setEmail(storedEmail);
        }
    }, []);

    return (
        <AdminAuthContext.Provider value={{ admin_access_token, admin_id, email, login, logout }}>
            {children}
        </AdminAuthContext.Provider>
    );
}

export const useAuthAdmin = () => {
    return useContext(AdminAuthContext);
};
