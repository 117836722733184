import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../lib/Api'; // Upewnij się, że ścieżka jest poprawna
import ToastHelper from '../helpers/toast_helper';

export default function Menu() {
    const { restaurantId, tableId } = useParams(); // Pobierz restaurant_id i table_id z URL
    const [categories, setCategories] = useState([]);
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [categoriesError, setCategoriesError] = useState('');

    const [order, setOrder] = useState([]);
    const [orderTotal, setOrderTotal] = useState(0);
    const [orderId, setOrderId] = useState(null); // Stan dla orderId
    const [orderStatus, setOrderStatus] = useState(null); // Stan dla statusu zamówienia

    const [cart, setCart] = useState([]); // Stan dla koszyka

    const [tableError, setTableError] = useState(''); // Nowy stan dla błędu stolika

    // Funkcja do pobierania kategorii wraz z produktami
    const fetchCategoriesWithProducts = useCallback(async () => {
        if (!restaurantId) return;

        setCategoriesLoading(true);
        setCategoriesError('');

        try {
            const fetchedCategories = await Api.GetCategoriesWithProducts(restaurantId);
            setCategories(fetchedCategories);
        } catch (err) {
            setCategoriesError(err.message || 'Wystąpił błąd podczas pobierania kategorii z produktami.');
        } finally {
            setCategoriesLoading(false);
        }
    }, [restaurantId]);

    // Funkcja do anulowania zamówienia
    const handleCancelOrder = async () => {
        if (!orderId) {
            ToastHelper.showErrorToast('Brak aktywnego zamówienia do anulowania.');
            return;
        }

        try {
            const data = await Api.CancelOrder(restaurantId, orderId);
            if (data.error) {
                throw new Error(data.message || 'Błąd podczas anulowania zamówienia.');
            }

            // Resetowanie stanu zamówienia
            setOrderId(null);
            setOrderStatus(null);
            setOrder([]);
            setCart([]);
            ToastHelper.showSuccessToast('Zamówienie zostało anulowane.');
        } catch (err) {
            ToastHelper.showErrorToast(err.message || 'Wystąpił błąd podczas anulowania zamówienia.');
        }
    };

    // Funkcja do pobierania aktywnego zamówienia dla stolika
    const fetchActiveOrder = useCallback(async () => {
        if (!restaurantId || !tableId) return;
        if (tableId === 'test') return;
        try {
            const data = await Api.GetActiveOrderForTable(restaurantId, tableId);

            if (data.error) {
                if (data.message === 'Stolik nie istnieje') {
                    throw new Error('Stolik, który próbujesz otworzyć, nie istnieje.');
                } else {
                    throw new Error(data.message || 'Błąd pobierania aktywnego zamówienia.');
                }
            }

            if (data.order) {
                setOrderId(data.order.id);
                setOrderStatus(data.order.status);
                setOrder(data.order.products.map(product => ({
                    id: product.product_id,
                    name: product.product_name,
                    description: product.product_description,
                    price: product.price,
                    image: product.image,
                    quantity: product.quantity,
                })));
            } else {
                // Brak aktywnego zamówienia, pokaż menu i umożliw dodawanie do koszyka
                setOrderId(null);
                setOrderStatus(null);
                setOrder([]);
                setCart([]); // Opcjonalnie, czyścimy koszyk gdy brak zamówienia
            }
        } catch (err) {
            if (err.message === 'Stolik, który próbujesz otworzyć, nie istnieje.') {
                setTableError(err.message);
            } else {
                setCategoriesError(err.message || 'Wystąpił błąd podczas zarządzania zamówieniem.');
            }
        }
    }, [restaurantId, tableId]);

    useEffect(() => {
        if (restaurantId && tableId) {
            fetchActiveOrder().then(() => {
                if (!tableError) {
                    fetchCategoriesWithProducts();
                }
            });
        }
    }, [restaurantId, tableId, fetchCategoriesWithProducts, fetchActiveOrder, tableError]);

    // Funkcja do dodawania produktu do koszyka
    const handleAddToCart = (product) => {
        setCart(prevCart => {
            const existingProduct = prevCart.find(item => item.id === product.id);
            if (existingProduct) {
                // Jeśli produkt już jest w koszyku, zwiększ ilość
                return prevCart.map(item =>
                    item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
                );
            } else {
                // Jeśli produktu jeszcze nie ma w koszyku, dodaj go z ilością 1
                return [...prevCart, { ...product, quantity: 1 }];
            }
        });
    };

    // Funkcja do usuwania produktu z koszyka
    const handleRemoveFromCart = (productId) => {
        setCart(prevCart => prevCart.filter(item => item.id !== productId));
    };

    // Funkcja do zmiany ilości produktu w koszyku
    const handleChangeQuantityInCart = (productId, quantity) => {
        if (quantity < 1) return;
        setCart(prevCart =>
            prevCart.map(item =>
                item.id === productId ? { ...item, quantity } : item
            )
        );
    };

    // Obliczanie całkowitej wartości koszyka
    const calculateCartTotal = () => {
        return cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
    };

    // Funkcja do składania zamówienia (zakończenie)
    const handlePlaceOrder = async () => {
        if (tableId === 'test') {
            ToastHelper.showErrorToast('Koszyk jest testowy, nie można złożyć zamówienia!');
            return;
        }
        if (cart.length === 0) {
            ToastHelper.showErrorToast('Koszyk jest pusty.');
            return;
        }

        try {
            // Step 1: Tworzenie nowego zamówienia
            const newOrder = await Api.CreateOrder(restaurantId, tableId);
            if (newOrder.error) {
                throw new Error(newOrder.message || 'Błąd podczas tworzenia zamówienia.');
            }
            const newOrderId = newOrder.order_id;
            setOrderId(newOrderId);
            setOrderStatus('Złożone');

            // Step 2: Dodawanie wszystkich produktów z koszyka do zamówienia
            for (const product of cart) {
                await Api.AddProductToOrder(restaurantId, newOrderId, product.id, product.quantity);
            }

            // Step 3: Aktualizacja stanu zamówienia i koszyka
            setOrder(cart);
            setCart([]);
            ToastHelper.showSuccessToast('Zamówienie zostało złożone pomyślnie!');
        } catch (err) {
            ToastHelper.showErrorToast(err.message || 'Wystąpił błąd podczas składania zamówienia.');
        }
    };

    // Obliczanie całkowitej wartości zamówienia
    useEffect(() => {
        const total = order.reduce((acc, item) => acc + item.price * item.quantity, 0);
        setOrderTotal(total);
    }, [order]);

    // Renderowanie menu lub aktywnego zamówienia w zależności od obecności orderId i błędów stolika
    return (
        <div className="min-h-screen bg-gray-900 text-white p-4 sm:p-6">
            <div className="container mx-auto">
                {/* Wyświetlanie błędu, jeśli stolik nie istnieje */}
                {tableError && (
                    <div className="mb-4 p-4 bg-red-600 text-white rounded">
                        <p>{tableError}</p>
                    </div>
                )}

                {/* Renderowanie Koszyka i Menu, jeśli nie ma aktywnego zamówienia i brak błędu stolika */}
                {!orderId && !tableError && (
                    <>
                        {/* Sekcja Menu */}
                        <div>
                            {categoriesLoading && <p className="text-gray-400">Ładowanie menu...</p>}
                            {categoriesError && <p className="text-red-500 mb-2">{categoriesError}</p>}
                            {!categoriesLoading && !categoriesError && categories.length === 0 && (
                                <p className="text-gray-400">Brak kategorii do wyświetlenia.</p>
                            )}
                            {!categoriesLoading && !categoriesError && categories.length > 0 && (
                                <div className="space-y-8">
                                    {categories.map(category => (
                                        <div key={category.id} className="bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg">
                                            <h2 className="text-xl sm:text-2xl font-semibold text-gray-200 mb-4">{category.name}</h2>
                                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6">
                                                {category.products.length > 0 ? (
                                                    category.products.map(product => (
                                                        <div key={product.id} className="bg-gray-700 p-3 sm:p-4 rounded-lg shadow hover:bg-gray-600 transition duration-300 flex flex-col">
                                                            <img
                                                                src={product.image}
                                                                alt={product.name}
                                                                className="w-full h-32 sm:h-40 object-cover rounded-md mb-3"
                                                            />
                                                            <h3 className="text-lg sm:text-xl font-bold text-gray-200">{product.name}</h3>
                                                            <p className="text-gray-400 mt-1 sm:mt-2 flex-grow">{product.description}</p>
                                                            <p className="text-green-400 font-semibold mt-2">Cena: {product.price.toFixed(2)} PLN</p>
                                                            <button
                                                                className="mt-3 sm:mt-4 px-3 sm:px-4 py-2 sm:py-2.5 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-200 text-sm sm:text-base"
                                                                onClick={() => handleAddToCart(product)}
                                                            >
                                                                Dodaj do koszyka
                                                            </button>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p className="text-gray-400">Brak produktów w tej kategorii.</p>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Sekcja Koszyka */}
                        {cart.length > 0 && (
                            <div className="mt-8 sm:mt-12 bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg">
                                <h2 className="text-xl sm:text-2xl font-semibold text-gray-200 mb-4">Twój Koszyk</h2>
                                <div className="space-y-4">
                                    {cart.map(item => (
                                        <div key={item.id} className="flex flex-col sm:flex-row items-start sm:items-center justify-between bg-gray-700 p-3 sm:p-4 rounded-lg">
                                            <div className="flex-1">
                                                <h3 className="text-lg sm:text-xl font-bold text-gray-200">{item.name}</h3>
                                                <p className="text-gray-400">{item.description}</p>
                                                <p className="text-green-400 font-semibold">Cena: {item.price.toFixed(2)} PLN</p>
                                            </div>
                                            <div className="flex items-center space-x-2 mt-2 sm:mt-0">
                                                <input
                                                    type="number"
                                                    value={item.quantity}
                                                    onChange={(e) => handleChangeQuantityInCart(item.id, parseInt(e.target.value, 10))}
                                                    min="1"
                                                    className="w-16 px-2 py-1 text-center bg-gray-600 text-white rounded-md"
                                                />
                                                <button
                                                    className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors duration-200 text-sm sm:text-base"
                                                    onClick={() => handleRemoveFromCart(item.id)}
                                                >
                                                    Usuń
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="flex flex-col sm:flex-row justify-between items-center mt-4">
                                        <span className="text-lg sm:text-xl font-bold text-gray-200">Razem:</span>
                                        <span className="text-lg sm:text-xl font-bold text-green-400">{calculateCartTotal().toFixed(2)} PLN</span>
                                    </div>
                                    <button
                                        className="w-full mt-4 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200 text-base sm:text-lg"
                                        onClick={handlePlaceOrder}
                                    >
                                        Złóż Zamówienie
                                    </button>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {/* Renderowanie Aktywnego Zamówienia, jeśli istnieje */}
                {orderId && (
                    <div className="mt-8 sm:mt-12 bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl sm:text-2xl font-semibold text-gray-200 mb-4">Twoje Aktywne Zamówienie</h2>
                        <p className="text-gray-400 mb-2">Status Zamówienia: {orderStatus}</p>
                        {order.length === 0 ? (
                            <p className="text-gray-400">Brak produktów w zamówieniu.</p>
                        ) : (
                            <div className="space-y-4">
                                {order.map(item => (
                                    <div key={item.id} className="flex flex-col sm:flex-row items-start sm:items-center justify-between bg-gray-700 p-3 sm:p-4 rounded-lg">
                                        <div className="flex-1">
                                            <h3 className="text-lg sm:text-xl font-bold text-gray-200">{item.name}</h3>
                                            <p className="text-gray-400">{item.description}</p>
                                            <p className="text-green-400 font-semibold">Cena: {item.price.toFixed(2)} PLN</p>
                                        </div>
                                    </div>
                                ))}
                                <div className="flex flex-col sm:flex-row justify-between items-center mt-4">
                                    <span className="text-lg sm:text-xl font-bold text-gray-200">Razem:</span>
                                    <span className="text-lg sm:text-xl font-bold text-green-400">{orderTotal.toFixed(2)} PLN</span>
                                </div>
                                <div className="flex justify-center mt-4">
                                    {/* Przycisk Anulowania Zamówienia */}
                                    <button
                                        className="w-full sm:w-auto px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-200 text-base sm:text-lg"
                                        onClick={handleCancelOrder}
                                        disabled={orderStatus === 'Wydane' || orderStatus === 'Anulowane'}
                                    >
                                        Anuluj Zamówienie
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}

            </div>
        </div>
    );
}
