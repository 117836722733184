import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../lib/Api';
import ToastHelper from '../helpers/toast_helper';
import { Transition } from '@headlessui/react';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [restaurantName, setRestaurantName] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('Polska');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [error, setError] = useState('');
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const maxSteps = 6;

  const handleRegisterRedirect = () => {
    navigate('/login');
  };

  const handleNext = () => {
    if (step === 1 && (!firstName || !lastName)) {
      setError('Proszę podać imię i nazwisko.');
      return;
    }
    if (step === 2 && !phone) {
      setError('Proszę podać numer telefonu.');
      return;
    }
    if (step === 3 && !email) {
      setError('Proszę podać adres e-mail.');
      return;
    }
    if (step === 4) {
      if (!password || !confirmPassword) {
        setError('Proszę podać i potwierdzić hasło.');
        return;
      }
      if (password !== confirmPassword) {
        setError('Hasła nie są zgodne.');
        return;
      }
    }
    if (step === 5 && !restaurantName) {
      setError('Proszę podać nazwę restauracji.');
      return;
    }
    if (step === 6 && (!street || !city || !postalCode || !country)) {
      setError('Proszę podać pełny adres restauracji.');
      return;
    }

    setError('');
    setStep(prev => Math.min(prev + 1, maxSteps));
  };

  const handlePrevious = () => {
    setError('');
    setStep(prev => Math.max(prev - 1, 1));
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!email || !password || !confirmPassword || !phone || !restaurantName || !street || !city || !postalCode || !country) {
      setError('Wszystkie pola są wymagane.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Hasła muszą się zgadzać.');
      return;
    }

    try {
      const response = await Api.RegisterUser(
        email,
        password,
        phone,
        restaurantName,
        street,
        city,
        postalCode,
        country
      );

      if (response.error === false && response.message === null) {
        ToastHelper.showSuccessToast('Rejestracja zakończona sukcesem!');
        navigate('/login');
      } else {
        setError(response.message || 'Wystąpił błąd podczas rejestracji.');
      }
    } catch (error) {
      setError('Nie udało się połączyć z serwerem.');
    }
  };

  const stepTitles = [
    'Informacje osobiste',
    'Telefon',
    'E-mail',
    'Hasło',
    'Nazwa restauracji',
    'Adres restauracji',
  ];

  const renderStepContent = (currentStep) => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <div className="mb-4">
              <label className="block mb-2 text-gray-300">Imię:</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Jan"
              />
            </div>
            <div className="mb-6">
              <label className="block mb-2 text-gray-300">Nazwisko:</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Kowalski"
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <div className="mb-6">
              <label className="block mb-2 text-gray-300">Numer telefonu:</label>
              <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="+48123456789"
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div className="mb-6">
              <label className="block mb-2 text-gray-300">Adres e-mail:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="przyklad@przyklad.pl"
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <div className="mb-4">
              <label className="block mb-2 text-gray-300">Hasło:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="••••••••"
              />
            </div>
            <div className="mb-6">
              <label className="block mb-2 text-gray-300">Potwierdź hasło:</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="••••••••"
              />
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <div className="mb-6">
              <label className="block mb-2 text-gray-300">Nazwa restauracji:</label>
              <input
                type="text"
                value={restaurantName}
                onChange={(e) => setRestaurantName(e.target.value)}
                required
                className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Wspaniała Restauracja"
              />
            </div>
          </div>
        );
      case 6:
        return (
          <div>
            <div className="mb-4">
              <label className="block mb-2 text-gray-300">Ulica:</label>
              <input
                type="text"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                required
                className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="ul. Główna 123"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-gray-300">Miasto:</label>
              <input
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
                className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Warszawa"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-gray-300">Kod pocztowy:</label>
              <input
                type="text"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                required
                className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="00-001"
              />
            </div>
            <div className="mb-6">
              <label className="block mb-2 text-gray-300">Kraj:</label>
              <input
                type="text"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
                readOnly
                className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Polska"
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-900 to-gray-800 text-white p-4">
      <div className="max-w-xl w-full bg-gray-800 p-8 rounded-md shadow-lg">
        <div className="flex flex-col items-center mb-4">
          {/* Wiersz z kulkami i liniami */}
          <div className="flex items-center w-full pl-4">
            {stepTitles.map((title, index) => (
              <div key={index} className="flex flex-1 items-center">
                <div className="flex items-center w-full">
                  <div
                    className={`flex items-center justify-center w-10 h-10 rounded-full ${step > index + 1
                      ? 'bg-green-500'
                      : step === index + 1
                        ? 'bg-green-600'
                        : 'bg-gray-600'
                      }`}
                  >
                    {step > index + 1 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={3}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : (
                      <span className="text-lg font-semibold">{index + 1}</span>
                    )}
                  </div>
                  {index !== stepTitles.length - 1 && (
                    <div
                      className={`flex-1 h-1 ${step > index + 1 ? 'bg-green-500' : 'bg-gray-600'
                        } mx-2`}
                    ></div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Wiersz z tytułami kroków */}
          {/* <div className="flex items-center w-full mt-2">
            {stepTitles.map((title, index) => (
              <div key={index} className="flex-1 flex justify-center  text-xs px-2">
                {title}
              </div>
            ))}
          </div> */}
        </div>

        <h2 className="text-3xl font-semibold mb-6 text-center">Zarejestruj się</h2>
        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}

        <form onSubmit={handleRegister}>
          <Transition
            key={step}
            show={true}
            appear={true}
            enter="transition ease-in-out duration-500"
            enterFrom="transform translate-x-10 opacity-0"
            enterTo="transform translate-x-0 opacity-100"
            leave="transition ease-in-out duration-500"
            leaveFrom="transform translate-x-0 opacity-100"
            leaveTo="transform -translate-x-10 opacity-0"
          >
            <div className="space-y-6">
              {renderStepContent(step)}
            </div>
          </Transition>

          <div className="flex justify-between mt-8">
            {step > 1 && (
              <button
                type="button"
                onClick={handlePrevious}
                className="px-6 py-2 bg-gray-700 hover:bg-gray-600 rounded-lg transition duration-300"
              >
                Poprzedni
              </button>
            )}
            {step < maxSteps && (
              <button
                type="button"
                onClick={handleNext}
                className="ml-auto px-6 py-2 bg-green-600 hover:bg-green-500 rounded-lg transition duration-300"
              >
                Następny
              </button>
            )}
            {step === maxSteps && (
              <button
                type="submit"
                className="ml-auto px-6 py-2 bg-green-600 hover:bg-green-500 rounded-lg transition duration-300"
              >
                Zarejestruj się
              </button>
            )}
          </div>
        </form>
        <p className="mt-4">
          Posiadasz konto?{' '}
          <span
            className="font-bold text-green-400 cursor-pointer hover:underline"
            onClick={handleRegisterRedirect}
          >
            Zaloguj się
          </span>
        </p>
      </div>
    </div>
  );
}

export default Register;
