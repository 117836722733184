// src/pages/Support.js
import React, { useState, useEffect, useRef } from 'react'; // Import useRef
import { FiHelpCircle, FiChevronDown, FiPlus, FiEdit } from 'react-icons/fi'; // Dodano FiEdit
import { Transition } from '@headlessui/react';
import Api from '../lib/Api';
import ToastHelper from '../helpers/toast_helper';
import { FaUserCircle, FaRobot } from 'react-icons/fa'; // For avatar icons

function Support() {
    const [supportTickets, setSupportTickets] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [isTicketsOpen, setIsTicketsOpen] = useState(true);
    const [isAddTicketOpen, setIsAddTicketOpen] = useState(false);
    const [newTicketTitle, setNewTicketTitle] = useState('');
    const [newTicketDescription, setNewTicketDescription] = useState('');
    const [isAddingTicket, setIsAddingTicket] = useState(false);
    const [addTicketError, setAddTicketError] = useState(null);
    const [isClosedTicket, setIsClosedTicket] = useState(false);

    // New states for messages
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [messageError, setMessageError] = useState(null);

    // New states for closing ticket
    const [isClosingTicket, setIsClosingTicket] = useState(false);
    const [closeTicketError, setCloseTicketError] = useState(null);

    // New states for editing messages
    const [isEditing, setIsEditing] = useState(false);
    const [editMessage, setEditMessage] = useState('');
    const [editError, setEditError] = useState(null);
    const [isEditingMessageId, setIsEditingMessageId] = useState(null);

    // Reference to the last message for auto-scrolling
    const lastMessageRef = useRef(null);

    // Toggle functions for tickets section
    const toggleTickets = () => {
        setIsTicketsOpen(!isTicketsOpen);
    };

    // Fetch support tickets
    const fetchSupportTickets = async () => {
        try {
            const response = await Api.getSupportTickets();
            if (response && Array.isArray(response)) {
                setSupportTickets(response);
            } else {
                console.error('Invalid API response:', response);
                setSupportTickets([]);
            }
        } catch (error) {
            console.error('Error fetching tickets:', error);
            setSupportTickets([]);
        }
    };

    const handleCloseTicket = async (ticket) => {
        if (ticket.status === 'Closed') {
            ToastHelper.showInfoToast('Ticket jest już zamknięty.');
            return;
        }

        // Optional: Potwierdzenie przed zamknięciem ticketu
        const confirmClose = window.confirm('Czy na pewno chcesz zamknąć ten ticket?');
        if (!confirmClose) return;

        setIsClosingTicket(true);
        setCloseTicketError(null);
        try {
            const response = await Api.closeSupportTicket(ticket.id);
            if (response && !response.error) {
                await fetchSupportTickets();
                ToastHelper.showSuccessToast('Ticket został zamknięty.');
                // Opcjonalnie zamknij modal
                closeTicketDetails();
            } else {
                setCloseTicketError(response.message || 'Nie udało się zamknąć ticketu.');
            }
        } catch (error) {
            console.error('Error closing ticket:', error);
            setCloseTicketError('Wystąpił błąd podczas zamykania ticketu.');
        } finally {
            setIsClosingTicket(false);
        }
    };

    useEffect(() => {
        fetchSupportTickets();
    }, []);

    // Open and close ticket details
    const openTicketDetails = async (ticket) => {
        try {
            const response = await Api.getSupportTicket(ticket.id);
            if (response && !response.error) {
                setSelectedTicket(response.support_ticket);
                setIsClosedTicket(response.support_ticket.status === 'Closed');
                setMessages(response.messages);
            } else {
                ToastHelper.showErrorToast(response.message || 'Nie udało się pobrać szczegółów ticketu.');
            }
        } catch (error) {
            console.error('Error fetching ticket details:', error);
            ToastHelper.showErrorToast('Wystąpił błąd podczas pobierania szczegółów ticketu.');
        }
    };

    const closeTicketDetails = () => {
        setSelectedTicket(null);
        setMessages([]);
        setNewMessage('');
        setMessageError(null);
        setCloseTicketError(null);
        setIsEditing(false);
        setEditMessage('');
        setIsEditingMessageId(null);
        setEditError(null);
    };

    // Open and close add ticket modal
    const openAddTicketModal = () => {
        setNewTicketTitle('');
        setNewTicketDescription('');
        setAddTicketError(null);
        setIsAddTicketOpen(true);
    };

    const closeAddTicketModal = () => {
        setIsAddTicketOpen(false);
    };

    // Handle adding a new ticket
    const handleAddTicket = async (e) => {
        e.preventDefault();
        if (!newTicketTitle.trim() || !newTicketDescription.trim()) {
            setAddTicketError('Tytuł i opis nie mogą być puste.');
            return;
        }

        setIsAddingTicket(true);
        setAddTicketError(null);
        try {
            const response = await Api.createSupportTicket(newTicketTitle, newTicketDescription);
            if (response && !response.error) {
                await fetchSupportTickets();
                closeAddTicketModal();
                ToastHelper.showSuccessToast('Nowy ticket został dodany!');
            } else {
                setAddTicketError(response.message || 'Nie udało się dodać ticketu.');
            }
        } catch (error) {
            console.error('Error adding ticket:', error);
            setAddTicketError('Wystąpił błąd podczas dodawania ticketu.');
        } finally {
            setIsAddingTicket(false);
        }
    };

    // Handle sending a message
    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim()) {
            setMessageError('Wiadomość nie może być pusta.');
            return;
        }

        setIsSendingMessage(true);
        setMessageError(null);
        try {
            const response = await Api.sendSupportMessage(selectedTicket.id, newMessage);
            if (response && !response.error) {
                // Refresh messages after sending
                const updatedTicket = await Api.getSupportTicket(selectedTicket.id);
                if (updatedTicket && !updatedTicket.error) {
                    setMessages(updatedTicket.messages);
                    ToastHelper.showSuccessToast('Wiadomość została wysłana.');
                    setNewMessage('');
                } else {
                    ToastHelper.showErrorToast(updatedTicket.message || 'Nie udało się odświeżyć ticketu.');
                }
            } else {
                setMessageError(response.message || 'Nie udało się wysłać wiadomości.');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessageError('Wystąpił błąd podczas wysyłania wiadomości.');
        } finally {
            setIsSendingMessage(false);
        }
    };

    // Handle editing a message
    const handleEditMessage = async (messageId, currentMessage) => {
        setIsEditing(true);
        setEditMessage(currentMessage);
        setIsEditingMessageId(messageId);
        setEditError(null);
    };

    // Handle saving the edited message
    const handleSaveEditMessage = async (e) => {
        e.preventDefault();
        if (!editMessage.trim()) {
            setEditError('Wiadomość nie może być pusta.');
            return;
        }

        setIsSendingMessage(true);
        setEditError(null);
        try {
            const response = await Api.editLastSupportMessage(selectedTicket.id, editMessage);
            if (response && !response.error) {
                // Refresh messages after editing
                const updatedTicket = await Api.getSupportTicket(selectedTicket.id);
                if (updatedTicket && !updatedTicket.error) {
                    setMessages(updatedTicket.messages);
                    ToastHelper.showSuccessToast('Wiadomość została zaktualizowana.');
                    setIsEditing(false);
                    setEditMessage('');
                    setIsEditingMessageId(null);
                } else {
                    ToastHelper.showErrorToast(updatedTicket.message || 'Nie udało się odświeżyć ticketu.');
                }
            } else {
                setEditError(response.message || 'Nie udało się zaktualizować wiadomości.');
            }
        } catch (error) {
            console.error('Error editing message:', error);
            setEditError('Wystąpił błąd podczas edytowania wiadomości.');
        } finally {
            setIsSendingMessage(false);
        }
    };

    // Handle canceling the edit
    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditMessage('');
        setIsEditingMessageId(null);
        setEditError(null);
    };

    // Effect to auto-scroll to the last message
    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, selectedTicket, isEditing]);

    // Effect to lock/unlock body scroll when modal is open/closed
    useEffect(() => {
        if (selectedTicket || isAddTicketOpen) {
            // Lock scroll
            document.body.style.overflow = 'hidden';
        } else {
            // Unlock scroll
            document.body.style.overflow = '';
        }

        // Cleanup in case component unmounts while modal is open
        return () => {
            document.body.style.overflow = '';
        };
    }, [selectedTicket, isAddTicketOpen]);

    // Function to check if a message is the last user message
    const isLastUserMessage = (message) => {
        if (messages.length === 0) return false;
        const lastMessage = messages[messages.length - 1];
        return lastMessage === message && lastMessage.sender_type === 'user';
    };

    const disableSendingMessage = () => {
        return messages.at(-1).sender_type === 'user' ? true : false;
    }

    return (
        <div className="min-h-screen bg-gray-900">
            {/* Main Content */}
            <div className="flex flex-col justify-center items-center mt-16 pt-4 px-4 space-y-4">
                {/* Support Tickets Section */}
                <div className="w-full max-w-3xl bg-gray-700 rounded-lg text-white shadow-lg">
                    <div className="flex items-stretch justify-between bg-gray-800 hover:bg-gray-700 transition-colors duration-200">
                        <button
                            className="flex-grow flex items-center justify-between px-4 py-4 focus:outline-none"
                            onClick={toggleTickets}
                        >
                            <div className="flex items-center space-x-2">
                                <FiHelpCircle className="text-2xl" />
                                <h2 className="text-xl font-bold">Support Tickets</h2>
                            </div>
                            <FiChevronDown
                                className={`transition-transform ${isTicketsOpen ? 'rotate-180' : ''}`}
                            />
                        </button>
                        <button
                            className="p-2 focus:outline-none"
                            onClick={openAddTicketModal}
                            title="Add Ticket"
                        >
                            <FiPlus className="text-xl transition-colors duration-200 hover:text-green-500" />
                        </button>
                    </div>
                    <Transition
                        show={isTicketsOpen}
                        enter="transition duration-300 ease-out"
                        enterFrom="transform scale-y-0 opacity-0"
                        enterTo="transform scale-y-100 opacity-100"
                        leave="transition duration-200 ease-in"
                        leaveFrom="transform scale-y-100 opacity-100"
                        leaveTo="transform scale-y-0 opacity-0"
                        className="origin-top"
                    >
                        <div className="p-4 max-h-80 overflow-y-auto">
                            {supportTickets.length > 0 ? (
                                <ul className="space-y-2">
                                    {supportTickets.map((ticket) => (
                                        <li
                                            key={ticket.id}
                                            onClick={() => openTicketDetails(ticket)}
                                            className="flex items-center justify-between p-3 bg-gray-800 rounded-md hover:bg-gray-600 cursor-pointer"
                                        >
                                            <span>{ticket.title}</span>
                                            <span
                                                className={`px-2 py-1 rounded-full text-xs ${ticket.status === 'Open'
                                                    ? 'bg-green-500 text-white'
                                                    : ticket.status === 'In Progress'
                                                        ? 'bg-yellow-500 text-white'
                                                        : 'bg-red-500 text-white'
                                                    }`}
                                            >
                                                {ticket.status}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-center">(Brak ticketów)</p>
                            )}
                        </div>
                    </Transition>
                </div>
            </div>

            {/* Add Ticket Modal */}
            {isAddTicketOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <Transition
                        show={isAddTicketOpen}
                        enter="transition duration-300 ease-out"
                        enterFrom="transform scale-75 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-200 ease-in"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-75 opacity-0"
                    >
                        <div className="bg-gray-800 text-gray-200 p-6 rounded-lg shadow-lg w-full max-w-md max-h-full overflow-y-auto">
                            <h2 className="text-2xl mb-4 font-semibold">Dodaj Nowy Ticket</h2>
                            <form onSubmit={handleAddTicket}>
                                <div className="mb-4">
                                    <label htmlFor="ticketTitle" className="block text-sm font-medium mb-1">
                                        Tytuł Ticketu
                                    </label>
                                    <input
                                        type="text"
                                        id="ticketTitle"
                                        value={newTicketTitle}
                                        onChange={(e) => setNewTicketTitle(e.target.value)}
                                        className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Wprowadź tytuł ticketu"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="ticketDescription" className="block text-sm font-medium mb-1">
                                        Opis Ticketu
                                    </label>
                                    <textarea
                                        id="ticketDescription"
                                        value={newTicketDescription}
                                        onChange={(e) => setNewTicketDescription(e.target.value)}
                                        className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Wprowadź opis ticketu"
                                        required
                                    />
                                </div>
                                {addTicketError && (
                                    <p className="text-red-500 text-sm mb-4">{addTicketError}</p>
                                )}
                                <div className="flex justify-end space-x-2">
                                    <button
                                        type="button"
                                        onClick={closeAddTicketModal}
                                        className="px-4 py-2 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-700 transition-colors duration-200 focus:outline-none"
                                    >
                                        Anuluj
                                    </button>
                                    <button
                                        type="submit"
                                        className={`px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center ${isAddingTicket ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                        disabled={isAddingTicket}
                                    >
                                        {isAddingTicket && (
                                            <svg
                                                className="animate-spin h-5 w-5 mr-2 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8v8H4z"
                                                ></path>
                                            </svg>
                                        )}
                                        {isAddingTicket ? 'Dodawanie...' : 'Dodaj'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Transition>
                </div>
            )}

            {/* Ticket Details Modal */}
            {selectedTicket && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <Transition
                        show={true}
                        enter="transition duration-300 ease-out"
                        enterFrom="transform scale-75 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-200 ease-in"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-75 opacity-0"
                    >
                        <div className="bg-gray-800 text-gray-200 p-6 rounded-lg shadow-lg w-full max-w-2xl max-h-full overflow-y-auto">
                            <h2 className="text-2xl mb-4 font-semibold">{selectedTicket.title}</h2>
                            <p className="mb-2">
                                <span className="font-medium">Status:</span> {selectedTicket.status}
                            </p>

                            {/* Messages Section */}
                            <div className="mb-4">
                                <h3 className="text-lg font-medium mb-2">Wiadomości</h3>
                                <div className="space-y-4 max-h-60 overflow-y-auto pr-2">
                                    {messages.length > 0 ? (
                                        messages.map((msg) => (
                                            <div
                                                key={msg.id}
                                                className={`flex ${msg.sender_type === 'admin' ? 'justify-start' : 'justify-end'}`}
                                            >
                                                {msg.sender_type === 'admin' && (
                                                    <FaRobot className="text-3xl mr-2 text-blue-500" />
                                                )}
                                                <div
                                                    className={`max-w-xs p-3 rounded-lg ${msg.sender_type === 'admin'
                                                        ? 'bg-blue-600 text-white'
                                                        : 'bg-green-600 text-white'
                                                        } relative`}
                                                >
                                                    {isEditing && isEditingMessageId === msg.id ? (
                                                        <form onSubmit={handleSaveEditMessage}>
                                                            <textarea
                                                                value={editMessage}
                                                                onChange={(e) => setEditMessage(e.target.value)}
                                                                className="w-full px-2 py-1 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                                required
                                                            />
                                                            {editError && (
                                                                <p className="text-red-500 text-sm">{editError}</p>
                                                            )}
                                                            <div className="flex justify-end space-x-2 mt-2">
                                                                <button
                                                                    type="button"
                                                                    onClick={handleCancelEdit}
                                                                    className="px-2 py-1 bg-gray-600 text-white rounded hover:bg-gray-700 focus:outline-none"
                                                                >
                                                                    Anuluj
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className={`px-2 py-1 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none flex items-center ${isSendingMessage ? 'opacity-50 cursor-not-allowed' : ''
                                                                        }`}
                                                                    disabled={isSendingMessage}
                                                                >
                                                                    {isSendingMessage ? (
                                                                        <svg
                                                                            className="animate-spin h-4 w-4 mr-1 text-white"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <circle
                                                                                className="opacity-25"
                                                                                cx="12"
                                                                                cy="12"
                                                                                r="10"
                                                                                stroke="currentColor"
                                                                                strokeWidth="4"
                                                                            ></circle>
                                                                            <path
                                                                                className="opacity-75"
                                                                                fill="currentColor"
                                                                                d="M4 12a8 8 0 018-8v8H4z"
                                                                            ></path>
                                                                        </svg>
                                                                    ) : (
                                                                        'Zapisz'
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    ) : (
                                                        <>
                                                            <p className="text-sm">{msg.message}</p>
                                                            <span className="text-xs mt-1 block text-right">
                                                                {new Date(msg.created_at).toLocaleString()}
                                                            </span>
                                                            {/* Dodanie przycisku edycji */}
                                                            {msg.sender_type === 'user' && isLastUserMessage(msg) && !isClosedTicket && (
                                                                <button
                                                                    onClick={() => handleEditMessage(msg.id, msg.message)}
                                                                    className="absolute top-0 right-0 mt-1 mr-1 text-xs text-blue-400 hover:text-blue-500 focus:outline-none"
                                                                    title="Edytuj wiadomość"
                                                                >
                                                                    <FiEdit className='text-black' />
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                {msg.sender_type === 'user' && (
                                                    <FaUserCircle className="text-3xl ml-2 text-green-500" />
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-sm text-gray-400">Brak wiadomości.</p>
                                    )}
                                    {/* Reference to the last message */}
                                    <div ref={lastMessageRef} />
                                </div>
                            </div>

                            {/* Add Message Form */}
                            {!isEditing && (
                                <div>
                                    <form onSubmit={handleSendMessage} className="mb-4">
                                        <div className="mb-2">
                                            <label htmlFor="newMessage" className="block text-sm font-medium mb-1">
                                                Dodaj Wiadomość
                                            </label>
                                            <textarea
                                                id="newMessage"
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                                className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                placeholder="Napisz swoją wiadomość..."
                                                required
                                                disabled={disableSendingMessage() || isClosedTicket}
                                            />
                                        </div>
                                        {messageError && (
                                            <p className="text-red-500 text-sm mb-2">{messageError}</p>
                                        )}
                                        <button
                                            type="submit"
                                            className={`px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200 flex items-center ${isSendingMessage || disableSendingMessage() || isClosedTicket ? 'opacity-50 cursor-not-allowed' : ''
                                                }`}
                                            disabled={isSendingMessage || disableSendingMessage() || isClosedTicket}
                                        >
                                            {isSendingMessage ? (
                                                <>
                                                    <svg
                                                        className="animate-spin h-5 w-5 mr-2 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        ></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8v8H4z"
                                                        ></path>
                                                    </svg>
                                                    Wysyłanie...
                                                </>
                                            ) : (
                                                'Wyślij'
                                            )}
                                        </button>
                                    </form>
                                    {/* Informacja dla użytkownika, że musi poczekać na odpowiedź */}
                                    {disableSendingMessage() && !isClosedTicket && (
                                        <p className="text-gray-400 text-sm">
                                            Oczekuj na odpowiedź przed wysłaniem kolejnej wiadomości.
                                        </p>
                                    )}
                                </div>
                            )}

                            {/* Add Message Form for Editing */}
                            {isEditing && (
                                <form onSubmit={handleSaveEditMessage} className="mb-4">
                                    <div className="mb-2">
                                        <label htmlFor="editMessage" className="block text-sm font-medium mb-1">
                                            Edytuj Wiadomość
                                        </label>
                                        <textarea
                                            id="editMessage"
                                            value={editMessage}
                                            onChange={(e) => setEditMessage(e.target.value)}
                                            className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            placeholder="Napisz swoją wiadomość..."
                                            required
                                        />
                                    </div>
                                    {editError && (
                                        <p className="text-red-500 text-sm mb-2">{editError}</p>
                                    )}
                                    <div className="flex justify-end space-x-2">
                                        <button
                                            type="button"
                                            onClick={handleCancelEdit}
                                            className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors duration-200 focus:outline-none"
                                        >
                                            Anuluj
                                        </button>
                                        <button
                                            type="submit"
                                            className={`px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center ${isSendingMessage ? 'opacity-50 cursor-not-allowed' : ''
                                                }`}
                                            disabled={isSendingMessage}
                                        >
                                            {isSendingMessage ? (
                                                <>
                                                    <svg
                                                        className="animate-spin h-5 w-5 mr-2 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        ></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8v8H4z"
                                                        ></path>
                                                    </svg>
                                                    Zapisz
                                                </>
                                            ) : (
                                                'Zapisz'
                                            )}
                                        </button>
                                    </div>
                                </form>
                            )}

                            {/* Error Message for Closing Ticket */}
                            {closeTicketError && (
                                <p className="text-red-500 text-sm mb-2">{closeTicketError}</p>
                            )}

                            {/* Action Buttons */}
                            <div className="flex justify-between">
                                <button
                                    onClick={closeTicketDetails}
                                    className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 focus:outline-none"
                                >
                                    Zamknij
                                </button>
                                {!isClosedTicket && (
                                    <button
                                        onClick={() => handleCloseTicket(selectedTicket)}
                                        className={`px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors duration-200 flex items-center ${isClosingTicket ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                        disabled={isClosingTicket || selectedTicket.status === 'Closed'}
                                    >
                                        {isClosingTicket ? (
                                            <>
                                                <svg
                                                    className="animate-spin h-5 w-5 mr-2 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8v8H4z"
                                                    ></path>
                                                </svg>
                                                Zamykam...
                                            </>
                                        ) : (
                                            'Zamknij Ticket'
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>
                    </Transition>
                </div>
            )}
        </div>
    );
}

export default Support;
