// src/components/QrCodeGenerator.js
import React, { useEffect, useRef, useState } from 'react';
import QRCodeStyling from 'qr-code-styling';

const QrCodeGenerator = ({ restaurantId, tableId }) => {
  const qrRef = useRef(null);
  const qrCodeInstance = useRef(null);

  const [qrColor, setQrColor] = useState('#000000'); // Default QR color
  const [backgroundColor, setBackgroundColor] = useState('#ffffff'); // Default background color
  const [label, setLabel] = useState(`Stolik ${tableId}`);
  const [labelPosition, setLabelPosition] = useState('bottom'); // 'top' or 'bottom'
  const [labelColor, setLabelColor] = useState('#000000'); // Label color

  // Function to initialize QR Code
  const initializeQRCode = () => {
    qrCodeInstance.current = new QRCodeStyling({
      width: 300,
      height: 300, // Height of QR Code
      type: 'canvas',
      data: `https://menuscan.pl/menu/${restaurantId}/${tableId}`,
      dotsOptions: {
        color: qrColor,
        type: 'rounded',
      },
      backgroundOptions: {
        color: backgroundColor,
      },
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 20,
      },
      // Label section removed from QR Code configuration
    });

    if (qrRef.current) {
      qrCodeInstance.current.append(qrRef.current);
    }
  };

  // Initialize QR Code only once
  useEffect(() => {
    initializeQRCode();

    return () => {
      if (qrCodeInstance.current) {
        qrCodeInstance.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array means the effect runs only once

  // Update QR Code when dependencies change
  useEffect(() => {
    if (qrCodeInstance.current) {
      const url = `https://app.menuscan.pl/menu/${restaurantId}/${tableId}`;
      qrCodeInstance.current.update({
        data: url,
        dotsOptions: {
          color: qrColor,
          type: 'rounded',
        },
        backgroundOptions: {
          color: backgroundColor,
        },
      });
    }
  }, [restaurantId, tableId, qrColor, backgroundColor]);

  // Helper function to load image
  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };

  // Function to download combined QR Code with label
  const downloadQRCode = async () => {
    if (qrRef.current) {
      try {
        // Get the canvas element from QR Code
        const canvas = qrRef.current.querySelector('canvas');
        if (!canvas) {
          console.error('Nie znaleziono elementu canvas.');
          return;
        }

        const qrDataUrl = canvas.toDataURL('image/png');

        const qrImage = await loadImage(qrDataUrl);

        const qrWidth = qrImage.width;
        const qrHeight = qrImage.height;
        const labelHeight = 50; // Label height, adjust as needed

        // Create a new canvas with appropriate dimensions
        const combinedCanvas = document.createElement('canvas');
        const ctx = combinedCanvas.getContext('2d');

        combinedCanvas.width = qrWidth;
        combinedCanvas.height = qrHeight + labelHeight;

        if (labelPosition === 'top') {
          // Draw label on top
          ctx.fillStyle = backgroundColor;
          ctx.fillRect(0, 0, combinedCanvas.width, labelHeight);
          ctx.fillStyle = labelColor;
          ctx.font = '20px Arial';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(label, combinedCanvas.width / 2, labelHeight / 2);

          // Draw QR Code below label
          ctx.drawImage(qrImage, 0, labelHeight, qrWidth, qrHeight);
        } else {
          // Draw QR Code on top
          ctx.drawImage(qrImage, 0, 0, qrWidth, qrHeight);

          // Draw label below QR Code
          ctx.fillStyle = backgroundColor;
          ctx.fillRect(0, qrHeight, combinedCanvas.width, labelHeight);
          ctx.fillStyle = labelColor;
          ctx.font = '20px Arial';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(label, combinedCanvas.width / 2, qrHeight + labelHeight / 2);
        }

        // Export canvas as PNG
        const combinedDataUrl = combinedCanvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = combinedDataUrl;
        link.download = `qr_table_${tableId}.png`;
        document.body.appendChild(link); // Add to DOM so click works in Firefox
        link.click();
        document.body.removeChild(link); // Remove from DOM
      } catch (error) {
        console.error('Błąd podczas generowania QR Code:', error);
      }
    }
  };

  return (
    <div className="mt-4 flex flex-col items-center">
      {labelPosition === 'top' && (
        <div
          style={{
            backgroundColor: backgroundColor,
            color: labelColor,
            textAlign: 'center',
            width: '300px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {label}
        </div>
      )}
      {/* QR Code */}
      <div ref={qrRef} className="flex justify-center"></div>
      {labelPosition === 'bottom' && (
        <div
          style={{
            backgroundColor: backgroundColor,
            color: labelColor,
            textAlign: 'center',
            width: '300px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {label}
        </div>
      )}

      {/* Personalization options */}
      <div className="mt-4 space-y-4 w-full max-w-md">
        <div>
          <label className="block text-sm font-medium mb-1">Kolor QR Code:</label>
          <input
            type="color"
            value={qrColor}
            onChange={(e) => setQrColor(e.target.value)}
            className="w-full h-10 p-0 border-none cursor-pointer"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Kolor tła:</label>
          <input
            type="color"
            value={backgroundColor}
            onChange={(e) => setBackgroundColor(e.target.value)}
            className="w-full h-10 p-0 border-none cursor-pointer"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Etykieta:</label>
          <input
            type="text"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            className="w-full px-2 py-1 border border-gray-300 rounded bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Wprowadź etykietę (np. Stolik 12)"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Pozycja etykiety:</label>
          <select
            value={labelPosition}
            onChange={(e) => setLabelPosition(e.target.value)}
            className="w-full px-2 py-1 border border-gray-300 rounded bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="top">Nad QR Code</option>
            <option value="bottom">Pod QR Code</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Kolor etykiety:</label>
          <input
            type="color"
            value={labelColor}
            onChange={(e) => setLabelColor(e.target.value)}
            className="w-full h-10 p-0 border-none cursor-pointer"
          />
        </div>
      </div>

      <button
        onClick={downloadQRCode}
        className="mt-4 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none"
      >
        Pobierz QR Code
      </button>
    </div>
  );
};

export default QrCodeGenerator;
