// src/pages/AdminUsers.js
import React, { useState, useEffect, useCallback } from 'react';
import { FiEdit, FiTrash2, FiX } from 'react-icons/fi';
import Api from '../lib/Api';
import ToastHelper from '../../helpers/toast_helper';

const AdminUsers = () => {
    // Stany dla listy użytkowników
    const [users, setUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(false);
    const [usersError, setUsersError] = useState('');

    // Stany dla edycji użytkowników
    const [editingUserId, setEditingUserId] = useState(null);
    const [editedUserData, setEditedUserData] = useState({});
    const [userFormLoading, setUserFormLoading] = useState(false);
    const [userFormError, setUserFormError] = useState('');
    const [userFormSuccess, setUserFormSuccess] = useState('');

    // Stany dla usuwania użytkownika
    const [deletingUserId, setDeletingUserId] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    // Stany dla wyświetlania danych użytkownika
    const [viewingUserId, setViewingUserId] = useState(null);
    const [viewingUserData, setViewingUserData] = useState({});

    // Pobieranie listy użytkowników
    const fetchUsers = useCallback(async () => {
        setUsersLoading(true);
        setUsersError('');
        try {
            const usersList = await Api.getAllUsers();
            setUsers(usersList);
        } catch (error) {
            console.error('Błąd podczas pobierania listy użytkowników:', error);
            setUsersError('Błąd podczas pobierania listy użytkowników.');
            ToastHelper.showErrorToast('Błąd podczas pobierania listy użytkowników.');
        } finally {
            setUsersLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    // Obsługa edycji użytkownika
    const handleEditUserClick = async (user) => {
        setEditingUserId(user.id);
        setUserFormError('');
        setUserFormSuccess('');
        try {
            const data = await Api.getUser(user.id);
            setEditedUserData({
                email: data.user.email,
                phone: data.user.phone,
                password: '',
                restaurant_name: data.restaurant ? data.restaurant.restaurant_name : '',
                street: data.restaurant ? data.restaurant.street : '',
                city: data.restaurant ? data.restaurant.city : '',
                postal_code: data.restaurant ? data.restaurant.postal_code : '',
                country: data.restaurant ? data.restaurant.country : '',
            });
        } catch (error) {
            console.error('Błąd podczas pobierania danych użytkownika:', error);
            setUserFormError('Błąd podczas pobierania danych użytkownika.');
            ToastHelper.showErrorToast('Błąd podczas pobierania danych użytkownika.');
        }
    };

    const handleCancelEditUser = () => {
        setEditingUserId(null);
        setEditedUserData({});
        setUserFormError('');
        setUserFormSuccess('');
    };

    const handleUserInputChange = (e) => {
        const { name, value } = e.target;
        setEditedUserData((prev) => ({ ...prev, [name]: value }));
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();
        setUserFormLoading(true);
        setUserFormError('');
        setUserFormSuccess('');

        try {
            const updateData = {};
            if (editedUserData.email) updateData.email = editedUserData.email;
            if (editedUserData.phone != null) updateData.phone = editedUserData.phone;
            if (editedUserData.password) updateData.password = editedUserData.password;
            if (editedUserData.restaurant_name != null) updateData.restaurant_name = editedUserData.restaurant_name;
            if (editedUserData.street != null) updateData.street = editedUserData.street;
            if (editedUserData.city != null) updateData.city = editedUserData.city;
            if (editedUserData.postal_code != null) updateData.postal_code = editedUserData.postal_code;
            if (editedUserData.country != null) updateData.country = editedUserData.country;

            if (Object.keys(updateData).length === 0) {
                setUserFormError('Brak danych do aktualizacji.');
                setUserFormLoading(false);
                return;
            }

            const response = await Api.updateUser(editingUserId, updateData);
            if (!response.error) {
                setUserFormSuccess('Użytkownik został zaktualizowany pomyślnie.');
                fetchUsers();
                handleCancelEditUser();
                ToastHelper.showSuccessToast('Użytkownik został zaktualizowany pomyślnie.');
            } else {
                setUserFormError(response.message || 'Błąd podczas aktualizacji użytkownika.');
                ToastHelper.showErrorToast(response.message || 'Błąd podczas aktualizacji użytkownika.');
            }
        } catch (error) {
            console.error('Błąd podczas aktualizacji użytkownika:', error);
            setUserFormError('Błąd podczas aktualizacji użytkownika.');
            ToastHelper.showErrorToast('Błąd podczas aktualizacji użytkownika.');
        } finally {
            setUserFormLoading(false);
        }
    };

    // Usuwanie użytkownika
    const handleDeleteUser = async (userId) => {
        const confirmDelete = window.confirm('Czy na pewno chcesz usunąć tego użytkownika?');
        if (!confirmDelete) return;

        setIsDeleting(true);
        setDeletingUserId(userId);

        try {
            const response = await Api.deleteUser(userId);
            if (!response.error) {
                ToastHelper.showSuccessToast('Użytkownik został usunięty pomyślnie.');
                fetchUsers();
            } else {
                ToastHelper.showErrorToast(response.message || 'Błąd podczas usuwania użytkownika.');
            }
        } catch (error) {
            console.error('Błąd podczas usuwania użytkownika:', error);
            ToastHelper.showErrorToast('Błąd podczas usuwania użytkownika.');
        } finally {
            setIsDeleting(false);
            setDeletingUserId(null);
        }
    };

    // Wyświetlanie danych użytkownika
    const handleViewUserClick = async (user) => {
        setViewingUserId(user.id);
        setUserFormError('');
        setUserFormSuccess('');
        try {
            const data = await Api.getUser(user.id);
            setViewingUserData({
                id: data.user.id,
                email: data.user.email,
                phone: data.user.phone,
                restaurant_name: data.restaurant ? data.restaurant.restaurant_name : '',
                street: data.restaurant ? data.restaurant.street : '',
                city: data.restaurant ? data.restaurant.city : '',
                postal_code: data.restaurant ? data.restaurant.postal_code : '',
                country: data.restaurant ? data.restaurant.country : '',
                created_at: data.user.created_at,
                // Dodaj inne potrzebne pola
            });
        } catch (error) {
            console.error('Błąd podczas pobierania danych użytkownika:', error);
            setUserFormError('Błąd podczas pobierania danych użytkownika.');
            ToastHelper.showErrorToast('Błąd podczas pobierania danych użytkownika.');
        }
    };

    const handleCloseViewUser = () => {
        setViewingUserId(null);
        setViewingUserData({});
    };

    return (
        <div className="min-h-screen bg-gray-900 p-6 pt-24">
            <div className="max-w-6xl mx-auto space-y-8">
                {/* Sekcja zarządzania użytkownikami */}
                <div className="bg-gray-800 p-6 rounded-lg shadow-md">
                    <div className="flex items-center justify-between mb-4">
                        <h2 className="text-2xl font-semibold text-white">Zarządzanie użytkownikami</h2>
                    </div>

                    {/* Lista użytkowników */}
                    <div className="overflow-x-auto">
                        {usersLoading ? (
                            <div className="flex items-center justify-center py-10">
                                <svg
                                    className="animate-spin h-8 w-8 text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8v8H4z"
                                    ></path>
                                </svg>
                            </div>
                        ) : usersError ? (
                            <p className="text-red-500 text-center">{usersError}</p>
                        ) : (
                            <table className="min-w-full bg-gray-700 rounded-lg overflow-hidden">
                                <thead>
                                    <tr>
                                        <th className="py-2 px-4 text-left text-sm font-semibold text-gray-300">ID</th>
                                        <th className="py-2 px-4 text-left text-sm font-semibold text-gray-300">Email</th>
                                        <th className="py-2 px-4 text-left text-sm font-semibold text-gray-300">Telefon</th>
                                        <th className="py-2 px-4 text-left text-sm font-semibold text-gray-300">Restauracja</th>
                                        <th className="py-2 px-4 text-left text-sm font-semibold text-gray-300">Utworzono</th>
                                        <th className="py-2 px-4 text-left text-sm font-semibold text-gray-300">Akcje</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user) => (
                                        <tr
                                            key={user.id}
                                            className="border-t border-gray-600 cursor-pointer hover:bg-gray-600"
                                            onClick={() => handleViewUserClick(user)}
                                        >
                                            <td className="py-2 px-4 text-sm text-gray-200">{user.id}</td>
                                            <td className="py-2 px-4 text-sm text-gray-200">{user.email}</td>
                                            <td className="py-2 px-4 text-sm text-gray-200">{user.phone}</td>
                                            <td className="py-2 px-4 text-sm text-gray-200">{user.restaurant_name || 'N/A'}</td>
                                            <td className="py-2 px-4 text-sm text-gray-200">
                                                {new Date(user.created_at).toLocaleDateString()}
                                            </td>
                                            <td className="py-2 px-4 text-sm text-gray-200 flex space-x-2">
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleEditUserClick(user);
                                                    }}
                                                    className="px-3 py-1 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition-colors duration-200 flex items-center"
                                                >
                                                    <FiEdit className="mr-1" /> Edytuj
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteUser(user.id);
                                                    }}
                                                    className={`px-3 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-200 flex items-center ${
                                                        isDeleting && deletingUserId === user.id
                                                            ? 'opacity-50 cursor-not-allowed'
                                                            : ''
                                                    }`}
                                                    disabled={isDeleting && deletingUserId === user.id}
                                                >
                                                    {isDeleting && deletingUserId === user.id ? (
                                                        'Usuwanie...'
                                                    ) : (
                                                        <>
                                                            <FiTrash2 className="mr-1" /> Usuń
                                                        </>
                                                    )}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    {users.length === 0 && (
                                        <tr>
                                            <td colSpan="6" className="py-4 text-center text-gray-400">
                                                Nie znaleziono użytkowników.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>

                    {/* Formularz edycji użytkownika */}
                    {editingUserId && (
                        <div className="mt-6 bg-gray-700 p-4 rounded-lg shadow-inner">
                            <h3 className="text-xl font-semibold text-white mb-3">Edytuj użytkownika</h3>
                            <form onSubmit={handleUpdateUser} className="space-y-4">
                                <div>
                                    <label htmlFor="editUserEmail" className="block text-sm font-medium text-gray-300 mb-1">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="editUserEmail"
                                        name="email"
                                        value={editedUserData.email}
                                        onChange={handleUserInputChange}
                                        className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-600 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="editUserPhone" className="block text-sm font-medium text-gray-300 mb-1">
                                        Telefon
                                    </label>
                                    <input
                                        type="text"
                                        id="editUserPhone"
                                        name="phone"
                                        value={editedUserData.phone || ''}
                                        onChange={handleUserInputChange}
                                        className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-600 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="editUserPassword" className="block text-sm font-medium text-gray-300 mb-1">
                                        Nowe hasło
                                    </label>
                                    <input
                                        type="password"
                                        id="editUserPassword"
                                        name="password"
                                        value={editedUserData.password || ''}
                                        onChange={handleUserInputChange}
                                        className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-600 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Wprowadź nowe hasło, jeśli chcesz je zmienić"
                                    />
                                </div>
                                {/* Dane restauracji */}
                                <div>
                                    <label htmlFor="editRestaurantName" className="block text-sm font-medium text-gray-300 mb-1">
                                        Nazwa restauracji
                                    </label>
                                    <input
                                        type="text"
                                        id="editRestaurantName"
                                        name="restaurant_name"
                                        value={editedUserData.restaurant_name || ''}
                                        onChange={handleUserInputChange}
                                        className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-600 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="editRestaurantStreet" className="block text-sm font-medium text-gray-300 mb-1">
                                        Ulica
                                    </label>
                                    <input
                                        type="text"
                                        id="editRestaurantStreet"
                                        name="street"
                                        value={editedUserData.street || ''}
                                        onChange={handleUserInputChange}
                                        className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-600 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="editRestaurantCity" className="block text-sm font-medium text-gray-300 mb-1">
                                        Miasto
                                    </label>
                                    <input
                                        type="text"
                                        id="editRestaurantCity"
                                        name="city"
                                        value={editedUserData.city || ''}
                                        onChange={handleUserInputChange}
                                        className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-600 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="editRestaurantPostalCode" className="block text-sm font-medium text-gray-300 mb-1">
                                        Kod pocztowy
                                    </label>
                                    <input
                                        type="text"
                                        id="editRestaurantPostalCode"
                                        name="postal_code"
                                        value={editedUserData.postal_code || ''}
                                        onChange={handleUserInputChange}
                                        className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-600 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="editRestaurantCountry" className="block text-sm font-medium text-gray-300 mb-1">
                                        Kraj
                                    </label>
                                    <input
                                        type="text"
                                        id="editRestaurantCountry"
                                        name="country"
                                        value={editedUserData.country || ''}
                                        onChange={handleUserInputChange}
                                        className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-600 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                {userFormError && <p className="text-red-500 text-sm">{userFormError}</p>}
                                {userFormSuccess && <p className="text-green-500 text-sm">{userFormSuccess}</p>}
                                <div className="flex space-x-2">
                                    <button
                                        type="submit"
                                        className={`flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center ${
                                            userFormLoading ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                        disabled={userFormLoading}
                                    >
                                        {userFormLoading ? 'Aktualizowanie...' : 'Aktualizuj użytkownika'}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleCancelEditUser}
                                        className="flex-1 px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors duration-200 flex items-center justify-center"
                                    >
                                        <FiX className="mr-2" /> Anuluj
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}

                    {/* Wyświetlanie danych użytkownika */}
                    {viewingUserId && (
                        <div className="mt-6 bg-gray-700 p-4 rounded-lg shadow-inner">
                            <h3 className="text-xl font-semibold text-white mb-3">Szczegóły użytkownika</h3>
                            <p className="text-gray-200">
                                <strong>ID:</strong> {viewingUserData.id}
                            </p>
                            <p className="text-gray-200">
                                <strong>Email:</strong> {viewingUserData.email}
                            </p>
                            <p className="text-gray-200">
                                <strong>Telefon:</strong> {viewingUserData.phone || 'N/A'}
                            </p>
                            <p className="text-gray-200">
                                <strong>Nazwa restauracji:</strong> {viewingUserData.restaurant_name || 'N/A'}
                            </p>
                            <p className="text-gray-200">
                                <strong>Adres:</strong>{' '}
                                {`${viewingUserData.street || ''}, ${viewingUserData.city || ''}, ${
                                    viewingUserData.postal_code || ''
                                }, ${viewingUserData.country || ''}`}
                            </p>
                            <p className="text-gray-200">
                                <strong>Utworzono:</strong>{' '}
                                {new Date(viewingUserData.created_at).toLocaleDateString()}
                            </p>
                            {/* Dodaj inne potrzebne dane */}
                            <button
                                type="button"
                                onClick={handleCloseViewUser}
                                className="mt-4 px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors duration-200 flex items-center justify-center"
                            >
                                <FiX className="mr-2" /> Zamknij
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminUsers;
