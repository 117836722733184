import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from './components/auth';
import { RequireAuth } from './components/require_auth';
import { AdminAuthProvider } from './admin/components/admin_auth';
import { AdminRequireAuth } from './admin/components/admin_require_auth';

import Navbar from './pages/components/navbar';
import AdminNavbar from './admin/pages/components/navbar';

import Login from './pages/login';
import Register from './pages/register';
import NotFound from './pages/not_found';
import Home from './pages/home';
import OrderHistory from './pages/order-history';
import Statistics from './pages/statistics';
import Settings from './pages/settings';
import Products from './pages/products';
import Menu from './pages/menu';
import Support from './pages/support';

import AdminLogin from './admin/pages/admin_login'; // Create this component
import AdminHome from './admin/pages/admin_home'; // Create this component
import AdminNotFound from './admin/pages/admin_not_found';
import AdminSupport from './admin/pages/admin_support';
import AdminSettings from './admin/pages/admin_settings';
import AdminUsers from './admin/pages/admin_users';

function AppContent() {
  const location = useLocation();

  // Paths where Navbar should not be displayed (prefix-based)
  const hideNavbarPaths = ['/login', '/register', '/menu', '/admin'];

  // Sprawdzenie, czy aktualna ścieżka zaczyna się od którejkolwiek z wybranych ścieżek
  const shouldHideNavbar = hideNavbarPaths.some(path => location.pathname.startsWith(path));
  const isAdminPath = location.pathname.startsWith('/admin') && location.pathname !== '/admin/login';
  return (
    <>
      {!shouldHideNavbar && <Navbar />}
      {isAdminPath && <AdminNavbar />}
      <ToastContainer />
      <Routes>
        {/* User Routes */}
        <Route path="/" element={<RequireAuth><Home /></RequireAuth>} />
        <Route path="/order-history" element={<RequireAuth><OrderHistory /></RequireAuth>} />
        <Route path="/statistics" element={<RequireAuth><Statistics /></RequireAuth>} />
        <Route path="/settings" element={<RequireAuth><Settings /></RequireAuth>} />
        <Route path="/products" element={<RequireAuth><Products /></RequireAuth>} />
        <Route path="/menu/:restaurantId/:tableId" element={<Menu />} />
        <Route path="/support" element={<RequireAuth><Support /></RequireAuth>} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* Admin Routes */}
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin" element={<AdminRequireAuth><AdminHome /></AdminRequireAuth>} />
        <Route path="/admin/support" element={<AdminRequireAuth><AdminSupport /></AdminRequireAuth>} />
        <Route path="/admin/settings" element={<AdminRequireAuth><AdminSettings /></AdminRequireAuth>} />
        <Route path="/admin/users" element={<AdminRequireAuth><AdminUsers /></AdminRequireAuth>} />

        {/* Fallback Route */}
        <Route path="/*" element={<NotFound />} />
        <Route path="/admin/*" element={<AdminNotFound />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <AdminAuthProvider>
        <Router>
          <AppContent />
        </Router>
      </AdminAuthProvider>
    </AuthProvider>
  );
}

export default App;
