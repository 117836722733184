// src/pages/OrderHistory.js
import React, { useState, useEffect } from 'react';
import Api from '../lib/Api';
import { useAuth } from '../components/auth';
import { useNavigate } from 'react-router-dom';

export default function OrderHistory() {
  const [restaurantId, setRestaurantId] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderLoading, setOrderLoading] = useState(false);
  const [orderError, setOrderError] = useState(null);

  // Stany dla wyszukiwarki
  const [tableNumberFilter, setTableNumberFilter] = useState('');
  const [orderDateFilter, setOrderDateFilter] = useState('');

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const storedRestaurantId = localStorage.getItem('restaurant_id');
    if (storedRestaurantId) {
      setRestaurantId(storedRestaurantId);
    } else {
      auth.logout();
      navigate('/login');
    }
  }, [auth, navigate]);

  useEffect(() => {
    const fetchOrderHistory = async () => {
      if (!restaurantId) return;

      try {
        setLoading(true);
        setError(null);

        // Pobieranie wszystkich zamówień bez filtrów
        const fetchedOrders = await Api.GetOrders(restaurantId);
        setOrders(fetchedOrders);
        setLoading(false);
      } catch (err) {
        console.error('Błąd podczas pobierania historii zamówień:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchOrderHistory();
  }, [restaurantId]);

  const handleSearch = async () => {
    if (!restaurantId) return;

    try {
      setLoading(true);
      setError(null);

      const filters = {};
      if (tableNumberFilter.trim() !== '') {
        filters.table_number = tableNumberFilter.trim();
      }
      if (orderDateFilter.trim() !== '') {
        filters.order_date = orderDateFilter.trim();
      }

      const fetchedOrders = await Api.GetOrders(restaurantId, filters);
      setOrders(fetchedOrders);
      setLoading(false);
    } catch (err) {
      console.error('Błąd podczas pobierania historii zamówień:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  const handleClearFilters = async () => {
    setTableNumberFilter('');
    setOrderDateFilter('');

    // Pobierz wszystkie zamówienia bez filtrów
    try {
      setLoading(true);
      setError(null);

      const fetchedOrders = await Api.GetOrders(restaurantId);
      setOrders(fetchedOrders);
      setLoading(false);
    } catch (err) {
      console.error('Błąd podczas pobierania historii zamówień:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  const openOrderDetails = async (order) => {
    try {
      setOrderLoading(true);
      setOrderError(null);
      // Pobranie szczegółów zamówienia z serwera
      const orderDetails = await Api.GetOrderDetails(restaurantId, order.order_id);
      // Połączenie oryginalnego zamówienia ze szczegółami
      setSelectedOrder({ ...order, ...orderDetails });
      setOrderLoading(false);
    } catch (err) {
      console.error('Błąd podczas pobierania szczegółów zamówienia:', err);
      setOrderError(err.message);
      setOrderLoading(false);
    }
  };

  const closeOrderDetails = () => {
    setSelectedOrder(null);
    setOrderError(null);
  };

  return (
    <div className="min-h-screen bg-gray-900 p-4">
      <h1 className="text-3xl text-white mb-6 text-center">Historia Zamówień</h1>

      {/* Sekcja Wyszukiwarki */}
      <div className="mb-6 flex flex-col md:flex-row items-center md:items-end justify-start space-y-4 md:space-y-0 md:space-x-4">
        {/* Pole numeru stolika */}
        <div className="w-full md:w-1/4">
          <label htmlFor="tableNumber" className="block text-white mb-1">Numer Stolika</label>
          <input
            type="text"
            id="tableNumber"
            value={tableNumberFilter}
            onChange={(e) => setTableNumberFilter(e.target.value)}
            className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Wprowadź numer stolika"
          />
        </div>
        {/* Pole daty zamówienia */}
        <div className="w-full md:w-1/4">
          <label htmlFor="orderDate" className="block text-white mb-1">Data Zamówienia</label>
          <input
            type="date"
            id="orderDate"
            value={orderDateFilter}
            onChange={(e) => setOrderDateFilter(e.target.value)}
            className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        {/* Przyciski */}
        <div className="flex space-x-4 w-full md:w-auto md:self-end">
          <button
            onClick={handleSearch}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none w-full md:w-auto"
          >
            Szukaj
          </button>
          {(tableNumberFilter || orderDateFilter) && (
            <button
              onClick={handleClearFilters}
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 focus:outline-none w-full md:w-auto"
            >
              Wyczyść filtry
            </button>
          )}
        </div>
      </div>

      {loading ? (
        <div className="flex items-center justify-center">
          <p className="text-white">Ładowanie...</p>
        </div>
      ) : error ? (
        <div className="flex items-center justify-center">
          <p className="text-red-500">Błąd: {error}</p>
        </div>
      ) : (
        <>
          {orders.length > 0 ? (
            <>
              {/* Tabela dla dużych ekranów */}
              <div className="hidden md:block overflow-x-auto">
                <table className="min-w-full bg-gray-800 text-white rounded-lg">
                  <thead>
                    <tr>
                      <th className="py-3 px-6 bg-gray-700 text-left">ID Zamówienia</th>
                      <th className="py-3 px-6 bg-gray-700 text-left">Numer Stolika</th>
                      <th className="py-3 px-6 bg-gray-700 text-left">Status</th>
                      <th className="py-3 px-6 bg-gray-700 text-left">Data Utworzenia</th>
                      <th className="py-3 px-6 bg-gray-700 text-left">Akcje</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map(order => (
                      <tr key={order.order_id} className="text-center border-t border-gray-600">
                        <td className="py-4 px-6">{order.order_id}</td>
                        <td className="py-4 px-6">{order.table_number}</td>
                        <td className="py-4 px-6">
                          <span
                            className={`inline-block px-2 py-1 text-xs font-semibold rounded-full ${order.status === 'Złożone' ? 'bg-yellow-500' :
                                order.status === 'Wydane' ? 'bg-green-500' :
                                  order.status === 'Anulowane' ? 'bg-red-500' :
                                    'bg-gray-500'
                              } text-white`}
                          >
                            {order.status}
                          </span>
                        </td>
                        <td className="py-4 px-6">{new Date(order.created_at).toLocaleString()}</td>
                        <td className="py-4 px-6">
                          <button
                            onClick={() => openOrderDetails(order)}
                            className="px-3 py-1 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none w-full md:w-auto"
                          >
                            Szczegóły
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Lista kart dla małych ekranów */}
              <div className="block md:hidden space-y-4">
                {orders.map(order => (
                  <div key={order.order_id} className="bg-gray-800 text-white p-4 rounded-lg shadow">
                    <div className="mb-2">
                      <p><span className="font-semibold">ID Zamówienia:</span> {order.order_id}</p>
                      <p><span className="font-semibold">Numer Stolika:</span> {order.table_number}</p>
                      <p><span className="font-semibold">Data Utworzenia:</span> {new Date(order.created_at).toLocaleString()}</p>
                    </div>
                    <div className="flex items-center justify-between">
                      <span
                        className={`inline-block px-2 py-1 text-xs font-semibold rounded-full ${order.status === 'Złożone' ? 'bg-yellow-500' :
                            order.status === 'Wydane' ? 'bg-green-500' :
                              order.status === 'Anulowane' ? 'bg-red-500' :
                                'bg-gray-500'
                          } text-white`}
                      >
                        {order.status}
                      </span>
                      <button
                        onClick={() => openOrderDetails(order)}
                        className="px-3 py-1 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none"
                      >
                        Szczegóły
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <p className="text-center text-white">Brak zamówień do wyświetlenia.</p>
          )}
        </>
      )}

      {/* Modal Szczegółów Zamówienia */}
      {selectedOrder && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
          <div className="bg-gray-800 text-gray-200 p-6 rounded-lg shadow-lg w-full max-w-2xl max-h-full overflow-y-auto">
            {orderLoading ? (
              <p className="text-center">Ładowanie szczegółów zamówienia...</p>
            ) : orderError ? (
              <p className="text-red-500 text-center">Błąd: {orderError}</p>
            ) : (
              <>
                <h2 className="text-2xl mb-4 font-semibold">{`Zamówienie #${selectedOrder.order_id}`}</h2>
                <p className="mb-2">
                  <span className="font-medium">Status:</span> {selectedOrder.status || 'Nieznany'}
                </p>
                <p className="mb-4">
                  <span className="font-medium">Data Utworzenia:</span> {selectedOrder.created_at ? new Date(selectedOrder.created_at).toLocaleString() : 'Nieznana'}
                </p>

                {/* Lista produktów */}
                <h3 className="text-xl mb-2">Produkty:</h3>
                {selectedOrder.products.length > 0 ? (
                  <div className="overflow-x-auto">
                    <table className="min-w-full bg-gray-700 text-white rounded-lg mb-4">
                      <thead>
                        <tr>
                          <th className="py-2 px-4 border-b border-gray-600 text-left">Nazwa Produktu</th>
                          <th className="py-2 px-4 border-b border-gray-600 text-left">Ilość</th>
                          <th className="py-2 px-4 border-b border-gray-600 text-left">Cena Jednostkowa</th>
                          <th className="py-2 px-4 border-b border-gray-600 text-left">Cena Całkowita</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedOrder.products.map(product => (
                          <tr key={product.product_id}>
                            <td className="py-2 px-4 border-b border-gray-600">{product.product_name}</td>
                            <td className="py-2 px-4 border-b border-gray-600">{product.quantity}</td>
                            <td className="py-2 px-4 border-b border-gray-600">{product.price.toFixed(2)} PLN</td>
                            <td className="py-2 px-4 border-b border-gray-600">{(product.price * product.quantity).toFixed(2)} PLN</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="mb-4">Brak produktów w zamówieniu.</p>
                )}

                {/* Obliczenie i wyświetlenie całkowitej ceny */}
                <p className="text-right font-semibold">
                  {`Łączna Cena: ${selectedOrder.products.reduce((acc, product) => acc + product.price * product.quantity, 0).toFixed(2)} PLN`}
                </p>

                <button
                  onClick={closeOrderDetails}
                  className="mt-6 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none w-full md:w-auto"
                >
                  Zamknij
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
