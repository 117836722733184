// src/pages/AdminSupport.js
import React, { useState, useEffect, useRef } from 'react';
import { FiHelpCircle, FiChevronRight, FiTrash2, FiX } from 'react-icons/fi';
import { Transition } from '@headlessui/react';
import { FaUserCircle, FaRobot } from 'react-icons/fa'; // Ikony avatarów
import Api from '../lib/Api';
import ToastHelper from '../../helpers/toast_helper';

function AdminSupport() {
    const [supportTickets, setSupportTickets] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [newMessage, setNewMessage] = useState('');
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [messageError, setMessageError] = useState(null);
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [statusError, setStatusError] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const [isLoadingTicket, setIsLoadingTicket] = useState(false); // Nowy stan dla ładowania

    // Referencja do końca wiadomości
    const messagesEndRef = useRef(null);

    // Pobieranie zgłoszeń wsparcia
    const fetchSupportTickets = async () => {
        try {
            const response = await Api.getAllSupportTickets();
            if (response && Array.isArray(response)) {
                setSupportTickets(response);
            } else {
                console.error('Nieprawidłowa odpowiedź API:', response);
                setSupportTickets([]);
            }
        } catch (error) {
            console.error('Błąd podczas pobierania zgłoszeń:', error);
            setSupportTickets([]);
            ToastHelper.showErrorToast('Błąd podczas pobierania zgłoszeń.');
        }
    };

    useEffect(() => {
        fetchSupportTickets();
    }, []);

    // Otwieranie i zamykanie panelu bocznego
    const openTicketDetails = async (ticket) => {
        setIsLoadingTicket(true); // Rozpoczęcie ładowania
        try {
            const response = await Api.getSupportTicket(ticket.id);
            if (response && !response.error) {
                setSelectedTicket(response.support_ticket);
                setMessages(response.messages);
                setIsSidebarOpen(true);
            } else {
                ToastHelper.showErrorToast(response.message || 'Nie udało się pobrać szczegółów zgłoszenia.');
            }
        } catch (error) {
            console.error('Błąd podczas pobierania szczegółów zgłoszenia:', error);
            ToastHelper.showErrorToast('Błąd podczas pobierania szczegółów zgłoszenia.');
        } finally {
            setIsLoadingTicket(false); // Zakończenie ładowania
        }
    };

    const closeTicketDetails = () => {
        setSelectedTicket(null);
        setMessages([]);
        setNewMessage('');
        setMessageError(null);
        setNewStatus('');
        setStatusError(null);
        setIsSidebarOpen(false);
    };

    // Wysyłanie wiadomości
    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim()) {
            setMessageError('Wiadomość nie może być pusta.');
            return;
        }

        setIsSendingMessage(true);
        setMessageError(null);
        try {
            const response = await Api.addSupportMessage(selectedTicket.id, newMessage);
            if (response && !response.error) {
                // Odświeżanie wiadomości po wysłaniu
                const updatedTicket = await Api.getSupportTicket(selectedTicket.id);
                if (updatedTicket && !updatedTicket.error) {
                    setSelectedTicket(updatedTicket.support_ticket);
                    setMessages(updatedTicket.messages);
                    ToastHelper.showSuccessToast('Wiadomość wysłana.');
                    setNewMessage('');
                } else {
                    ToastHelper.showErrorToast(updatedTicket.message || 'Nie udało się odświeżyć zgłoszenia.');
                }
            } else {
                setMessageError(response.message || 'Nie udało się wysłać wiadomości.');
            }
        } catch (error) {
            console.error('Błąd podczas wysyłania wiadomości:', error);
            setMessageError('Błąd podczas wysyłania wiadomości.');
        } finally {
            setIsSendingMessage(false);
        }
    };

    // Aktualizacja statusu
    const handleUpdateStatus = async () => {
        if (!newStatus.trim()) {
            setStatusError('Proszę wybrać nowy status.');
            return;
        }

        setIsUpdatingStatus(true);
        setStatusError(null);
        try {
            const response = await Api.updateSupportStatus(selectedTicket.id, newStatus);
            if (response && !response.error) {
                // Odświeżanie szczegółów zgłoszenia po aktualizacji statusu
                const updatedTicket = await Api.getSupportTicket(selectedTicket.id);
                if (updatedTicket && !updatedTicket.error) {
                    setSelectedTicket(updatedTicket.support_ticket);
                    setMessages(updatedTicket.messages);
                    ToastHelper.showSuccessToast('Status zgłoszenia zaktualizowany.');
                } else {
                    ToastHelper.showErrorToast(updatedTicket.message || 'Nie udało się odświeżyć zgłoszenia.');
                }
            } else {
                setStatusError(response.message || 'Nie udało się zaktualizować statusu.');
            }
        } catch (error) {
            console.error('Błąd podczas aktualizacji statusu:', error);
            setStatusError('Błąd podczas aktualizacji statusu zgłoszenia.');
        } finally {
            setIsUpdatingStatus(false);
        }
    };

    // Usuwanie zgłoszenia
    const handleDeleteTicket = async () => {
        const confirmDelete = window.confirm('Czy na pewno chcesz usunąć to zgłoszenie?');
        if (!confirmDelete) return;

        try {
            const response = await Api.deleteSupportTicket(selectedTicket.id);
            if (response && !response.error) {
                ToastHelper.showSuccessToast('Zgłoszenie usunięte.');
                await fetchSupportTickets();
                closeTicketDetails();
            } else {
                ToastHelper.showErrorToast(response.message || 'Nie udało się usunąć zgłoszenia.');
            }
        } catch (error) {
            console.error('Błąd podczas usuwania zgłoszenia:', error);
            ToastHelper.showErrorToast('Błąd podczas usuwania zgłoszenia.');
        }
    };

    // Przewijanie do dołu wiadomości
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    return (
        <div className="min-h-screen pt-16 bg-gray-900 flex">
            {/* Lista Zgłoszeń Wsparcia */}
            <div className="w-1/4 bg-gray-800 rounded-l-lg shadow-md ml-2 mt-2 overflow-y-auto">
                <div className="flex items-center justify-between bg-gray-700 text-white px-6 py-4">
                    <div className="flex items-center space-x-2">
                        <FiHelpCircle className="text-2xl" />
                        <h2 className="text-xl font-semibold">Zgłoszenia Wsparcia</h2>
                    </div>
                    {/* Opcjonalne akcje jak odświeżanie czy filtrowanie */}
                </div>
                <div className="p-4">
                    {supportTickets.length > 0 ? (
                        <ul className="space-y-3">
                            {supportTickets.map((ticket) => (
                                <li
                                    key={ticket.id}
                                    onClick={() => openTicketDetails(ticket)}
                                    className={`flex items-center justify-between p-4 bg-gray-700 rounded-lg shadow-sm hover:bg-gray-600 cursor-pointer transition-colors duration-200 ${selectedTicket && selectedTicket.id === ticket.id ? 'bg-gray-600' : ''
                                        }`}
                                >
                                    <div className="flex items-center space-x-3">
                                        <FiHelpCircle className="text-2xl text-blue-400" />
                                        <div>
                                            <h3 className="text-lg font-medium text-white">{ticket.title}</h3>
                                            <p className="text-sm text-gray-400">
                                                {new Date(ticket.created_at).toLocaleDateString()}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <span
                                            className={`px-3 py-1 rounded-full text-xs font-semibold ${ticket.status === 'Open'
                                                ? 'bg-green-600 text-green-100'
                                                : ticket.status === 'In Progress'
                                                    ? 'bg-yellow-600 text-yellow-100'
                                                    : 'bg-red-600 text-red-100'
                                                }`}
                                        >
                                            {ticket.status}
                                        </span>
                                        <FiChevronRight className="text-xl text-gray-400" />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-center text-gray-500">Brak dostępnych zgłoszeń wsparcia.</p>
                    )}
                </div>
            </div>

            {/* Panel boczny ze szczegółami zgłoszenia */}
            <Transition show={isSidebarOpen} as={React.Fragment}>
                <Transition.Child
                    as={React.Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                >
                    <div className="w-2/3 bg-gray-800 mt-2 ml-2 text-gray-200 shadow-xl overflow-y-auto flex flex-col">
                        {/* Przycisk zamykania */}
                        <div className="flex items-center justify-between p-4 border-b border-gray-700">
                            <h2 className="text-xl font-semibold">Szczegóły Zgłoszenia</h2>
                            <button
                                onClick={closeTicketDetails}
                                className="text-gray-400 hover:text-gray-200 focus:outline-none"
                                aria-label="Zamknij panel boczny"
                            >
                                <FiX className="h-6 w-6" />
                            </button>
                        </div>

                        {/* Zawartość */}
                        {isLoadingTicket ? (
                            <div className="flex items-center justify-center flex-1 p-6">
                                <svg
                                    className="animate-spin h-8 w-8 text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8v8H4z"
                                    ></path>
                                </svg>
                            </div>
                        ) : (
                            selectedTicket && (
                                <div className="flex flex-1">
                                    {/* Sekcja Szczegółów */}
                                    <div className="w-1/2 p-6 space-y-6 border-r border-gray-700 overflow-y-auto">
                                        <div>
                                            <h3 className="text-2xl font-bold">{selectedTicket.title}</h3>
                                            <p className="text-gray-400 mt-2">
                                                <span className="font-medium">Status:</span>{' '}
                                                <span
                                                    className={`px-2 py-1 rounded-full text-sm font-semibold ${selectedTicket.status === 'Open'
                                                        ? 'bg-green-600 text-green-100'
                                                        : selectedTicket.status === 'In Progress'
                                                            ? 'bg-yellow-600 text-yellow-100'
                                                            : 'bg-red-600 text-red-100'
                                                        }`}
                                                >
                                                    {selectedTicket.status}
                                                </span>
                                            </p>
                                            <p className="text-gray-300 mt-4">{selectedTicket.description}</p>
                                        </div>

                                        {/* Sekcja aktualizacji statusu */}
                                        <div>
                                            <h4 className="text-lg font-medium mb-3">Aktualizuj Status Zgłoszenia</h4>
                                            <div className="flex items-center space-x-3">
                                                <select
                                                    value={newStatus}
                                                    onChange={(e) => setNewStatus(e.target.value)}
                                                    className="flex-1 px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                    required
                                                >
                                                    <option value="">Wybierz status</option>
                                                    <option value="Open">Otwarte</option>
                                                    <option value="In Progress">W trakcie</option>
                                                    <option value="Closed">Zamknięte</option>
                                                </select>
                                                <button
                                                    onClick={handleUpdateStatus}
                                                    className={`px-4 py-2 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 transition-colors duration-200 flex items-center justify-center ${isUpdatingStatus
                                                        ? 'opacity-50 cursor-not-allowed'
                                                        : ''
                                                        }`}
                                                    disabled={isUpdatingStatus}
                                                >
                                                    {isUpdatingStatus ? 'Aktualizowanie...' : 'Aktualizuj'}
                                                </button>
                                            </div>
                                            {statusError && (
                                                <p className="text-red-500 text-sm mt-2">{statusError}</p>
                                            )}
                                        </div>

                                        {/* Przycisk usuwania zgłoszenia */}
                                        <button
                                            onClick={handleDeleteTicket}
                                            className="w-full mt-6 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-200 flex items-center justify-center"
                                        >
                                            <FiTrash2 className="mr-2" /> Usuń Zgłoszenie
                                        </button>
                                    </div>

                                    {/* Sekcja Czat */}
                                    <div className="w-1/2 p-6 flex flex-col">
                                        <div className="flex-1 space-y-4 overflow-y-auto pr-2">
                                            <h4 className="text-lg font-medium mb-3">Wiadomości</h4>
                                            {messages.length > 0 ? (
                                                messages.map((msg) => (
                                                    <div
                                                        key={msg.id}
                                                        className={`flex ${msg.sender_type === 'admin'
                                                            ? 'justify-start'
                                                            : 'justify-end'
                                                            }`}
                                                    >
                                                        {msg.sender_type === 'admin' && (
                                                            <FaRobot className="text-3xl mr-2 text-blue-400" />
                                                        )}
                                                        <div
                                                            className={`max-w-xs p-3 rounded-lg ${msg.sender_type === 'admin'
                                                                ? 'bg-blue-600 text-white'
                                                                : 'bg-green-600 text-white'
                                                                }`}
                                                        >
                                                            <p className="text-sm">{msg.message}</p>
                                                            <span className="text-xs mt-1 block text-right">
                                                                {new Date(msg.created_at).toLocaleString()}
                                                            </span>
                                                        </div>
                                                        {msg.sender_type === 'user' && (
                                                            <FaUserCircle className="text-3xl ml-2 text-green-400" />
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <p className="text-sm text-gray-500">Brak wiadomości.</p>
                                            )}
                                            {/* Pusty div do przewijania */}
                                            <div ref={messagesEndRef} />
                                        </div>

                                        {/* Formularz dodawania wiadomości */}
                                        <form onSubmit={handleSendMessage} className="mt-4">
                                            <div className="mb-2">
                                                <label htmlFor="newMessage" className="block text-sm font-medium mb-1">
                                                    Dodaj wiadomość
                                                </label>
                                                <textarea
                                                    id="newMessage"
                                                    value={newMessage}
                                                    onChange={(e) => setNewMessage(e.target.value)}
                                                    className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                    placeholder="Wpisz swoją wiadomość..."
                                                    required
                                                />
                                            </div>
                                            {messageError && (
                                                <p className="text-red-500 text-sm mb-2">{messageError}</p>
                                            )}
                                            <button
                                                type="submit"
                                                className={`w-full px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200 flex items-center justify-center ${isSendingMessage
                                                    ? 'opacity-50 cursor-not-allowed'
                                                    : ''
                                                    }`}
                                                disabled={isSendingMessage}
                                            >
                                                {isSendingMessage ? (
                                                    <>
                                                        <svg
                                                            className="animate-spin h-5 w-5 mr-2 text-white"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle
                                                                className="opacity-25"
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                                stroke="currentColor"
                                                                strokeWidth="4"
                                                            ></circle>
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8v8H4z"
                                                            ></path>
                                                        </svg>
                                                        Wysyłanie...
                                                    </>
                                                ) : (
                                                    'Wyślij wiadomość'
                                                )}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </Transition.Child>
            </Transition>
        </div>
    );
}

export default AdminSupport;
